////////////////////////////ITEMS IN THE MENU TOOLBAR ////////////////////////////////
const SearchItems = [
  {
    path: '/gradesPhysics',
    name: 'search physics',
    component: () => import('./views/SubtopicMenuPhys.vue')
  },
  {
    path: '/gradesChemistry',
    name: 'search chemistry',
    component: () => import('./views/SubtopicMenuChem.vue')
  },
  {
    path: '/gradesMathematics',
    name: 'search Mathematics',
    component: () => import('./views/SubtopicMenuMath.vue')
  },	
    ]
export default SearchItems;
////////////////////////////END OF FILE /////////////////////////////////////////
