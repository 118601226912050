<template>
  <div>
    <!-- LEFT NAVIGATION DRAWER -->
    <v-navigation-drawer
      v-model="leftDrawer"
      class="black"
      width= "3200px"
      height="320px"
      temporary
      left
      app
      elevation="6"
    >
      <v-list class="pa-0 black">
        <v-list-tile avatar class="black">
          <v-list-tile-content>
            <v-layout
              align-center
              justify-start
              row
            >
              <v-list-tile avatar>
                <v-img
                  src="/assets/list.svg"
                  aspect-ratio="1"
                  width="25px"
                  contain
                />
              </v-list-tile>
              <v-list-tile-title>
                <h2 style="color:#FFC100">{{ titles.menu }} </h2>
              </v-list-tile-title>
            </v-layout>
          </v-list-tile-content>
        </v-list-tile>
      </v-list>
      <v-list class="pa-0">
        <v-divider color="white"> </v-divider>
        <v-list-tile class="black pa-0" v-for="item in navItems"
                     :key="item.title"
                     @click="item.action"
        >
          <v-hover>
            <v-list-tile-content slot-scope="{ hover }"
                                 :class="`${hover ? 'menu-hover' : ''}`"
            >
              <v-layout
                align-center
                justify-space-around
                row
              >
                <v-list-tile avatar>
                  <v-img
                    :src="item.img"
                    :aspect-ratio="1"
                    width="25px"
                    contain
                  />
                </v-list-tile>
                <v-list-tile-title style="color:white" class="subtitle-2 text-white" v-text="item.title" />
              </v-layout>
              <v-divider color="white" inset></v-divider>
            </v-list-tile-content>
          </v-hover>
        </v-list-tile>
      </v-list>
    </v-navigation-drawer>
    <!-- END OF LEFT NAVIGATION DRAWER -->
    <!-- RIGHT NAVIGATION DRAWER -->
    <v-navigation-drawer
      v-model="toolBarConfig.rightModel"
      :permanent="toolBarConfig.type ==='permanent'"
      :temporary="toolBarConfig.type ==='temporary'"
      :mini-variant="toolBarConfig.mini"
      :clipped="toolBarConfig.clipped"
      :floating="toolBarConfig.floating"
      class="primary"
      color="white"
      width="250px"
      dark
      right
      overflow
      app
    >
      <v-list class="pa-0 mx-auto" dense>
        <v-list-tile avatar>
          <v-list-tile-content>
            <v-layout
              align-center
              justify-space-around
              row
            >
              <v-list-tile avatar>
                <v-img
                  src="/assets/list.svg"
                  aspect-ratio="1"
                  width="25px"
                  contain
                />
              </v-list-tile>

              <v-list-tile-title class="text-h2">
                <h2>My Account </h2>
              </v-list-tile-title>
            </v-layout>
          </v-list-tile-content>
        </v-list-tile>
      </v-list>
      <v-divider />
      <v-list class="pa-0" dense>
        <v-list-tile
          v-for="item in rightNavItems"
          :key="item.key"
          @click="item.action"
        >
          <v-hover>
            <v-list-tile-content slot-scope="{ hover }"
                                 :class="`${hover ? 'menu-hover' : ''}`"
            >
              <v-layout
                align-center
                justify-space-around
                row
              >
                <v-list-tile avatar>
                  <v-img
                    :src="item.img"
                    :aspect-ratio="1"
                    width="25px"
                    contain
                  />
                </v-list-tile>
                <v-list-tile-title class="subtitle-2" v-text="item.title" />
              </v-layout>
              <v-divider />
            </v-list-tile-content>
          </v-hover>
        </v-list-tile>
      </v-list>
      <v-list class="pa-0" dense>
        <v-list-tile @click="logout()">
          <v-hover>
            <v-list-tile-content slot-scope="{ hover }"
                                 :class="`${hover ? 'menu-hover' : ''}`"
            >
              <v-layout
                align-center
                justify-space-around
                row
              >
                <v-list-tile avatar>
                  <v-img
                    src="/assets/logout.svg"
                    :aspect-ratio="1"
                    width="25px"
                    contain
                  />
                </v-list-tile>
                <v-list-tile-title class="subtitle-1">
                  Logout
                </v-list-tile-title>
              </v-layout>
            </v-list-tile-content>
          </v-hover>
        </v-list-tile>
      </v-list>
    </v-navigation-drawer>
    <!-- END OF RIGHT NAVIGATION DRAWER -->
    <!-- TOOLBAR ITEMS BEGING HERE    -->
    <v-toolbar
      dark
      bottom
      fixed
      app
    >
      <!-- MENU BUTTON -->
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn
            icon
            small
            v-on="on"
            @click="leftDrawer = !leftDrawer"
          >
            <v-img
              src="/assets/app.svg"
              aspect-ratio="1"
              max-width="25px"
              contain
            />
          </v-btn>
        </template>
        <span> My Menu </span>
      </v-tooltip>
      <!--  SEARCH BAR -->
      <v-spacer />
      <v-flex v-show="showhome || userDashboard" cols="8">
        <v-autocomplete
          v-model="grades"
          :items="gradeList"
          dense
          filled
          color="black"
          label="Grade"
          item-text="name"
          item-value="name"
          return-object
          class="mx-auto px-1"
        >
          <template slot="selection" slot-scope="data">
            <template>
              <v-layout
                align-start
                justify-center
              >
                {{ data.item.name }}
              </v-layout>
            </template>
          </template>
        </v-autocomplete>
      </v-flex>
      <v-flex v-show="showhome || userDashboard" cols="8">
        <v-autocomplete
          v-model="subs"
          :items="items"
          dense
          filled
          flat
          color="black"
          label="Subject"
          item-text="name"
          item-value="id"
          return-object
          class="mx-auto px-1"
        >
          <template slot="selection" slot-scope="data">
            <template>
              <v-layout
                align-start
                justify-center
              >
                {{ data.item.name }}
              </v-layout>
            </template>
          </template>
        </v-autocomplete>
      </v-flex>
      <v-tooltip bottom dark>
        <template v-slot:activator="{ on }">
          <v-btn v-show="showhome || userDashboard"
                 icon
                 small
                 class="pa-auto"
                 @click="onSubmit()"
                 v-on="on"
          >
            <v-img
              src="/assets/find.svg"
              aspect-ratio="1"
              max-width="25px"
              contain
            />
          </v-btn>
        </template>
        <span>Search</span>
      </v-tooltip>
      <v-spacer />

      <v-tooltip bottom dark>
        <template v-slot:activator="{ on }">
          <v-btn v-show="showLeftArrow"
                 icon
                 small
                 @click="$router.go(-1)"
                 v-on="on"
          >
            <v-img
              src="/assets/left-arrow.svg"
              aspect-ratio="1"
              max-width="25px"
              contain
            />
          </v-btn>
        </template>
        <span> Go back</span>
      </v-tooltip>
      <!--  <v-btn v-show="showProton"
             icon
             small
             @click="$router.push({path:'/gradesChem'})"
      >
        <v-img
          src="/assets/proton.svg"
          aspect-ratio="1"
          max-width="25px"
          contain
        />
      </v-btn> -->
      <!--<v-btn icon small
      v-show="showEng" @click="$router.push({name:'programming'})">
       <v-img
       src="/assets/brain.svg"
       aspect-ratio = "1"
       max-width="25px"
       contain
       />
     </v-btn> -->
      <!--  <v-btn v-show="showGraph"
             icon
             small
             @click="$router.push({path:'/gradesMath'})"
      >
        <v-img
          src="/assets/algebraII.svg"
          aspect-ratio="1"
          max-width="25px"
          contain
        />
      </v-btn> -->
      <!--
     <v-toolbar-items >
     <v-btn icon small v-show="showhome" @click="$router.push({path:'/contact'})">
       <v-img
       src="/assets/support.svg"
       aspect-ratio = "1"
       max-width="25px"
       contain
       />
     </v-btn>
    </v-toolbar-items> -->
      <!--
    <v-btn icon small v-show="showhome" @click="$router.push({path: '/new'})">
      <v-img
      src="/assets/edliyE.svg"
      aspect-ratio = "1"
      max-width="25px"
      contain
      />
    </v-btn>
    -->
      <!--   <v-toolbar-items v-show="showhome">
   <v-btn icon small @click="$router.push({path: '/contact'})">
     <v-img
     src="/assets/customerSupport.svg"
     aspect-ratio = "1"
     max-width="25px"
     contain
     />
   </v-btn>
  </v-toolbar-items> -->
      <v-tooltip bottom dark>
        <template v-slot:activator="{ on }">
          <v-btn v-show="showModule"
                 icon
                 small
                 @click="$router.push({path: '/contact'})"
                 v-on="on"
          >
            <v-img
              src="/assets/question.svg"
              aspect-ratio="1"
              max-width="25px"
              contain
            />
          </v-btn>
        </template>
        <span class="text-h2">Have a Question?</span>
      </v-tooltip>
      <!--     <v-toolbar-items>
     <v-btn icon small @click="$router.push({path: '/finder'})">
       <v-img
       src="/assets/find.svg"
       aspect-ratio = "1"
       max-width="25px"
       contain
       />
     </v-btn>
    </v-toolbar-items> -->
      <v-tooltip bottom dark>
        <template v-slot:activator="{ on }">
          <v-btn v-show="!showhome && !userDashboard"
                 icon
                 small
                 v-on="on"
                 @click="$router.push({path:'/dashboard'})"
          >
            <v-img
              src="/assets/edliy.svg"
              aspect-ratio="1"
              max-width="25px"
              contain
            />
          </v-btn>
        </template>
        <span> My Home </span>
      </v-tooltip>

      <!--  <v-dialog
        v-model="dialog"
        hide-overlay
        width="auto "
        transition="dialog-top-transition"
        style="border-radius:7.5px;"
      >
        <template v-slot:activator="{ on, attrs }">
    <v-tooltip bottom dark>
            <template v-slot:activator="{ on }"> -->
      <v-tooltip bottom dark>
        <template v-slot:activator="{ on }">
          <v-btn v-show="loginStatus===false"
                 icon
                 small
                 v-on="on"
                 @click="dialog=true"
          >
            <v-img
              src="/assets/login.svg"
              aspect-ratio="1"
              max-width="25px"
              contain
            />
          </v-btn>
        </template>
        <span> Login</span>
      </v-tooltip>
      <!--      </template>
        <span>Login</span>
      </v-tooltip>
        </template>-->

      <!--  </v-dialog> -->
      <v-tooltip bottom dark>
        <template v-slot:activator="{ on }">
          <v-btn
            v-show="loginStatus===true && userDashboard===true"
            icon
            small
            v-on="on"
            @click="showRightNavDrawer"
          >
            <v-img
              src="/assets/edliyE.svg"
              aspect-ratio="1"
              max-width="25px"
              contain
            />
          </v-btn>
        </template>
        <span> My Account </span>
      </v-tooltip>
      <v-tooltip bottom dark>
        <template v-slot:activator="{ on }">
          <v-btn
            v-show="loginStatus===true && showhome===true"
            icon
            small
            color="lighten-2"
            dark
            v-on="on"
            @click="$router.push({path:'/dashboard'})"
          >
            <v-img
              src="/assets/edliy.svg"
              aspect-ratio="1"
              max-width="45px"
              contain
            />
          </v-btn>
        </template>
        <span> My Library </span>
      </v-tooltip>
      <!-- <template>
    <span @click="logout()">
      <v-btn icon small
        color="lighten-2"
        dark
        v-show="loginStatus==true"
      >
      <v-img
      src="/assets/logout.svg"
      aspect-ratio = "1"
      max-width="45px"
      contain
      />
      </v-btn>
    </span>
  </template>
  <v-btn icon small v-show ="loginStatus==true" @click="logout()">
    <v-img
    src="/assets/userIcon.svg"
    aspect-ratio = "1"
    max-width="25px"
    contain
    />
  </v-btn>-->

      <!--<v-menu offset-y>
    <template v-slot:activator="{ on, attrs }">
      <v-btn icon small
        v-bind="attrs"
        v-on="on"
        v-show="loginStatus==true"
      >
      <v-img
      src="/assets/userIcon.svg"
      aspect-ratio = "1"
      max-width="25px"
      contain
      />
      </v-btn>
    </template>
    <v-list>
      <v-list-tile>
        <v-list-tile-title> Profile </v-list-tile-title>
      </v-list-tile>
    </v-list>
  </v-menu> -->
    </v-toolbar>
    <!-- START OF  LOGIN WINDOW -->
    <v-layout
      v-if="dialog"
      justify-center
      align-center
      style="opacity:0.85; position:fixed; top:0;bottom:0;left:0;right:0;z-index:100;"
    >
      <LoginDialog :status="dialog" @close="dialog=false" />
    </v-layout>
    <!-- END OF LOGIN WINDOW -->
    <v-card flat style="padding-top:50px">
      <v-layout
        align-center
        justify-center
      >
        <v-flex
          xs5
          sm6
          md6
          lg6
          xl6
        >
          <v-card-actions v-if="showProton">
            <v-img
              max-height="500px"
              max-width="1000px"
              src="/assets/success.svg"
              contain
            />
          </v-card-actions>
          <v-card-actions v-if="showhome">
            <v-img
              max-height="500px"
              max-width="1000px"
              src="/assets/Learning.svg"
              contain
            />
          </v-card-actions>
          <v-card-actions v-if="showChem">
            <v-img
              max-height="500px"
              max-width="1000px"
              src="/assets/Chem.svg"
              contain
            />
          </v-card-actions>
          <v-card-actions v-if="showBrain">
            <v-img
              max-height="500px"
              max-width="1000px"
              src="/assets/Demo.svg"
              contain
            />
          </v-card-actions>
          <v-card-actions v-if="showMath">
            <v-img
              max-height="500px"
              max-width="1000px"
              src="/assets/classroom.svg"
              contain
            />
          </v-card-actions>
          <v-card-actions v-if="showPNP">
            <v-img
              max-height="500px"
              max-width="1000px"
              src="/assets/404.svg"
              contain
            />
          </v-card-actions>
          <v-card-actions v-if="showPhys">
            <v-img
              max-height="500px"
              max-width="1000px"
              src="/assets/Phys.svg"
              contain
            />
          </v-card-actions>
          <v-card-actions v-if="showTeam">
            <v-img
              max-height="500px"
              max-width="1000px"
              src="/assets/team.svg"
              contain
            />
          </v-card-actions>
          <v-card-actions v-if="showFind">
            <v-img
              max-height="500px"
              max-width="1000px"
              src="/assets/topicFinder.svg"
              contain
            />
          </v-card-actions>
          <v-card-actions v-if="userDashboard">
            <v-img
              max-height="500px"
              max-width="1000px"
              src="/assets/dashboard.svg"
              contain
            />
          </v-card-actions>
          <v-card-actions v-if="showEng">
            <v-img
              max-height="500px"
              max-width="1000px"
              src="/assets/programmer.svg"
              contain
            />
          </v-card-actions>
          <v-card-actions v-if="showContact">
            <v-img
              max-height="500px"
              max-width="1000px"
              src="/assets/contact.svg"
              contain
            />
          </v-card-actions>
          <v-card-actions v-if="showIP">
            <v-img
              max-height="500px"
              max-width="1000px"
              src="/assets/IP.svg"
              contain
            />
          </v-card-actions>
          <v-card-actions v-if="showCart">
            <v-img
              max-height="500px"
              max-width="1000px"
              src="/assets/showCart.svg"
              contain
            />
          </v-card-actions>
        </v-flex>
        <v-flex
          xs7
          sm6
          md6
          lg6
          xl6
        >
          <v-layout
            justify-start
            align-center
            class="px-2"
          >
            <div class="mb-3">
              <h1>
                <span class="header-etsy"> &epsilon;</span>dliy
              </h1>
            </div>
            <div class="px-0">
              <span class="header-dividers font-weight-regular">
                &nbsp;&vert;&nbsp;
              </span>
            </div>
            <div class="px-0">
              <span class="header-slogan font-weight-light">
                Content that clicks.
              </span>
              <br>
              <span class="header-slogan font-weight-light">
                Concepts that stick.
              </span>
            </div>
          </v-layout>
          <v-layout
            justify-start
            align-center
            class="px-2"
          >
            <v-flex>
              <span class="header-title font-weight-bold">
                {{ titles.main }}
              </span>
            </v-flex>
          </v-layout>
          <v-layout
            v-if="showSample"
            justify-center
            align-center
            class="px-3 mt-2"
          >
            <v-flex
              class="px-1"
              justify-center
            >
              <v-img
                max-height="300px"
                max-width="300px"
                src="/assets/Sample.jpg"
                contain
              />
            </v-flex>
          </v-layout>
          <v-layout
            v-if="showEnrollmentAdvert"
            justify-start
            align-center
            class="mt-1 px-2"
          >
            <v-flex v-if="courseStatus==='active'"
                    xs12
                    sm12
                    md12
                    lg12
                    xl12
            >
              <h3 style="color:green">
                You're already enrolled.
              </h3>
            </v-flex>
            <v-flex v-else
                    xs12
                    sm12
                    md12
                    lg12
                    xl12
            >
              <span v-if="price !== null" class="px-2">
                <h3><strike> ${{ price }}</strike>&nbsp;
                  <span
                    v-if="discount !== null"
                  >
                    ${{ newPrice }}/month &nbsp;({{ discount }}% off)
                  </span>
                </h3>
              </span>
              <span v-else class="px-2">
                <h5> Sign Up & Get Free Access!
                </h5>
              </span>
            </v-flex>
          </v-layout>

          <v-layout
            v-if="showEnrollmentAdvert"
            justify-start
            align-center
            class="mt-1 mb-1"
          >
            <v-flex v-if="courseStatus==='active'">
              <div v-if="!subscriptionEnd">
                <v-btn
                  color="red"
                  text
                  dark
                  @click="unsubscribeFunc()"
                >
                  <div class="pa-1">
                    <h4>
                      Unsubscribe
                    </h4>
                  </div>
                </v-btn>
              </div>
              <div v-else>
                <h3
                  class="px-2"
                >
                  Enrollment End Date: {{ subscriptionEnd | moment("MMMM Do YYYY") }}
                </h3>
              </div>
            </v-flex>
            <v-flex v-else>
              <v-btn
                class="btnLeft"
                text
                dark
                @click="enrollFunc()"
              >
                Get Started 
            </v-btn>
            </v-flex>
          </v-layout>
          <!--
              <v-btn
                v-show="showEnrollmentAdvert && loggedIn()"
                color="red"
                text
                dark
                @click="enrollFunc()"
              >
              <div class="pa-1">
                <h4>
                  Unsubscribe
                </h4>
              </div>
            </v-btn> -->

          <!--  <v-container
          v-show="showPhys"
          justify-start
          >
            <v-btn
              text
              justify-start
              large
              @click="enroll('Physics')"
            >
              Enroll Now!
            </v-btn> -->
          <!--  <v-card
              class="course-info-contents"
            >
            <v-card-text
                v-for="info in physicsCourseInfo"
                :key="info.img"
              >
                  <v-layout>
                    <v-flex
                      xs2
                    >
                      <v-img
                         max-height="2em"
                         max-width="2em"
                         :src="info.img"
                         contain
                      />
                    </v-flex>
                    <v-flex
                      xs10
                    >
                      {{ info.description }}
                    </v-flex>
                  </v-layout>
              </v-card-text>
              <v-card-actions>
                <v-btn
                  text
                  @click="enroll('Physics')"
                >
                  Enroll Now!
                </v-btn>
              </v-card-actions>
            </v-card>
            </v-container>-->
          <!--
          <v-container>
            <v-card
              class="course-info-contents"
            >
              <v-card-text>
                  <v-layout>
                    <v-flex
                      xs2
                    >
                      <v-img
                         max-height="2em"
                         max-width="2em"
                         src="/assets/online-lesson.svg"
                         contain
                      />
                    </v-flex>
                    <v-flex
                      xs10
                    >
                      12 Physics Modules
                    </v-flex>
                  </v-layout>
              </v-card-text>
            </v-card>
          </v-container>
-->
        </v-flex>
      </v-layout>
    </v-card>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import axios from 'axios';
import LoginDialog from './SocialLoginDialog.vue';
export default {
  name: 'Menu',
  components: {
    LoginDialog
  },
  data () {
    return {
      finder: { },
      subs:[],
      grades:[],
      catArr:[],
      gradesList:[],
      link1:null,
      link2:null,
      id:null,
      items: [
        { id:1, name: 'Physics',     avatar: '/assets/masswhite.svg' },
        { id:2, name: 'Chemistry',   avatar: '/assets/proton.svg' },
        { id:3, name: 'Mathematics', avatar: '/assets/algebraII.svg' },
      ],
      gradeList: [
        { name: '6th',  avatar: '/assets/domino-6white.svg' },
        { name: '7th',  avatar: '/assets/domino-7white.svg' },
        { name: '8th',  avatar: '/assets/domino-8white.svg' },
        { name: '9th',  avatar: '/assets/domino-9white.svg' },
        { name: '10th', avatar: '/assets/domino-10white.svg' },
        { name: '11th', avatar: '/assets/domino-11white.svg' },
        { name: '12th', avatar: '/assets/domino-12white.svg' },
      ],
      dark: true,
      leftDrawer:null,
      rightDrawer:null,
      //drawers: ['Permanent', 'Persistent', 'Temporary'],
      toolBarConfig: {
        rightModel: false,
        model: false,
        type: 'temporary',
        clipped: true,
        dark: true,
        floating:false,
        mini: false
      },
      showModal: false,
      dialog: false,
      loginStatus: false,
      userName: "",
      getpriceValues: null,
      unsubscribeText: false,
      /////////////////////// search menu options ////////////////////////
    }
  },
////////////////////////////////////////////////////////////////////
  computed: {
    isSmall() {
      return this.$vuetify.breakpoint.smAndDown
    },
    extensionHeight() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return '80px'
        case 'sm': return '140px'
        case 'md': return '250px'
        case 'lg': return '450px'
        case 'xl': return '450px'
      }
      return '150px';
    },
///////////////////////////////////////////////////////////////////////////////
    ...mapGetters({
      navItemsGroup: 'navigation/navItemsGroup',
      navItems: 'navigation/navItems',
      rightNavItems: 'navigation/rightNavItems',
      titles: 'navigation/titles',
      topics: 'navigation/topics',
      showLeftArrow: 'navigation/showLeftArrow',
      showhome: 'navigation/showhome',
      showPhys: 'navigation/showPhys',
      showMath: 'navigation/showMath',
      showChem: 'navigation/showChem',
      showEng: 'navigation/showEng',
      showTeam:'navigation/showTeam',
      showContact:'navigation/showContact',
      showFind:'navigation/showFind',
      showIP:'navigation/showIP',
      showModule:'navigation/showModule',
      showSwing:'navigation/showSwing',
      showProton:'navigation/showProton',
      showGraph:'navigation/showGraph',
      showBrain:'navigation/showBrain',
      showPNP:'navigation/showPNP',
      registerRole: 'navigation/registerRole',
      userDashboard: 'navigation/userDashboard',
      price: 'navigation/price',
      newPrice:'navigation/newPrice',
      discount: 'navigation/discount',
      courseStatus: 'navigation/courseStatus',
      titleStatus: 'navigation/titleStatus',
      subscriptionId: 'navigation/subscriptionId',
      subscriptionEnd: 'navigation/subscriptionEnd',
      loginModalValue: 'navigation/loginModalValue',
      showEnrollmentAdvert:'navigation/showEnrollmentAdvert',
      showCart:'navigation/showCart',
      showSample:'navigation/showSample'
    })
},
//////////////////////////////////////////////////////
async mounted () {
 console.log(this.$route, this.loginStatus, this.leftDrawer);
 // console.log(window.location.href.split('access_token=')[1]);
 if(window.location.href.split('access_token=')[1])
 {
   let getToken =  window.location.href.split('access_token=')[1].split('&user_id=')
   localStorage.setItem('token', getToken[0])
   let userId;
   if(getToken[1].includes("#")){
     userId = getToken[1].split('#')
     userId = userId[0]
   } else {
     userId = getToken[1]
   }
   localStorage.setItem('userId', userId)
   if(localStorage.getItem('redirect')){
     this.$router.push(`/checkout?id=${localStorage.getItem('redirect')}`)
   } else {
     this.$router.push('/dashboard')
   }
//////////////////////////////////////Notification Upon Successfull Login
   this.$notify(
     {
     group: 'notify',
     type: 'success',
     title: 'Success',
     text: "Login successfully"
    })
   // console.log(localStorage.getItem('token'));
 }
 if (localStorage.getItem('token')) {
   console.log("token found.")
   this.loginStatus = true
 }
 else{
   console.log("token does not exist.")
   this.loginStatus = false
 }
 },
  //////////////////////////////////////////////////////////////////////////
  updated(){
    console.log("loginModalValue::", this.loginModalValue)
    if(this.loginModalValue)
    {
      console.log("Innnnn")
      this.dialog = true
    }
          },
/////////////////////////////////////////////////////////////////
  methods : {
///////////////////////////////DRAWER CONTROL/////////////////////////////////
//////////////////////////////LEFT DRAWER CONTROL///////////////////////////////
    showTopics: function ()
    {
       this.toolBarConfig.model = !this.toolBarConfig.model;
    },
////////////////////////RIGHT DRAWER CONTROL ///////////////////////////////////
    showRightNavDrawer: function ()
    {
       this.toolBarConfig.rightModel = !this.toolBarConfig.rightModel;
    },
////////////////////////////////////////////////////////////////////////////////
    remove (item)
    {
    const index = this.friends.indexOf(item.name)
    if (index >= 0) this.friends.splice(index, 1)
    },
///////////////////////////////////LOGOUT MODULE////////////////////////////////
    logout(){
      console.log("Logged in?", this.checkLogin());
      this.$confirm(
        { title: '',
          message: `Are you sure you want to logout?`,
          button:
          {
            no: 'No',
            yes: 'Yes'
          },
       callback: async (confirm) => {
         if (confirm)
         {
           this.loginStatus = false;
           this.dialog = false
           localStorage.removeItem('token');
           localStorage.removeItem('userId');
           localStorage.removeItem('redirect');
           console.log("Logged in?", this.checkLogin());
           this.$router.push('/');
           this.$notify({
             group: 'notify',
             type: 'success',
             title: 'Success',
             text: "You are now logged out."});
         } else {
           // console.log("error::", response);
           this.$notify({
                     group: 'notify',
                     type: 'warn',
                     title: 'Cancelled!!',
                     text: 'You cancelled logout.'
           });
          console.log("Logged in?", this.checkLogin());
         }
       }
     })
   },
///////////////////////////////////LOGIN MODULE ///////////////////////////////////
    checkLogin ()
    {
      if (localStorage.getItem('token'))
      {
        this.userName = "User Name"
        return true
      }
      else
      {
        this.userName = ""
        return false
      }
    },

///////////////////////////////SEARCH MODULE IN TOOLBAR/////////////////////////////
//
//
onSubmit()
    {
      console.log(this.subs.name)
      if (this.subs.name == null && this.grades.name == null) {
      this.$router.push({path:"/"});
      this.$notify({
          group: 'notify',
          type: 'error',
          title: 'Subject and Grade not specified.',
          text: 'You need to specify a subject and a grade to search.'});      
      }
      else if(this.grades.name==null && this.subs.name !==null){
      this.$router.push({path:"/grades"+(this.subs.name)});
      }
      else{
      this.$router.push({path:"/"+(this.subs.name+this.grades.name)});}
      //this.$root.$emit('eventing', this.finder.subject);
    }, 
 /*
    async onSubmit()
     {
       try{
       if(this.subs.name != null)
       {
         console.log("Subject name specified::", this.subs.name);
         const getCat = await axios.get(process.env.VUE_APP_API+'categories');
         if(getCat.data.success)
         {
           this.catArr = getCat.data.data.categories.slice(3, 6);
           for(let i in this.catArr)
           {
             if(this.catArr[i].title == this.subs.name)
             {
               this.link1 = this.catArr[i].link+'?id='+this.catArr[i].id;
               this.id = this.catArr[i].id;
             }
           }
         }
       if(this.grades.name != null){
         console.log("Grade level specified::", this.grades.name);
         const getGrade = await axios.get(process.env.VUE_APP_API+'category/'+this.id+'/courses');
         if(getGrade.data.success)
         {
           this.gradesList = getGrade.data.data.category.courses;
           console.log("grade name ::", this.gradesList);
           for (let j in this.gradesList){
           if((this.gradesList[j].title).split(" ")[0] == this.grades.name)
           {
             this.link2 = this.grades.name+'?grade='+this.gradesList[j].id;
           }
         }
       }
       }
     }}
     catch (error) {
       console.error(error);
       this.$notify({
                 group: 'notify',
                 type: 'error',
                 title: 'Subject not specified.',
                 text: 'Specify a subject to start searching.'
       });
     }
       if(this.grades.name == null && this.subs.name == null) {
         //this.$router.push({path:"/"});
         this.$notify({
               group: 'notify',
               type: 'error',
               title: 'Subject and Grade not specified.',
               text: 'You need to specify a subject and a grade to search.'
             });
           }
       else if(this.grades.name != null && this.subs.name != null){
         this.$router.push({path:'/'+this.subs.name+this.link2});
       }
       else if(this.grades.name == null && this.subs.name != null)
       {
       this.$router.push({path:this.link1});
       }
       else if(this.grades.name!= null && this.subs.name == null){
         this.$notify({
                   group: 'notify',
                   type: 'error',
                   title: 'Subject NOT specified',
                   text: 'You need to specify a subject to search.'
         });
       }
     } */
//////////////////////////////////////////////////////////////////////////
  async socialLogin(params) {
  try {
    let socialParam;
    if(params == "google") socialParam = 'google'
    if(params == "fb") socialParam = 'facebook'
    const response = await axios.get(process.env.VUE_APP_API+'auth/redirect/'+socialParam);
    // console.log(response);
    if(response.data.success){
      window.location = response.data.data.provider_redirect;
    } else {
      // console.log("error::", response);
      this.$notify({
                group: 'notify',
                type: 'error',
                title: 'Error!!',
                text: 'Something went wrong!!'
      });
    }
  } catch (error) {
    console.error(error);
     this.$notify({
              group: 'notify',
              type: 'error',
              title: 'Error!!',
              text: 'Something went wrong!!'
    });
  }
},
////////////////////////////////ENROLLMENT MODULE//////////////////////////////
enrollFunc()
{
  console.log("token12::", localStorage.getItem('token'))
  if (localStorage.getItem('token')) {
    this.$router.push('/checkout?id='+this.$route.query.grade)
    // this.$router.push('/checkout?id='+this.$route.query.grade).catch((e)=>{});
  }else{
   this.dialog = true;
  }
},
////////////////////////////////////////////////////////////////UNSUBSCRIBE FUNCTION ///////////////////////////////////////////////////
unsubscribeFunc(){
  this.$confirm(
    {
      message: `Are you sure want to cancel subscription?`,
      button: {
        no: 'No',
        yes: 'Yes'
      },
      callback: async (confirm) => {
        if (confirm) {
          console.log("yessssss")
          let postData = {
            user_id: localStorage.getItem('userId'),
            app_subscription_id: this.subscriptionId
          }
          console.log(postData);
          const response = await axios.post(process.env.VUE_APP_API+'user/subscription/cancel', postData, { headers: {Authorization: 'Bearer ' + localStorage.getItem('token')}});
          console.log(response);
          if(response.data.success){
            console.log("success")
            this.subscriptionEnd = response.data.data.subscription.ends_at;
            this.$store.commit('navigation/appSubscriptionEnd', response.data.data.subscription.ends_at);
            this.$store.commit('navigation/courseStatusInd', response.data.data.subscription_status);
            console.log("subscriptionEnd::", this.subscriptionEnd)
            this.$notify({
                group: 'notify',
                type: 'success',
                title: 'success',
                text: "Subscription cancelled successfully!!"
      });
          } else {
            this.$notify({
                      group: 'notify',
                      type: 'error',
                      title: 'Error!!',
                      text: 'Something went wrong!!'
            });
          }
        }
      }
    }
  )
},
/////////////////////////////////////////////////////////////////////////////////////
loginModal ()
{
    this.loginStatus = false
    this.dialog = true
    localStorage.removeItem('footerPopup')
},
////////////////////////////////////////////////////////////////////////////
close(){
  this.dialog==false;
},
update(status){
    this.dialog=status;
    this.loginModalValue = false
    this.$store.commit('navigation/loginModalValue', false);
    // this.loginStatus = !status
}
}
}
</script>
<style lang="scss">
@import 'src/styles/header.scss';
@import 'src/styles/footer.scss';
.namebox {
	font-size: 16px;
	max-width: 1600px;
	padding-right:0px;
	border: solid 1px #e8e8e8;
  border-radius:2px;
  text-justify:center;
}
.form {
	font-size: 18px;
	max-width: 400px;
  border: solid 1px #e8e8e8;
	border-radius:2px;
	text-justify:center;
}
.subhead {
color: white;
float: left;
font-family: Georgia;
font-size: 75px;
line-height: 60px;
padding-top: 4px;
padding-right: 8px;
}
.head {
color: white;
font-family: Oswald;
}
.menu-hover {
color:var(--v-red-lighten3);
}
.google-cls {
  background: #dd4b39;
  margin-right: 3%;
  border-radius:4.5px;
}
.fb-cls {
  background: #3b5998;
  border-radius:4.5px;
}
.icon-cls {
  font-size: 22px;
  color: #fff;
  padding: 2% 7%;
  cursor: pointer;
}
.icon-cls:hover {
  opacity: 0.8;
}
.inner-icon {
  margin-left: 9%;
  font-family: 'Oswald'
}
.txt-center {
  text-align: center;
  padding: 3%;
  color:'grey';
}
.user-name {
  font-size: 14px;
}

.dropbtn {
    color: white;
    padding: 16px;
    font-size: 16px;
    border: none;
  }

  .dropdown {
    position: relative;
    display: inline-block;
  }

  .dropdown-content {
    display: none;
    position: absolute;
    background-color: #f1f1f1;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
  }

  .dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
  }

  .dropdown-content a:hover {background-color: #ddd;}

  .dropdown:hover .dropdown-content {display: block;}

  .dropdown:hover .dropbtn {background-color: #3e8e41;}

  .dropdown-content a {
    font-size: 14px;
   }

  i.v-icon.fa.fa.fa.fa-user-circle-o.theme--dark {
    font-size: 25px;
}

i.v-icon.fa.fa.fa.fa-envelope-o.theme--dark {
    font-size: 25px;
}
</style>