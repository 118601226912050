//THIS FILE CONTAINS OBSOLETE PATHS
const dumpItems = [
{
  path: '/courses',
  name: 'courses',
  component: () => import('./views/Home.vue')
},
{
    path: '/physics/demoCar',
    name: 'Banked Road',
    component: () => import('./components/physics/Phy12th/car_demo/Lesson.vue')
},
{
    path: '/physics/carCalculate',
    name: 'Banked Road',
    component: () => import('./components/physics/Phy12th/car_calculate/Lesson.vue')
},
{
    path: '/physics/carQuiz',
    name: 'Banked Road',
    component: () => import('./components/physics/Phy12th/car_quiz/Lesson.vue')
},
{
  path: '/new',
  name: 'New',
  component: () => import('./components/mathematics/Math9thGrade/angles/Lesson.vue')
},
{
  path: '/comingsoon',
  name: 'comingsoon',
  component: () => import('./views/ComingSoon.vue')
},
{
    path: '/for',
    name: 'For Loop',
    component: () => import('./components/programming/Lesson.vue')
  },
  {
    path: '/ForLoop',
    name: 'For Loop Flowchart',
    component: () => import('./components/coding/ForLoop.vue')
  },
  {
      path: '/gradesProgramming',
      name: 'Prog Grades',
      component: () => import('./views/Prog/Programming.vue')
  },
  {
      path: '/physics/stress',
      name: 'Stress',
      component: () => import('./components/physics/stress/Stress.vue')
    },
    {
        path: '/physics/12th/forces',
        name: 'Forces',
        component: () => import('./components/physics/forces/Lesson.vue')
    },
    {
        path: '/physics/hydrostatic',
        name: 'Hydrostatic Pressure',
        component: () => import('./components/physics/hydrostatic/Lesson.vue')
    },
    {
        path: '/physics/phase',
        name: 'Phase Difference',
        component: () => import('./components/physics/phase/Lesson.vue')
    },
    {
        path: '/physics/waveA',
        name: 'Amplitude of a Wave',
        component: () => import('./components/physics/wave/Lesson.vue')
    },
    {
        path: '/physics/wavef',
        name: 'Frequency of a Wave',
        component: () => import('./components/physics/wavef/Lesson.vue')
    },
    {
        path: '/physics/wavet',
        name: 'Longitudinal vs. Transverse Wave',
        component: () => import('./components/physics/wavet/Lesson.vue')
    },
    {
        path: '/physics/eqn',
        name: 'Equation of Motion',
        component: () => import('./components/physics/eqn/Lesson.vue')
    },
    {
        path: '/physics/gravitation',
        name: 'Law of Gravitation',
        component: () => import('./components/physics/gravitation/Lesson.vue')
    },
    {
        path: '/physics/motion',
        name: 'Newton\s Laws',
        component: () => import('./components/physics/newton/Lesson.vue')
    },
    {
        path: '/physics/concavemirrors',
        name: 'Concave Mirrors',
        component: () => import('./components/physics/mirror/Lesson.vue')
    },
    {
        path: '/physics/convexmirrors',
        name: 'Convex Mirrors',
        component: () => import('./components/physics/convexmirror/Lesson.vue')
    },
    {
        path: '/physics/FBD',
        name: 'Free Body Diagram',
        component: () => import('./components/physics/FBD/Lesson.vue')
    },
    {
        path: '/physics/work',
        name: 'Work-Energy ',
        component: () => import('./components/physics/work/Lesson.vue')
    },
    {
        path: '/physics/cm',
        name: 'Center of Mass ',
        component: () => import('./components/physics/centerofmass/Lesson.vue')
    },
    {
        path: '/physics/conservation',
        name: 'Conservation of Energy ',
        component: () => import('./components/physics/conservationofenergy/Lesson.vue')
    },
    {
      path: '/physics/buoyancy',
      name: 'buoyancy',
      component: () => import('./components/physics/buo/Buo.vue')
    },
    {
      path: '/physics/faraday',
      name: 'Faraday\'s Law',
      component: () => import('./components/physics/magnetism/Lesson.vue')
    },
    {
      path: '/mathematics/11th/arithmetic',
      name: 'arithmetic',
      component: () => import('./components/mathematics/arithmetic/Lesson.vue')
    },
    {
      path: '/physics/bernoulli',
      name: 'bernoulli-principle',
      component: () => import('./components/physics/bernoulli/Lesson.vue')
    },
    {
      path: '/physics/expansion',
      name: 'Thermal Expansion',
      component: () => import('./components/physics/thermal/Lesson.vue')
    },
    {path: '/physics/11th/rolling',
    name: 'rolling motion ',
    component: () => import('./components/physics/circle/Lesson.vue')
    },
    {path: '/physics/angularmotion',
    name: 'Angular Motion',
    component: () => import('./components/physics/angularmomentum/Lesson.vue')
    },
    {path: '/physics/flux',
    name: 'Electric Flux',
    component: () => import('./components/physics/electricflux/Lesson.vue')
    },
    {path: '/physics/magnet',
    name: 'Magnetic Field',
    component: () => import('./components/physics/magfield/Lesson.vue')
    },
    {path: '/physics/units',
    name: 'Unit Conversion',
    component: () => import('./components/physics/units/Lesson.vue')
    },

        {
          path: '/physics/12th/friction',
          name: 'Static Friction',
          component: () => import('./components/mechanics/friction/Friction.vue'),
        },
        {
          path: '/forces-in-mechanics',
          name: 'forces_in_mechanics',
          component: () => import('./components/mechanics/SubtopicMenu.vue')
        },

        /* Heat & Mass Transfer */
        /*Friction*/
        {
          path: '/mechanics/friction',
          name: 'friction2',
          component: () => import('./components/mechanics/friction/Friction.vue')
        },
        {
          path: '/mechanics/buoyancy',
          name: 'buoyancy-deprecated',
          component: () => import('./components/mechanics/buoyancy_force/Buo.vue')
        },

        {
          path: '/transformation-laws/vector-transformation',
          name: 'vector-transformationn',
          component: () => import('./components/transformation_laws/vector_transformation/VectorTransformation.vue')
        },
        /*mathematics*/
      /*  {
          path: '/mathematics/qe',
          name: 'quadratic-equation',
          component: () => import('./components/mathematics/qe/QuadraticEquation.vue')
        },*/
        {
          path: '/mathematics/sample',
          name: 'coordinate geometry',
          component: () => import('./components/mathematics/Math9thGrade/coord/SubtopicMenu')
        },
        {
          path: '/mathematics/tangentlength',
          name: 'Tangent Length 2',
          component: () => import('./components/mathematics/tangent/Lesson.vue')
        },
        {
          path: '/mathematics/triangleArea',
          name: 'Area of a Triangle',
          component: () => import('./components/mathematics/triangleArea/Lesson.vue')
        },
        {
          path: '/mathematics/tangent',
          name: 'Tangent to a Circle 2',
          component: () => import('./components/mathematics/tangent2/Lesson.vue')
        },
        {
           path: '/mathematics/9th/Perimeter',
           name: 'Perimeter of a Circle',
           component: () => import('./components/mathematics/circle/Lesson.vue')
         },
    	 {
          path: '/mathematics/8th/le',
          name: 'Linear Equation in One Variable 8th',
          component: () => import('./components/mathematics/lineq/Lesson.vue')
        },
    	{
          path: '/mathematics/line',
          name: 'Line',
          component: () => import('./components/mathematics/line/Line.vue')
        },
        {
          path: '/mathematics/trigonometry',
          name: 'Trigonometry',
          component: () => import('./components/mathematics/trig/Lesson.vue')
        },
    	{
          path: '/vectors',
          name: 'vectors',
          component: () => import('./components/mathematics/vectors/SubtopicMenu.vue')
        },
    	{
          path: '/mathematics/polynomials',
          name: 'Polynomials',
          component: () => import('./components/mathematics/polynomials/SubtopicMenu.vue')
        },
    	{
          path: '/mathematics/polynomials/order',
          name: 'Order',
          component: () => import('./components/mathematics/polynomials/order/Lesson.vue')
        },
    	{
          path: '/mathematics/polynomials/zeros',
          name: 'Zeros',
          component: () => import('./components/mathematics/polynomials/roots/Lesson.vue')
        },
    	{
          path: '/mathematics/polynomials/coefficients',
          name: 'Polynomial Coeffs',
          component: () => import('./components/mathematics/polynomials/coefficients/Lesson.vue')
        },
    	{
          path: '/mathematics/polynomials/remainder',
          name: 'Polynomial Remainder',
          component: () => import('./components/mathematics/polynomials/rt/Lesson.vue')
        },
    	{
          path: '/mathematics/vectors/trigonometry',
          name: 'trig',
          component: () => import('./components/mathematics/vectors/trigonometry/Lesson.vue')
        },
    	{
          path: '/mathematics/square',
          name: 'sq',
          component: () => import('./components/mathematics/sq/Lesson.vue')
        },
        {
          path: '/mathematics/sine',
          name: 'Sine Function',
          component: () => import('./components/mathematics/sine/Lesson.vue')
        },
    //////////////////CHEMISTRY TOPICS ////////////////////
      {
        path: '/chemistry/sample',
        name: 'Balancing a Chemical Equation',
        component: () => import('./components/chemistry/bce/SubtopicMenu.vue')
      },
        {
          path: '/chemistry/bce',
          name: 'Balancing a Chemical Equation',
          component: () => import('./components/chemistry/bce/SubtopicMenu.vue')
        },
        {
          path: '/chemistry/combustion',
          name: 'Chemical equation of combustion',
          component: () => import('./components/chemistry/bce/combustion/Lesson.vue')
        },
        {
          path: '/chemistry/rust',
          name: 'Chemical equation of rusting',
          component: () => import('./components/chemistry/bce/rusting/Lesson.vue')
        },
        {
          path: '/chemistry/photosynthesis',
          name: 'Chemical equation of photosynthesis',
          component: () => import('./components/chemistry/bce/photosynthesis/Lesson.vue')
        },
    	{
          path: '/chemistry/ionicbond',
          name: 'Ionic Bonds',
          component: () => import('./components/chemistry/ionicbond/IonicBond.vue')
        },
    	{
          path: '/chemistry/electronic',
          name: 'electronic',
          component: () => import('./components/chemistry/electronic/Electronic.vue')
        },
    		{
          path: '/chemistry/boyle',
          name: 'boyle',
          component: () => import('./components/chemistry/boyle/Lesson.vue')
        },
        {
          path: '/chemistry/charles',
          name: 'charles',
          component: () => import('./components/chemistry/charles/Lesson.vue')
        },
        /*Harmonic Motion*/
        {
          path: '/physics/sp',
          name: 'harmonic motion',
          component: () => import('./components/physics/sp/SubtopicMenu.vue')
        },
        {
          path: '/physics/sp/sp',
          name: 'simple pendulum',
          component: () => import('./components/physics/sp/sp/SimplePendulum.vue')
        },
        {
          path: '/physics/sp/sm',
          name: 'spring mass system',
          component: () => import('./components/physics/sp/sm/Spring.vue')
        },
        {
          path: '/physics/sample',
          name: 'springs',
          component: () => import('./components/physics/springs/SubtopicMenu.vue')
        },
        {
          path: '/physics/sm',
          name: 'springs',
          component: () => import('./components/physics/springs/SubtopicMenu.vue')
        },
        {
          path: '/physics/refraction',
          name: 'refraction_of_light',
          component: () => import('./components/physics/refraction/Refraction.vue')
        },
        {
          path: '/physics/snell',
          name: 'Snell\'s Law',
          component: () => import('./components/physics/optics/refraction/Refraction.vue')
        },
        {
          path: '/physics/lens',
          name: 'Lens Theory',
          component: () => import('./components/physics/lens/Lesson.vue')
        },
    	    {
          path: '/physics/friction',
          name: 'friction',
          component: () => import('./components/physics/friction/Friction.vue')
        },
        {
          path: '/physics/electricity',
          name: 'electricity',
          component: () => import('./components/physics/electricity/SubtopicMenu.vue')
        },
        {
          path: '/physics/fluids',
          name: 'fluids',
          component: () => import('./components/physics/fluids/SubtopicMenu.vue')
        },
        {
          path: '/physics/electricity/capacitors',
          name: 'capacitors',
          component: () => import('./components/physics/electricity/capacitors/Resistor.vue')
        },
        {
          path: '/physics/electricity/ol',
          name: 'OhmsLaw',
          component: () => import('./components/physics/electricity/ol/Resistor.vue')
        },
        {
          path: '/physics/electricity/resistors',
          name: 'resistor',
          component: () => import('./components/physics/electricity/resistors/Resistor.vue')
        },
        {
          path: '/physics/electricity/rc',
          name: 'rc',
          component: () => import('./components/physics/electricity/rc/Resistor.vue')
        },
        {
          path: '/physics/springs/hooke',
          name: 'spring',
          component: () => import('./components/physics/springs/hooke/Spring.vue')
        },
    	{
          path: '/physics/MoI',
          name: 'MoI',
          component: () => import('./components/physics/MoI/MoI.vue')
        },
        {
          path: '/physics/moment',
          name: 'balance of moments',
          component: () => import('./components/physics/moment/Lesson.vue')
        },
    	{
          path: '/physics/springs/sos',
          name: 'system of springs',
          component: () => import('./components/physics/springs/sos/SystemofSprings.vue')
        },
        {
          path: '/physics/springs/springmass',
          name: 'springmass',
          component: () => import('./components/physics/springs/springmass/Spring.vue')
        },
        {
          path: '/physics/springs/energy',
          name: 'energy',
          component: () => import('./components/physics/springs/energy/Spring.vue')
        },
    	{
          path: '/physics/thermodynamics',
          name: 'thermo',
          component: () => import('./components/physics/thermodynamics/Lesson.vue')
        },
        {
          path: '/physics/fluids/capillary',
          name: 'capillary effect in fluids',
          component: () => import('./components/physics/fluids/capillary/Tension.vue')
        },
        {
          path: '/physics/fluids/continuity',
          name: 'principle of continuity',
          component: () => import('./components/physics/fluids/continuity/ContinuityPrinciple.vue')
        },
        {
          path: '/physics/fluids/bernoulli',
          name: 'bernoulli principle 2',
          component: () => import('./components/physics/fluids/bernoulli/BernoullisPrinciple.vue')
        },
        {
          path: '/physics/fluids/joukowsky',
          name: 'joukowsky1',
          component: () => import('./components/physics/fluids/joukowsky/Joukowsky.vue')
        },
        {
          path: '/physics/2dmotion',
          name: 'throw',
          component: () => import('./components/physics/2dmotion/Throw.vue')
        },
    	{
          path: '/physics/gravity',
          name: 'gravity',
          component: () => import('./components/mechanics/gravity/Lesson.vue')
        },
        {
          path: '/physics/circularmotion',
          name: 'circularmotion',
          component: () => import('./components/physics/circularmotion/Lesson.vue')
        },
]
export default dumpItems;
