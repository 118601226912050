import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import 'vuetify/src/stylus/app.styl'
// import theme from '../styles/theme'
// import { library } from '@fortawesome/fontawesome-svg-core'
// import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
// import { fas } from '@fortawesome/free-solid-svg-icons'
import 'font-awesome/css/font-awesome.min.css'
import 'sass-mq/_mq.scss'
// Vue.component('font-awesome-icon', FontAwesomeIcon)
// library.add(fas)

const CustomIcons = {
  'facebook': 'fab fa-facebook-square',
  'search': 'fas fa-search'
}

Vue.use(Vuetify, {
  iconfont: 'fa4',
  icons: CustomIcons,
  // theme: { theme },
  options: {
    customProperties: true
  },
  theme: {
    primary: '#000000',
    secondary: '#FFFFFF',
    accent: '#FFA500',
    error: '#C62828',
    info: '#1565C0',
    success: '#2E7D32',
    warning: '#F9A825',
    red: "#800000"
  }
})
