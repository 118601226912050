import router from '../../../router'
const Math6th =[
  {name:"Practical Geometry I",
   lock:false,
   img:"/assets/divider.svg",
   data:
   [
    {
    title: "Geometric Tools",
    subtitle:'(Practice)',
    flex:12,
    icon:'fas fa-lock-open',
    action: () => router.push({path: '/mathematics/toolset'})
   },
   {
     title: "Drawing a Line",
    subtitle:'(Practice)',
     flex:6,
     icon:'fas fa-lock-open',
     action: () => router.push({path: '/mathematics/drawLine'})
   },
   {
   title: "Drawing a Circle",
   subtitle:'(Practice)',
   flex:6,
   icon:'fas fa-lock-open',
   action: () => router.push({path:'/mathematics/drawCircle'})
 },
 {
 title: "Drawing a Triangle",
 subtitle:'(Practice)',
 flex:12,
 icon:'fas fa-lock-open',
 action: () => router.push({path:'/mathematics/drawTriangle'})
},
]},

 {name:"Practical Geometry II",
  lock:true,
  img:"/assets/divider.svg",
  data:
  [
 {
   title: "Perpendicular to a Line",
   description: ['Construction of a Line through a Point','Construction of a Line parallel to another Line','Construction of Triangles','. . .'],
   flex:6,
   icon:'fas fa-lock',
   action: () => router.push({path: '/mathematics6th/demoPerp'})
 },
 {
   title: "Perpendicular Bisector of a Line",
   description: ['Construction of a Line through a Point','Construction of a Line parallel to another Line','Construction of Triangles','. . .'],
   flex:6,
   icon:'fas fa-lock',
   action: () => router.push({path: '/mathematics6th/demoPerpBisector'})
 },
 {
   title: "Drawing Angle Bisector",
   description: ['Construction of a Line through a Point','Construction of a Line parallel to another Line','Construction of Triangles','. . .'],
   flex:12,
   icon:'fas fa-lock',
   action: () => router.push({path: '/mathematics6th/demoAngleBisector'})
 }
]},
{name:'Numbers', lock:true, img:"/assets/numbers.svg", data:[
      {
        title: "Integers on a Number Line",
        description: ['Integers','Integers on a Number Line','Adding & Subtracting Integers', 'Comparing Integers','. . .'],
        icon: 'fas fa-lock',
        flex:6,
        action: () => router.push({path: '/mathematics6th/quizInteger'})
      },
      {
        title: "Substraction of Integers",
        description: ['Integers','Integers on a Number Line','Adding & Subtracting Integers', 'Comparing Integers','. . .'],
        icon: 'fas fa-lock',
        flex:6,
        action: () => router.push({path: '/mathematics6th/quizSubtraction'})
      },
      {
        title: "Fractions",
        description: ['Fractions', 'Fractions on a Number Line','Adding & Subtracting Fractions','Proper & Improper Fractions','. . .'],
        flex:12,
        icon: 'fas fa-lock',
        action: () => router.push({path:'/mathematics/fractions2'})
      }
    ]
    },
    {name:'Decimals I', lock:true, img:"/assets/decimal.svg", data:[
          {
            title: "Decimal Places",
            subtitle:"(Concepts)",
            description: ['Integers','Integers on a Number Line','Adding & Subtracting Integers', 'Comparing Integers','. . .'],
            flex:6,
            icon: 'fas fa-lock',
            action: () => router.push({path: '/mathematics/decimal'})
          },
          {
            title: "Build a Decimal Number",
            description: ['Decimals: Tenths & Hundreths','Decimals on a Number Line','Adding Decimals','Subtracting Decimals','. . .'],
            flex:6,
            icon: 'fas fa-lock',
            action: () => router.push({path: '/mathematics/decimal3'})
          },
          {
            title: "Decimals Places",
            subtitle:"(Quiz)",
            description: ['Fractions', 'Fractions on a Number Line','Adding & Subtracting Fractions','Proper & Improper Fractions','. . .'],
            icon: 'fas fa-lock',
            flex:12,
            action: () => router.push({path:'/mathematics/decimal2'})
          }
        ]
        },
        {name:'Decimals II', lock:true, img:"/assets/decimal.svg", data:[
          {
            title: "Decimal on a Number Line",
            description: ['Integers','Integers on a Number Line','Adding & Subtracting Integers', 'Comparing Integers','. . .'],
            flex:12,
            icon: 'fas fa-lock',
            action: () => router.push({path: '/mathematics/decimal'})
          },
              {
                title: "Fractions to Decimal",
                description: ['Integers','Integers on a Number Line','Adding & Subtracting Integers', 'Comparing Integers','. . .'],
                flex:12,
                icon: 'fas fa-lock',
                action: () => router.push({path: '/mathematics/decimal'})
              }
            ]
            },
    {name:'Algebra', lock:true, img:"/assets/alg.svg", data:[ {
        title: "Parts of an Expression",
        description:['Terms of an Expression','Coefficients','Using Expressions','. . .'],
        icon: 'fas fa-lock',
          flex:6,
        action: () => router.push({path: '/mathematics/expression'})
      },
      {
        title: "Build an Expression",
        description: ['Equations','Setting up an Equation','Solution of an Equation','. . .'],
        icon: 'fas fa-lock',
      flex:6,
        action: () => router.push({path: '/mathematics/expression2'})
      },
      {
        title: "Equations in One Variable",
        description: ['Equations','Setting up an Equation','Solution of an Equation','. . .'],
        icon: 'fas fa-lock',
          flex:12,
        action: () => router.push({path: '/mathematics/equations'})
      }
    ]},
    {name:'Geometry',lock:true, img:"/assets/shapes.svg", data:[
      {
        title: "Lines & Angles",
        description: ['Introduction to Triangles','Types of Triangles Based on Sides','Types of Triangles Based on Angles','. . .'],
        icon: 'fas fa-lock',
          flex:6,
        action: () => router.push({path: '/mathematics6th/geometric'})
     },
      {
        title: "Triangles",
        description: ['Introduction to Triangles','Types of Triangles Based on Sides','Types of Triangles Based on Angles','. . .'],
        icon: 'fas fa-lock',
          flex:6,
        action: () => router.push({path: '/mathematics/triangles/sum'})
     },
     {
       title: "Types of Angles",
       description: ['Angles', 'Acute Angles','Obtuse Angles','Right Angle','. . .'],
         flex:12,
       icon: 'fas fa-lock',
       action: () => router.push({path: '/mathematics6th/demoAngles'})
     },
    /*  {
        title: "Quadrilaterals",
        description: ['Introduction to Quadrilaterals', 'Types of Quadrilaterals','. . .'],
        icon: 'fas fa-lock',
        isBlack: true,
        action: () => router.push({path:''})
      }*/
    ]},
    {name:'Parallelograms', lock:true, img:"/assets/parallelogram.svg",  data:[
      {
      title: "Area of a Parallelogram",
      subtitle:"(Concepts)",
      description: ['Collecting Data','Organization of Data','Plotting Data','Pictographs','Bar Graphs','. . .'],
      flex:6,
      icon: 'fas fa-lock',
      action: () => router.push({path: '/mathematics6th/demoParallelogram'})
    },
    {
    title: "Area of a Parallelogram",
    subtitle:"(Practice)",
    description: ['Collecting Data','Organization of Data','Plotting Data','Pictographs','Bar Graphs','. . .'],
    flex:6,
    icon: 'fas fa-lock',
    action: () => router.push({path: '/mathematics6th/quizParallelogram'})
  },
  {
  title: "Area of a Parallelogram",
  subtitle:"(Quiz)",
  description: ['Collecting Data','Organization of Data','Plotting Data','Pictographs','Bar Graphs','. . .'],
  flex:12,
  icon: 'fas fa-lock',
  action: () => router.push({path: '/mathematics6th/quizParallelogram2'})
},
  ]
},

{name:'Triangles', lock:true, img:"/assets/triangle.svg",  data:[
  {
  title: "Area of a Triangle",
  subtitle:"(Concepts)",
  description: ['Collecting Data','Organization of Data','Plotting Data','Pictographs','Bar Graphs','. . .'],
    flex:6,
  icon: 'fas fa-lock',
  action: () => router.push({path: '/mathematics6th/demoTriangle'})
},
{
title: "Area of a Triangle",
subtitle:"(Practice)",
description: ['Collecting Data','Organization of Data','Plotting Data','Pictographs','Bar Graphs','. . .'],
flex:6,
icon: 'fas fa-lock',
action: () => router.push({path: '/mathematics6th/practiceTriangle'})
},
{
title: "Area of a Triangle",
subtitle:"(Quiz)",
description: ['Collecting Data','Organization of Data','Plotting Data','Pictographs','Bar Graphs','. . .'],
  flex:12,
icon: 'fas fa-lock',
action: () => router.push({path: '/mathematics6th/quizTriangle'})
},
]
},
{name:'Geometric Measurement', lock:true, img:"/assets/mensuration.svg",  data:[
      {
        title: "Circumference of a Circle",
        subtitle:"(Concepts)",
        description: ['Squares', 'Rectangles', 'Parallelograms','Triangles','. . .'],
        icon: 'fas fa-lock',
        flex:12,
        action: () => router.push({path: '/mathematics6th/perimeter'})
      },
      {
        title: "Perimeter of a Rectangle",
        subtitle:"(Concepts)",
        description: ['Squares', 'Rectangles', 'Parallelograms','Triangles','. . .'],
        icon: 'fas fa-lock',
        flex:6,
        action: () => router.push({path: '/mathematics6th/perimeter2'})
      },
      {
        title: "Perimeter of a Rectangle",
        subtitle:"(Quiz)",
        description: ['Squares', 'Rectangles', 'Parallelograms','Triangles','. . .'],
        icon: 'fas fa-lock',
        flex:6,
        action: () => router.push({path: '/mathematics6th/quizPerimeter'})
      },
      {
          title: "Area of Geometric Shapes",
          subtitle:"(Concepts)",
          description: ['Squares', 'Rectangles', 'Parallelograms','Triangles','. . .'],
          icon: 'fas fa-lock',
          flex:12,
          action: () => router.push({path: '/mathematics/area'})
        },
    ]
    },
    {name:'Ratios & Proportions',
     lock:true,
     img:"/assets/golden-ratio.svg",
     data:
     [
      {
      title: "Ratios",
      subtitle:"(Concepts)",
      description: ['What is Ratio?','Finding Ratio', 'Equivalent Ratios','. . .'],
      flex:12,
      icon: 'fas fa-lock',
      action: () => router.push({path: '/mathematics/ratios'})
    },
    {
    title: "Ratios",
    subtitle:"(Quiz)",
    description: ['What is Ratio?','Finding Ratio', 'Equivalent Ratios','. . .'],
    flex:6,
    icon: 'fas fa-lock',
    action: () => router.push({path: '/mathematics6th/quizRatio'})
  },
    {
    title: "Proportions",
    subtitle:"(Concepts)",
    description: ['Proportionality','Calculating Proportions', 'Practical Use of Proportions','. . .'],
    flex:6,
    icon: 'fas fa-lock',
    action: () => router.push({path: '/mathematics/proportions'})
  },
  {
  title: "Proportions",
  subtitle:"(Quiz)",
  description: ['Proportionality','Calculating Proportions', 'Practical Use of Proportions','. . .'],
  flex:12,
  icon: 'fas fa-lock',
  action: () => router.push({path: '/mathematics6th/quizProportion'})
},
    ]
   },
   {name:'Symmetry', lock:true,
    img:"/assets/symmetry.svg",
    data:
    [
     {
     title: "Symmetric Objects",
     subtitle:"(Concepts)",
     description: ['What is Symmetry?','Symmetric Objects', 'Line of Symmetry','. . .'],
      flex:12,
     icon: 'fas fa-lock',
     action: () => router.push({path: '/mathematics6th/symmetry'})
   },
   {
   title: "Symmetric Objects",
   subtitle:"(Quiz)",
   description: ['What is Symmetry?','Symmetric Objects', 'Line of Symmetry','. . .'],
    flex:5,
   icon: 'fas fa-lock',
   action: () => router.push({path: '/mathematics6th/quizSymmetry'})
 },
   {
   title: "Draw a Symmetric Shape",
   subtitle:"(Practice)",
   description: ['What is Symmetry?','Symmetric Objects', 'Line of Symmetry','. . .'],
   flex:7,
   icon: 'fas fa-lock',
   action: () => router.push({path: '/mathematics6th/symmetrydraw'})
 },
 {
 title: "Line of Symmetry",
 subtitle:"(Quiz)",
 description: ['What is Symmetry?','Symmetric Objects', 'Line of Symmetry','. . .'],
   flex:12,
 icon: 'fas fa-lock',
 action: () => router.push({path: '/mathematics6th/quizSymmetryLine'})
},
   ]
  },
{name:'Data & Statistics',  lock:true, img:"/assets/graph-paper.svg",  data:[
    {
    title: "Arithmetic Mean",
    subtitle:"(Concepts)",
    description: ['Collecting Data','Organization of Data','Plotting Data','Pictographs','Bar Graphs','. . .'],
    flex:12,
    icon: 'fas fa-lock',
    action: () => router.push({path: '/mathematics6th/quizMean'})
  },
  {
  title: "Arithmetic Mean",
  subtitle:"(Quiz)",
  description: ['Collecting Data','Organization of Data','Plotting Data','Pictographs','Bar Graphs','. . .'],
    flex:12,
  icon: 'fas fa-lock',
  action: () => router.push({path: '/mathematics6th/quizMean'})
}
  ]},
{name:'Visualizing Data', lock:true, img:"/assets/data-handling.svg",  data:[
    {
      title: "Types of Data Visualization",
        subtitle:"(Concepts)",
      description: ['Collecting Data','Organization of Data','Plotting Data','Pictographs','Bar Graphs','. . .'],
      flex:12,
      icon: 'fas fa-lock',
      action: () => router.push({path: '/mathematics6th/data'})
    },
    {
      title: "Build a Bar Chart",
        subtitle:"(Practice)",
      description: ['Collecting Data','Organization of Data','Plotting Data','Pictographs','Bar Graphs','. . .'],
      flex:6,
      icon: 'fas fa-lock',
      action: () => router.push({path: '/mathematics7th/quizBar'})
    },
    {
    title: "Build a Pie Chart",
      subtitle:"(Practice)",
    description: ['Collecting Data','Organization of Data','Plotting Data','Pictographs','Bar Graphs','. . .'],
    icon: 'fas fa-lock',
    flex:6,
    action: () => router.push({path: '/mathematics/pie'})
  },
  {
    title: "Build a Pictogram",
      subtitle:"(Practice)",
    description: ['Collecting Data','Organization of Data','Plotting Data','Pictographs','Bar Graphs','. . .'],
    icon: 'fas fa-lock',
    flex:12,
    action: () => router.push({path: '/mathematics/pic'})
  }
  ]
    }
]
export default Math6th;
////////////////////////////END OF FILE /////////////////////////////////////////
