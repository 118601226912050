////////////////////////////6TH GRADE MATH TOPICS////////////////////////////////
import router from '../../../router'
const Math12th =[
  {name:'Probability', lock:false, img:"/assets/probs.svg", data:[
  {
    title: "Probability of an Event",
    description: ['Event & Outcomes','Probability of an Event','Sum of Probabilities', '. . .'],
    flex:12,
    icon: 'fas fa-lock-open',
    action: () => router.push({path: '/mathematics12th/prob'})

  },
  {
    title: "Probability II",
    description: ['Event & Outcomes','Probability of an Event','Sum of Probabilities', '. . .'],
    flex:4,
    icon: 'fas fa-lock-open',
    action: () => router.push({path: '/mathematics/probability'})
  },
  {
    title: "Probability of A or B",
    description: ['Event & Outcomes','Probability of an Event','Sum of Probabilities', '. . .'],
    flex:8,
    icon: 'fas fa-lock-open',
    action: () => router.push({path: '/mathematics12th/quizProb'})
  },
  {
    title: "Conditional Probability",
    description: ['Event & Outcomes','Probability of an Event','Sum of Probabilities', '. . .'],
    flex:12,
    icon: 'fas fa-lock-open',
    action: () => router.push({path: '/mathematics12th/conditionalProb'})
  },
]},
  {name:'Relations & Functions',lock:true, img:"/assets/relations.svg", data:[
    {
      title: "Plotting Relations on a Graph",
      description: ['Domain & Range of a Function','One-to-One Function','Onto Functions', 'Composite & Inverse Functions', '. . .'],
      flex:7,
      icon: 'fas fa-lock',
      action: () => router.push({path:'/mathematics12th/mappings'})
    },
    {
      title: "Relation Tables",
      description: ['Domain & Range of a Function','One-to-One Function','Onto Functions', 'Composite & Inverse Functions', '. . .'],
      flex:5,
      icon: 'fas fa-lock',
      action: () => router.push({path:'/mathematics12th/tables'})
    },
    {
      title: "Identifying Functions",
      description: ['Domain & Range of a Function','One-to-One Function','Onto Functions', 'Composite & Inverse Functions', '. . .'],
      flex:12,
      icon: 'fas fa-lock',
      action: () => router.push({path:'/mathematics12th/functions1'})
    },

  ]},
  {name:'Calculus', lock:true, img:"/assets/calculus.svg",  data:[
    {
      title: "Continuity & Differentiability",
      description: ['Differentiable Functions', 'Derivative of a Composite Function', 'Chain Rule', 'Derivatives of Some Known Functions','. . .'],
      icon: 'fas fa-lock',
      flex:12,
      action: () => router.push({path: '/comingsoon'})
    },
    {
      title: "Derivatives & Their Applications",
      description: ['Derivatives and Rate of Change', 'Tangents & Normals', 'Maxima & Minima', 'Applications to Practical Problems', '. . .'],
      icon: 'fas fa-lock',
      flex:6,
      action: () => router.push({path: '/mathematics/12th/derivatives'})
    },
    {
      title: "Integrals & Their Applications",
      description: ['Indefinite Integrals', 'Integration by Substitution', 'Integration by Parts', 'Integration by Fraction', '. . .'],
      icon: 'fas fa-lock',
      flex:6,
      action: () => router.push({path: '/mathematics/12th/integral'})
    },
    {
      title: "Differential Equations",
      description: ['Definition', 'Order', 'General & Particular Solution of a Differential Equation', 'Solution by Separation of Variables', '. . .'],
      icon: 'fas fa-lock',
      flex:12,
      action: () => router.push({path: '/comingsoon'})
    },
  ]},

  {name:'Trigonometry', lock:true, img:"/assets/right-triangle.svg",
   data:[
    {
      title: "Trigonometric Functions ",
      description: ['Base, Height & Hypotenuse', 'Trigonometric Ratios','Heights & Distances','Trigonometric Identities', '. . .'],
      icon: 'fas fa-lock',
      flex:12,
      action: () => router.push({path: '/mathematics/sine'})
    },
    {
      title: " Inverse Trigonometric Functions ",
      description: ['Definition', 'Range & Domain', 'Principal Values','Graphs of Such Functions','Properties of Such Functions', '. . .'],
      icon: 'fas fa-lock',
      flex:12,
      action: () => router.push({path: '/comingsoon'})
    },
  ]},
  {name:'Matrix Algebra', lock:true, img:"/assets/matrix.svg", data:[
    {
      title: "Matrices",
      description: ['Order of a Matrix', 'Components of a Matrix','Transpose of a Matrix','Symmetric and Skey-Symmetric Matices', '. . .'],
      flex:5,
      icon: 'fas fa-lock',
      action: () => router.push({path:'/mathematics/matrices'})
    },
    {
      title: "Matrix Operations",
      description: ['Addition/Subtraction of Matrices','Multiplication of Matrices','Matrix Inversion', '. . .'],
      flex:7,
      icon: 'fas fa-lock',
      action: () => router.push({path: '/mathematics/matrices/addition'})
    },
    {
      title: "Determinants",
      description: ['Determinant of a Matrix','Cofactors','Area of a Triangle using Determinant','Application to System of Linear Equations', '. . .'],
      flex:12,
      icon: 'fas fa-lock',
      action: () => router.push({path:'/comingsoon'})
    },
  ]},
 {name:'Series & Progressions', lock:true, img:"/assets/series.svg", data:[
   {
     title: "Arithmetic Progressions (AP)",
     description: ['Introduction','Euclid\'s Lemma','Fundamental Theorem of Arithmatic','Irrational Numbers', 'Decimal Exapansions','. . .'],
     flex:12,
     icon: 'fas fa-lock',
     action: () => router.push({path: '/comingsoon'})
   },
   {
     title: "Nth Terms of an AP",
     description: ['Introduction','Nth Term of an AP', 'Sum of first N terms of an AP','. . .'],
     flex:6,
     icon: 'fas fa-lock',
     action: () => router.push({path: '/comingsoon'})
   },
   {
     title: "Sum of an Arithmetic Progression",
     description: ['Introduction','Nth Term of an AP', 'Sum of first N terms of an AP','. . .'],
     flex:6,
     icon: 'fas fa-lock',
     action: () => router.push({path: '/comingsoon'})
   },
   {
     title: "Geometric Progressions (GP)",
     description: ['Introduction','Euclid\'s Lemma','Fundamental Theorem of Arithmatic','Irrational Numbers', 'Decimal Exapansions','. . .'],
     flex:12,
     icon: 'fas fa-lock',
     action: () => router.push({path: '/comingsoon'})
   },
 ]},
 {name:'Continuity & Differentiability', lock:true, img:"/assets/functions.svg",  data:[
   {
     title: "Mean Value Theorem (Basics)",
     description: ['Formula for Mean of a Grouped Data', 'Examples', '. . .'],
     flex:6,
     icon: 'fas fa-lock',
     action: () => router.push({path: '/mathematics12th/mvt'})
   },
   {
     title: "Mean Value Theorem (Quiz)",
     description: ['Formula for Median a Grouped Data', 'Examples', '. . .'],
     flex:6,
     icon: 'fas fa-lock',
     action: () => router.push({path: '/mathematics12th/quizMVT'})
   },
   {
     title: "Mean Value Theorem (Quiz)",
     description: ['Formula for Mode of a Grouped Data', 'Examples', '. . .'],
     flex:12,
     icon: 'fas fa-lock',
     action: () => router.push({path: '/mathematics12th/quizMVT'})
   },
 ]},
  {name:'Data & Statistics', lock:true, img:"/assets/graph-paper.svg",  data:[
    {
      title: "Mean of Grouped Data",
      description: ['Formula for Mean of a Grouped Data', 'Examples', '. . .'],
      flex:6,
      icon: 'fas fa-lock',
      action: () => router.push({path: '/comingsoon'})
    },
    {
      title: "Median of Grouped Data",
      description: ['Formula for Median a Grouped Data', 'Examples', '. . .'],
      flex:6,
      icon: 'fas fa-lock',
      action: () => router.push({path: '/comingsoon'})
    },
    {
      title: "Mode of Grouped Data",
      description: ['Formula for Mode of a Grouped Data', 'Examples', '. . .'],
      flex:12,
      icon: 'fas fa-lock',
      action: () => router.push({path: '/comingsoon'})
    },
  ]},
  {name:'Vectors', lock:true, img:"/assets/vectors.svg", data:[
    {
      title: "Introduction to Vectors",
      description: ['Event & Outcomes','Probability of an Event','Sum of Probabilities','. . .'],
      flex:12,
      icon: 'fas fa-lock',
      action: () => router.push({path: '/mathematics/vectors'})
    },
    {
      title: "Components of a Vector",
      description: ['Event & Outcomes','Probability of an Event','Sum of Probabilities','. . .'],
      flex:6,
      icon: 'fas fa-lock',
      action: () => router.push({path: '/mathematics/vectors/cartesian'})
    },
  {
    title: "Sum of Vectors",
    description: ['Event & Outcomes','Probability of an Event','Sum of Probabilities','. . .'],
    flex:6,
    icon: 'fas fa-lock',
    action: () => router.push({path: '/mathematics/vectorSum'})
  },
  {
    title: "Dot Product of Two Vectors",
    description: ['Event & Outcomes','Probability of an Event','Sum of Probabilities','. . .'],
    flex:6,
    icon: 'fas fa-lock',
    action: () => router.push({path: '/mathematics/vectors/inner'})
  },
  {
    title: "Cross Product of Two Vectors",
    description: ['Event & Outcomes','Probability of an Event','Sum of Probabilities','. . .'],
    flex:6,
    icon: 'fas fa-lock',
    action: () => router.push({path: '/mathematics/vectors/outer'})
  },
]}
  ]
export default Math12th;
////////////////////////////END OF FILE /////////////////////////////////////////
