const Prog =[
  [
    {
      title: "Data and Variables",
      description: "Data Types | Variable Assignments | Local & Global Variables | ...",
      isBlack: true,
      icon: 'fas fa-lock',
      action: () => router.push({path: ''})
    },
    {
      title: "Array",
      description: "Array Definitions | Creating Arrays | Operating on Arrays | ... ",
      icon: 'fas fa-lock',
      action: () => router.push({path: ''})
    },
    {
      title: "Functions",
      description: " Defining a Function | Calling a Function | Returning a Value | ...",
      isBlack: true,
      icon: 'fas fa-lock',
      action: () => router.push({path: ''})
    },
  ],
    [
      {
        title: "If Else Statement",
        description: "Conditional Statements | If Else Statement | Else If Statement | ...  ",
        isBlack: false,
        icon: 'fas fa-lock',
        action: () => router.push({path: ''})
      },
      {
        title: "Do While Statement",
        description: "While Loop | Do While Loops | Examples | ...",
        icon: 'fas fa-lock',
        isBlack: true,
        action: () => router.push({path: ''})
      },
      {
        title: "For Loops",
        description: "Iterative Statements| For Loops | Cascaded For Loops| ...",
        icon: 'fas fa-lock',
        isBlack: false,
        action: () => router.push({path: ''})
      },
    ],
    [
      {
        title: "Boolean Operations",
        description: " Boolean Values | Boolean Functions | Conditions and Comparisons |...",
        icon: 'fas fa-lock',
        isBlack: true,
        action: () => router.push({path: ''})
      },
      {
        title: "Operations",
        description: "Arithmatic Operations | String Operations | Logical Operations | ... ",
        isBlack: false,
        icon: 'fas fa-lock',
        action: () => router.push({path: ''})
      },
      {
        title: "Switch & Break Statements",
        icon: 'fas fa-lock',
        isBlack: true,
        description: " Switch Statements| Break & Continue Statements| ...",
        action: () => router.push({path: ''})
      },
    ]
]
export default Prog;
////////////////////////////END OF FILE /////////////////////////////////////////
