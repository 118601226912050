////////////////////////////8TH GRADE MATH TOPICS////////////////////////////////
const Math8th = [
  {
    path: '/mathematics/squareRoot',
    name: 'Square Root',
    component: () => import('../squareRoot/Lesson.vue')
  },
  {
    path: '/mathematics/percentage1',
    name: 'Percentage#1',
    component: () => import('../percentage/Lesson.vue')
  },
  {
    path: '/mathematics/percentage2',
    name: 'Percentage#2',
    component: () => import('../percentage2/Lesson.vue')
  },
  {
    path: '/mathematics/perimeter2',
    name: 'Perimeter#2',
        component: () => import('../Math6thGrade/demoPerimeter/Lesson.vue')
  },
  {
    path: '/mathematics/area',
    name: 'Area',
    component: () => import('../Math7thGrade/area/Lesson.vue')
  },
  {
  path: '/mathematics8th/quizParallel',
  name: 'Quiz Parallel Lines',
  component: () => import('../Math8thGrade/quizParallel/Lesson.vue')
  },
  {
  path: '/mathematics8th/demoParallel',
  name: 'Parallel Lines',
  component: () => import('../Math8thGrade/demoParallel/Lesson.vue')
  },
  {
  path: '/mathematics8th/demoAngles',
  name: 'Parallel Angles',
  component: () => import('../Math8thGrade/demoAngles/Lesson.vue')
  },
  {
  path: '/mathematics8th/demoSLE',
  name: 'System of Linear Equations',
  component: () => import('../Math8thGrade/demoSLE/Lesson.vue')
  },
  {
  path: '/mathematics8th/quizSLE',
  name: 'System of Linear Equations',
  component: () => import('../Math8thGrade/quizSLE/Lesson.vue')
  },
  {
  path: '/mathematics8th/demoQuadAngle',
  name: 'Angle Sum Theorem',
  component: () => import('./demoQuadAngle/Lesson.vue')
  },
  {
  path: '/mathematics8th/quizQuadExt',
  name: 'Exterior Angle Theorem',
  component: () => import('./quizQuadExt/Lesson.vue')
  },
    ]
export default Math8th;
////////////////////////////END OF FILE /////////////////////////////////////////
