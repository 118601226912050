////////////////////////////9TH GRADE MATH TOPICS////////////////////////////////
const Math9th = [
  {
    path: '/mathematics/9th/fraction',
    name: 'fraction3',
  //  beforeEnter: conditionalNext,
    component: () => import('../fractions/Lesson.vue')
  },
  {
    path: '/mathematics/9th/triangles',
    name: 'Triangle and Its Properties (9th)',
    beforeEnter: conditionalNext,
    component: () => import('../triangles/SubtopicMenu.vue')
  },
  {
    path: '/mathematics/parallel',
    name: 'Parallel Lines',
    //beforeEnter: conditionalNext,
    component: () => import('../parallel/Lesson.vue')
  },
  {
      path: '/mathematics9th/coord',
      name: 'coord',
      component: () => import('../Math9thGrade/coord/SubtopicMenu.vue')
  },
  {
      path: '/mathematics9th/coord/lesson-1',
      name: 'LearnToFindCoord',
      //beforeEnter: conditionalNext,
      component: () => import('../Math9thGrade/coord/Lesson-1/Lesson.vue')
  },
  {
    path: '/mathematics9th/coord/lesson-2',
    name: 'Find Coordinates',
    //beforeEnter: conditionalNext,
    component: () => import('../Math9thGrade/coord/Lesson-2/Lesson.vue')
  },
  {
    path: '/mathematics9th/coord/lesson-3',
    name: 'Plot a Point',
    //beforeEnter: conditionalNext,
    component: () => import('../Math9thGrade/coord/Lesson-3/Lesson.vue')
  },
  {
    path: '/mathematics9th/point',
    name: 'Plot a Point (9th)',
    component: () => import('../Math10thGrade/points/Lesson.vue')
  },
  {
    path: '/mathematics9th/findCoord',
    name: 'Find Coordinates',
    //beforeEnter: conditionalNext,
    component: () => import('../Math9thGrade/findCoordinates/Lesson.vue')
  },
  ////////////////////////////////////////////
  {
      path: '/mathematics9th/triangleArea',
      name: 'triangleArea',
      component: () => import('../Math9thGrade/demoTriangleArea/Lesson.vue')
  },
  {
  path: '/mathematics9th/demoSLE',
  name: 'System of Linear Equations',
  component: () => import('../Math8thGrade/demoSLE/Lesson.vue')
  },
  {
  path: '/mathematics9th/quizSLE',
  name: 'System of Linear Equations',
  component: () => import('../Math8thGrade/quizSLE/Lesson.vue')
  },

  {
    path: '/mathematics9th/geometric',
    name: 'Plot a Point (9th)',
    component: () => import('../Math9thGrade/geometric/Lesson.vue')
  },
  {
    path: '/mathematics9th/angles',
    name: 'Angles',
    component: () => import('../Math9thGrade/angles/Lesson.vue')
  },
  {
    path: '/mathematics9th/quizQuads',
    name: 'Quads',
    component: () => import('../Math9thGrade/quizQuads/Lesson.vue')
  },
  {
    path: '/mathematics9th/demoCongruence',
    name: 'Congruent Triangles',
    component: () => import('../Math9thGrade/demoCongruence/Lesson.vue')
  },
  {
    path: '/mathematics9th/quizExponents',
    name: 'Quiz on Exponents',
    component: () => import('../Math9thGrade/quizExponents/Lesson.vue')
  },
  {
    path: '/mathematics9th/demoExponents',
    name: 'Demo Exponents',
    component: () => import('../Math9thGrade/demoExponent/Lesson.vue')
  },
  {
    path: '/mathematics9th/demoTransversal',
    name: 'Demo Transversal Angles',
    component: () => import('../Math9thGrade/demoAngles/Lesson.vue')
  },
  {
    path: '/mathematics9th/quizTransversal',
    name: 'Demo Transversal Angles',
    component: () => import('../Math9thGrade/quizAngles/Lesson.vue')
  },
  {
    path: '/mathematics9th/quizProbability',
    name: 'Quiz Probability of an Event',
    component: () => import('../Math9thGrade/quizProbability/Lesson.vue')
  },
  {
    path: '/mathematics9th/demoProb',
    name: 'Quiz Probability of an Event',
    component: () => import('../Math9thGrade/demoProb/Lesson.vue')
  },
  {
    path: '/mathematics9th/prob',
    name: 'Find Probability (9th)',
    component: () => import('../Math12thGrade/Prob/Lesson.vue')
  },
  {
    path: '/mathematics9th/demoMean',
    name: 'Demo on Mean',
    component: () => import('./demoMean/Lesson.vue')
  },
  {
    path: '/mathematics9th/quizMean',
    name: 'Quiz on Mean',
    component: () => import('../Math6thGrade/quizMean/Lesson.vue')
  },
  {
    path: '/mathematics9th/practiceMean',
    name: 'Practice on Mean',
    component: () => import('../arithmeticMean/Lesson.vue')
  },
  {
    path: '/mathematics9th/demoMedian',
    name: 'Finding Median of a Set of Numbers',
    component: () => import('./demoMedian/Lesson.vue')
  },
  {
    path: '/mathematics9th/demoSurface',
    name: 'Demo on Surface Area',
    component: () => import('./demoSurfaceArea/Lesson.vue')
  },

  {
  path: '/camMotion',
  name: 'Cam Motion',
  component: () => import('../Math9thGrade/camMotion/Lesson.vue')
  }
];
function conditionalNext(to, from, next) {
          if (localStorage.getItem('token'))
          {
              next();
          }
          else
          {
            next("/")
          }
  }
export default Math9th;
////////////////////////////END OF FILE /////////////////////////////////////////
