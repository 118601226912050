<template>
  <div v-if="status">
    <v-card
      justify="center"
      style="border-radius:7.5px;"
    >
      <div class="pa-2">
        <v-btn
          color="pink"
          dark
          outline
          absolute
          fab
          @click="closeModal"
        >
          <v-icon>fas fa-close</v-icon>
        </v-btn>
      </div>
      <v-img width="auto "
             :src="img"
             contain
      />
      <div class="text-h1 txt-center">
        Sign in to start your learning journey.
      </div>
      <v-layout justify-center class="mx-2 pb-3">
        <v-flex lg12
                md12
                sm12
                xl12
                xs12
                class="px-2"
        >
          <v-btn large
                 block
                 color="error"
                 class="white--text pa-1"
                 @click="socialLogin('google')"
          >
            <div>
              <h2 style="color:white;">
                Get Started
              </h2>
            </div>
          </v-btn>
        </v-flex>
      </v-layout>
      <div class="text-caption txt-center">
        By signing in, you agree to our <a href="/TermsofService">Terms of Service</a> and
        <a href="/PrivacyPolicy">Privacy Policy</a> .
      </div>
    </v-card>
  </div>
</template>
<script>
  import axios from 'axios';
  export default {
    name: "LoginDialog",
    props: {
      status: {
        type: Boolean,
        required: true
      },
      img: {
        type: String,
        default: '/assets/Learning.svg',
        required: false
      },
    },
    data() {
      return {
      };
    },
    methods: {
      closeModal() {
        this.$emit("close");
      },
      async socialLogin(params) {
        const providers = {
          "google":   { "authPath": 'auth/redirect/google', },
          "facebook": { "authPath": 'auth/redirect/facebook', },
        };

        if (this.$route.query.grade !== undefined &&
            this.$route.query.grade &&
            localStorage.getItem('footerPopup')) {
          localStorage.setItem('redirect', this.$route.query.grade);
        }

        if (!(params in providers)) {
          console.error("Unknown social provider " + params + " passed.");
        } else {
          let displayError = true;
          try {
            const url = process.env.VUE_APP_API+providers[params].authPath;
            const response = await axios.get(url);
            if (response.data.success) {
              displayError = false;
              window.location = response.data.data.provider_redirect;
            }
          } catch (error) {
            console.error(error);
          }

          if (displayError) {
            this.$notify({
              group: 'notify',
              type: 'error',
              title: 'Error!!',
              text: 'Something went wrong!!'
            });
          }
        }
      },
    },
  };
</script>
