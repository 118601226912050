////////////////////////////6TH GRADE MATH TOPICS////////////////////////////////
const Math6th = [
      {
        path: '/mathematics6th/data',
        name: 'data',
        component: () => import('../data/SubtopicMenu.vue')
      },
      {
        path: '/mathematics/bar',
        name: 'bar',
        component: () => import('../data/BarChart/Lesson.vue')
      },
      {
        path: '/mathematics/fractions',
        name: 'fractions1',
        component: () => import('../fractions/Lesson.vue')
      },
      {
        path: '/mathematics/fractions2',
        name: 'fractions2',
        component: () => import('../fractions2/Lesson.vue')
      },
      {
        path: '/mathematics/integer',
        name: 'integer',
        component: () => import('../integer/Lesson.vue')
      },
      {
        path: '/mathematics/decimal',
        name: 'decimal',
        component: () => import('../decimal/Lesson.vue')
      },
      {
        path: '/mathematics/decimal2',
        name: 'decimal2',
        component: () => import('../decimal2/Lesson.vue')
      },
      {
        path: '/mathematics/decimal3',
        name: 'decimal3',
        component: () => import('../decimal3/Lesson.vue')
      },
      {
        path: '/mathematics/expression',
        name: 'expression1',
        component: () => import('../Math6thGrade/expression/Lesson.vue')
      },
      {
        path: '/mathematics/expression2',
        name: 'expression2',
        component: () => import('../Math6thGrade/expression2/Lesson.vue')
      },
      {
        path: '/mathematics/equations',
        name: 'Linear Equation in One Variable 6th',
        component: () => import('../lineq/Lesson.vue')
        },
///////////////////////////////////////////////////////////
      {
        path: '/mathematics/toolset',
        name: 'Tools & Apparatus',
        component: () => import('../Math6thGrade/toolSet/Lesson.vue')
      },
      {
        path: '/mathematics/ratios',
        name: 'Ratios',
        component: () => import('../ratios/Lesson.vue')
      },
      {
        path: '/mathematics/proportions',
        name: 'Proportions',
        component: () => import('../proportions/Lesson.vue')
      },
      {
        path: '/mathematics/drawLine',
        name: 'Draw Line',
        component: () => import('../drawLine/Lesson.vue')
      },
      {
        path: '/mathematics/drawTriangle',
        name: 'Draw Triangle',
        component: () => import('../drawTriangle/Lesson.vue')
      },
      {
        path: '/mathematics/drawCircle',
        name: 'Draw Circle',
        component: () => import('../drawCircle/Lesson.vue')
      },
      {
        path: '/mathematics/statistics',
        name: 'statistics',
        component: () => import('../statistics/Lesson.vue')
      },
      {
      path: '/mathematics/symmetryLine',
      name: 'Symmetry Line',
      component: () => import('../symmetryLine/Lesson.vue')
      },
      {
      path: '/mathematics6th/demoParallelogram',
      name: 'Parallelogram',
      component: () => import('../Math6thGrade/demoParallelogram/Lesson.vue')
      },
      {
      path: '/mathematics6th/quizParallelogram',
      name: 'Parallelogram - Quiz 1',
      component: () => import('../Math6thGrade/quizParallelogram/Lesson.vue')
      },
      {
      path: '/mathematics6th/quizParallelogram2',
      name: 'Parallelogram - Quiz 2',
      component: () => import('../Math6thGrade/quizParallelogram2/Lesson.vue')
      },
      {
      path: '/mathematics6th/demoTriangle',
      name: 'Triangle - Demo',
      component: () => import('../Math6thGrade/demoTriangle/Lesson.vue')
      },
      {
      path: '/mathematics6th/practiceTriangle',
      name: 'Triangle - Practice',
      component: () => import('../Math6thGrade/quizTriangle/Lesson.vue')
      },
      {
      path: '/mathematics6th/quizTriangle',
      name: 'Triangle - Practice',
      component: () => import('../Math6thGrade/quizTriangle2/Lesson.vue')
      },
      {
      path: '/mathematics6th/demoMean',
      name: 'Mean',
      component: () => import('../Math6thGrade/demoMean/Lesson.vue')
      },
      {
      path: '/mathematics6th/quizMean',
      name: 'Mean',
      component: () => import('../Math6thGrade/quizMean/Lesson.vue')
      },
      ///////////////////////////////////////////////////////
      {
      path: '/mathematics6th/demoPerp',
      name: 'Perp',
      component: () => import('../Math6thGrade/demoPerp/Lesson.vue')
      },
      {
      path: '/mathematics6th/demoPerpBisector',
      name: 'Perp Bisector',
      component: () => import('../Math6thGrade/demoPerpBisector/Lesson.vue')
      },
      {
      path: '/mathematics6th/demoAngleBisector',
      name: 'Angle Bisector',
      component: () => import('../Math6thGrade/demoAngleBisector/Lesson.vue')
      },
////////////////////////////////////////////////////////////////////////////////////
      {
      path: '/mathematics6th/quizRatio',
      name: 'quizRatio',
      component: () => import('../Math6thGrade/quizRatio/Lesson.vue')
      },
      {
      path: '/mathematics6th/demoSquare',
      name: 'demoSquare',
      component: () => import('../Math6thGrade/demoSquare/Lesson.vue')
      },
      {
      path: '/mathematics6th/demoDiff',
      name: 'demoDiff',
      component: () => import('../Math6thGrade/demoDiff/Lesson.vue')
      },
      {
      path: '/mathematics6th/quizProportion',
      name: 'quizProportion',
      component: () => import('../Math6thGrade/quizProportion/Lesson.vue')
      },
      {
      path: '/mathematics6th/quizInteger',
      name: 'quizInteger',
      component: () => import('../Math6thGrade/quizInteger/Lesson.vue')
      },
      {
      path: '/mathematics6th/quizSubtraction',
      name: 'quizSubtraction',
      component: () => import('../Math6thGrade/quizSubtraction/Lesson.vue')
      },
      {
        path: '/mathematics6th/perimeter',
        name: 'perimeter1',
        component: () => import('../Math6thGrade/demoPerimeter/Lesson.vue')
      },
      {
        path: '/mathematics6th/perimeter2',
        name: 'perimeter2',
        component: () => import('../Math6thGrade/demoPerimeter2/Lesson.vue')
      },
      {
        path: '/mathematics6th/quizPerimeter',
        name: 'quizPerimeter',
        component: () => import('../Math6thGrade/quizPerimeter/Lesson.vue')
      },
      {
        path: '/mathematics6th/quizSymmetry',
        name: 'quizSymmetry',
        component: () => import('../Math6thGrade/quizSymmetry/Lesson.vue')
      },
       {
         path: '/mathematics6th/quizSymmetryLine',
         name: 'quizSymmetryLine',
         component: () => import('../Math6thGrade/quizSymmetryLine/Lesson.vue')
        },
        {
          path: '/mathematics6th/symmetry',
          name: 'Symmetry',
          component: () => import('../symmetry/Lesson.vue')
        },
        {
          path: '/mathematics6th/symmetrydraw',
          name: 'Draw Symmetric Shapes',
          component: () => import('../drawObject/Lesson.vue')
        },
        {
          path: '/mathematics6th/geometric',
          name: 'Geometric Construction',
          component: () => import('../Math6thGrade/demoGeometric/Lesson.vue')
        },
        {
          path: '/mathematics6th/demoAngles',
          name: 'Types of angles',
          component: () => import('../Math6thGrade/demoAngles/Lesson.vue')
        },
    ];
export default Math6th;
////////////////////////////END OF FILE /////////////////////////////////////////
