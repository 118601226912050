const footerItems = [
  {
    path: '/team',
    name: 'team',
    component: () => import('./views/Team/Team.vue'),
  },
  {
    path: '/finder',
    name: 'finder',
    component: () => import('./views/Finder/Finder.vue'),
  },
  // The "contact us" link exists in the menu items, so do not duplicate
  // the route here.
  {
    path: '/ip',
    name: 'ip',
    component: () => import('./views/IP/IP.vue'),
  },
  // The privacy policy also exists in the menu items.
];

export default footerItems;
