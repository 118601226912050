import router from '../router'
const Chem9th =[
  ///////////////////////////////////////////////////////////////////////////////////////
      {name:'Chemical Kinetics', lock:false,  img:"/assets/Cartesians.svg", data:[
      {
        title: "Chemical Equations",
        description: "Definition of a Chemical Equation | Reactants and Products | Law of Conservation of Mass | Balanced versus unbalanced Equation | ...",
        isBlack: true,
        icon: 'fas fa-lock-open',
        action: () => router.push({path: '/chemistry/bce'})
      },
      {
        title: "Chemical Bonding",
        icon: 'fas fa-lock-open',
        description: "Ionic and Covalent Bonds | Hybridization | Molecular Orbital Theory | Valence Bond Theory | ...",
        action: () => router.push({path: '/chemistry/ionicbond'})
      },
    ]},
  ///////////////////////////////////////////////////////////////////////////////////
    {name:'Atomic Structure', lock:true, img:"/assets/calc.svg", data:[
      {
    title: "Structure of Atom",
        description: "Bohr's Model of Atom | Sub-atomic Particles | Quantum Mechanical Model of Atom | ...",
        icon: 'fas fa-lock',
        action: () => router.push({path: ''})
      },
      {
        title: "Electronic Configuration of Elements",
        description: "Classification of Elements | Electronegativity | ...",
        isBlack: true,
        icon: 'fas fa-lock',
        action: () => router.push({path: '/chemistry/electronic'})
      }
    ]},
  ////////////////////////////////////////////////////////////////////////////////////////////////////////
   {name:'Thermodynamics', lock:true, img:"/assets/numbers.svg", data:[
      {
        title: "Thermodynamics",
        icon: 'fas fa-lock',
        description: "Laws of Thermodynamics | Enthalpy | Entropy | Gibbs Free Energy | ...",
        isBlack: true,
        action: () => router.push({path: ''})
      },
      {
        title: "Theory of Equilibrium",
        icon: 'fas fa-lock',
        description: "Law of Chemical Equilibrium | Le Chatelier’s principle | Law of Mass Action | ...",
        action: () => router.push({path: ''})
      },
      {
        title: "Thermal Energy",
        icon: 'fas fa-lock',
        description: "Kinetic Energy and Molecular Speeds | Temperature | Kinetic Theory of Gases | ...",
        isBlack: true,
        action: () => router.push({path: ''})
      },
      {
        title: "Gas Laws",
        icon: 'fas fa-lock',
        description: "Gaseous State | Boyle's Law | Dalton's Law | Ideal Gas Equation | ... ",
        action: () => router.push({path: '/chemistry/boyle'})
      }
    ]},
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////
     {name:'Organic Chemistry', lock:true, img:"/assets/numbers.svg", data:[
      {
        title: "Hydrocarbons",
        icon: 'fas fa-lock',
        description: "Alkanes, Alkenes and Alkynes | IUPAC Nomenclature of Hydrocarbons | Properties of Hydrocarbons | ...",
        isBlack: true,
        action: () => router.push({path: ''})
      },
      {
        title: "Organic Molecules: Alkanes",
        description: "Alkane | Molecular Structure | Formula | Examples | ...",
        isBlack: true,
        icon: 'fas fa-lock',
        action: () => router.push({path: ''})
      },
      {
        title: "Organic Molecules: Alkenes",
        description: "Alkenes & Alkynes | Molecular Structure | Formula | Examples | ...",
        isBlack: false,
        icon: 'fas fa-lock',
        action: () => router.push({path: ''})
      },
    ]}
  /////////////////////////////////////////////////////////////////////////////////////////////////
]
export default Chem9th;
////////////////////////////END OF FILE /////////////////////////////////////////
