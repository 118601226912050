////////////////////////////10TH GRADE MATH TOPICS////////////////////////////////
const Math10th = [
  {
    path: '/mathematics/tangents',
        name: 'tangents',
        component: () => import('../tangentsToCircle/Lesson.vue')
  },
  {
    path: '/mathematics/tangentNum',
    name: 'Tangent to a Circle',
    component: () => import('../tangent2/Lesson.vue')
  },
  {
    path: '/mathematics10th/demoTangent',
    name: 'Tangent Length',
    component: () => import('../Math10thGrade/demoTangent/Lesson.vue')
  },
  {
    path: '/mathematics/iat',
    name: 'Inscribed Angle Theorem',
    component: () => import('../Math10thGrade/demoIat/Lesson.vue')
  },
  {
    path: '/mathematics/tt',
    name: 'Thales Theorem',
    component: () => import('../Math10thGrade/tt/Lesson.vue')
  },
	{
      path: '/mathematics/triangles/types',
      name: 'Types of Triangles',
      component: () => import('../triangles/types/Lesson.vue')
    },
    {
      path: '/mathematics/triangles/features',
      name: 'Features of Triangles',
      component: () => import('../triangles/features/Lesson.vue')
    },
    {
      path: '/mathematics/triangles/pythagorean',
      name: 'Pythagorean Theorem (10th)',
      component: () => import('../Math7thGrade/pyt/Lesson.vue')
    },
	  {
      path: '/mathematics/triangles/sum',
      name: 'Triangle Sum Theorem',
      component: () => import('../intang/Lesson.vue')
    },
    {
      path: '/mathematics10th/distance',
      name: 'Distance between Two Points',
      component: () => import('../Math10thGrade/distance/Lesson.vue')
    },
    {
      path: '/mathematics10th/findDistance',
      name: 'Distance between Two Points 2',
      component: () => import('../Math10thGrade/distance-2/Lesson.vue')
    },
    {
      path: '/mathematics10th/point',
      name: 'Plot a Point (10th)',
      component: () => import('../Math10thGrade/points/Lesson.vue')
    },
    {
        path: '/mathematics10th/quizThales',
        name: 'quizThales',
        component: () => import('../Math10thGrade/quizThales/Lesson.vue')
    },
    {
        path: '/mathematics10th/quizTriangle',
        name: 'quizTriangle',
        component: () => import('../Math10thGrade/quizTriangle/Lesson.vue')
    },
    {
        path: '/mathematics10th/quizTrig',
        name: 'quizTrigonometry',
        component: () => import('../Math10thGrade/quizTrig/Lesson.vue')
    },
    {
        path: '/mathematics10th/demoTrig',
        name: 'ratioTrigonometry',
        component: () => import('../Math10thGrade/demoTrig/Lesson.vue')
    },
    {
        path: '/mathematics10th/demoThales',
        name: 'thalesTheorem',
        component: () => import('../Math10thGrade/demoThales/Lesson.vue')
    },
    {
        path: '/mathematics10th/demoIAT',
        name: 'iatTheorem',
        component: () => import('../Math10thGrade/demoIat/Lesson.vue')
    },
    {
        path: '/mathematics10th/demoQE',
        name: 'Quadratic Equation',
        component: () => import('../Math10thGrade/demoQE/Lesson.vue')
    },
    {
        path: '/mathematics10th/demoSol',
        name: 'Discriminant and Number of Solutions',
        component: () => import('../Math10thGrade/demoSol/Lesson.vue')
    },
    {
        path: '/mathematics10th/quizQE',
        name: 'Quiz on Quadratic Equation',
        component: () => import('../Math10thGrade/quizQE/Lesson.vue')
    },
    {
        path: '/mathematics10th/demoIden',
        name: 'Trigonometric Identities',
        component: () => import('../Math10thGrade/demoIden/Lesson.vue')
    },
    ]
export default Math10th;
////////////////////////////END OF FILE /////////////////////////////////////////
