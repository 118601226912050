<template>
  <v-app>
    <!--  <Menu
      :tool-bar-items="toolBarItems"
      :tool-bar-buttons="footerButtons"
    /> -->
    <Menu />
    <vue-confirm-dialog />
    <notifications
      position="top center"
      width="400px"
      style="fontSize:15px;justify:center"
      group="notify"
    />

    <!--
/////////////////////////////////PopUpWindow////////////////////////////
  <v-layout
      v-if="popUp"
      justify-center
      align-center
      style="opacity:0.85; position:fixed; top:0;bottom:0;left:0;right:0;z-index:100;"
    >
      <LoginDialog :status="popUp" @close="closeModal" />
    </v-layout>
      ///////////////////////////////////EndPopUpWindow////////////////////////////
  -->
    <v-container fluid>
      <v-content style="padding-top:0px">
        <router-view :key="$route.fullPath" />
        <!--<router-view></router-view> -->
        <v-layout
          align-center
          justify-center
          class="mx-auto pa-auto"
        >
          <v-progress-circular
            id="sine"
            indeterminate
            :size="150"
            :width="10"
            color="red lighten-2"
          >
            <span class="footer__dliy font-weight-bold">
              <span class="footer__etsy">&epsilon;</span>dliy</span>
          </v-progress-circular>
        </v-layout>
      </v-content>
    </v-container>
    <v-footer dark height="auto" fluid>
      <v-layout
        align-center
        justify-center
        row
        wrap
      >
        <v-card class="flex primary footer__card">
          <v-layout
            align-center
            justify-center
            row
          >
            <span class="mt-3 footer__copyright">
              &copy; 2018-2023 | <span class="edliy-e">&epsilon;</span>dliy.com | All Rights Reserved.
            </span>
          </v-layout>
          <v-card-title class="footer__title">
            <v-layout align-center justify-center>
              <span class="footer__dliy">
                <span class="footer__etsy">&epsilon;</span>dliy</span>
            </v-layout>
          </v-card-title>
          <v-layout
            align-center
            justify-center
            row
            class="px-auto pb-2"
          >
            <v-btn
              v-for="b in footerButtons"
              :key="b.title"
              depressed
              class="footer__buttons mx-0"
              flat
              @click="b.action"
            >
              {{ b.title }}
            </v-btn>
          </v-layout>
          <v-layout
            align-center
            justify-center
            row
          >
            <v-btn v-for="b in socialMediaButtons"
                   :key="b.title"
                   icon
                   :href="b.link"
                   depressed
            >
              <v-icon>{{ b.icon }}</v-icon>
            </v-btn>
          </v-layout>
        </v-card>
      </v-layout>
    </v-footer>
    <v-spacer />
  </v-app>
</template>
<script>
import Menu from './views/Menu'
import { mapGetters } from 'vuex'
// LoginDialog from './views/SocialLoginDialog.vue';
export default {
  name: 'App',
  metaInfo:{
  title:"Edliy",
  titleTemplate:"%s | Learn interactively",
  meta: [
    {vmid:"chars", charset:"utf-8"},
    {vmid:"viewport",
     name:"viewport",
     content:"width=device-width, initial-scale=1"},
    {vmid:"description",
     name: "description",
     content: "At Edliy, we are pioneering visually interactive technology to teach science and math concepts through visual storytelling." },
    {vmid:"keywords",
     name: "keywords",
     content: "visual learning, interactive learning, concept building for students, science, engineering, mathematics"}
  ]},
  components: {
    Menu
  },
  data () {
    return {
      popUp:false,
      open: null,
      toolBarItems: [
        {title:'info', icon: '', action: () => this.$router.push({path: '/contact'})},
        {title: 'topics', icon: '', action: () => this.$router.push({path: '/finder'})}
      ],
      socialMediaButtons: [
        {title: 'facebook', icon: 'fab fa-facebook-square', link:'https://www.facebook.com/edliyInteractive'},
        {title: 'instagram', icon: 'fab fa-instagram', link:'https://www.instagram.com/edliy.interactive'},
        {title: 'Youtube', icon: 'fab fa-youtube', link:'https://www.youtube.com/channel/UCpEgkcSulX3pKW4sgEkD-LA/featured'},
        {title: 'linkedin', icon: 'fab fa-linkedin', link:'https://www.linkedin.com/company/edliy'},
      ],
      footerButtons: [
        {title: 'Our Team', action: () => this.$router.push({path: '/team'})},
        {title: 'Topic Finder', action: () => this.$router.push({path: '/finder'})},
        {title: 'Contact us', action: () => this.$router.push({path: '/contact'})},
        {title: 'Intellectual Property ', action: () => this.$router.push({path: '/IP'})},
      ]
    }
  },
  mounted(){
    //this.popUpTrigger();
  },
  methods:{
  /*  popUpTrigger()
    {
      if(!localStorage.getItem('token'))
      {
      setTimeout(()=>{this.popUp=true;}, 5000);
      }
      else
      {
        this.popUp=false;
      }
    },
    closeModal(){
      this.popUp=false;
      console.log(this.popUp);
    },*/
    toggle(){
      this.open = !this.open;
      console.log(this.open);
    }
  },

/*  beforeDestroy () {
      clearInterval(this.interval)
    },
  mounted () {
      this.interval = setInterval(() => {
        if (this.value == 100) {
          this.overlay=false
        }
        //  return (this.value = 0)
        //}
        this.value += 5
      }, 30)
    }*/
}
</script>
<style lang="scss">
@import 'src/styles/subtopic-menu.scss';
@import 'src/styles/edliy-box-about.scss';
@import 'src/styles/screen-sizes.scss';
h1,h2 {
 font-family: 'Roboto';
//  font-weight: 700 Bold !important;
 }
h4,h5,h6 {
  font-family: "Roboto"; 
  font-weight: 500 Bold !important;
}
h5 {
  font-family: "Roboto" !important;
  color: var(--v-red-base);
  font-weight: 300 !important;
  line-height: 32px !important;
  letter-spacing: normal !important;
  @include respond-to(less-smallest-screens) {font-size: 13px;}
  @include respond-to(smallest-screens) {font-size: 13px;}
  @include respond-to(small-screens) {font-size: 16px;}
  @include respond-to(medium-screens) {font-size: 16px;}
  @include respond-to(large-screens) {font-size: 20px;}
  @include respond-to(largest-screens) {font-size: 24px;}
  }

  .v-progress-circular {
  @include respond-to(less-smallest-screens) {size:150;}
  @include respond-to(smallest-screens) {size:150;}
  @include respond-to(small-screens) {size:200;}
  @include respond-to(medium-screens) {size:100;}
  @include respond-to(large-screens) {size:100;}
  @include respond-to(largest-screens) {size:100;}}

h3 {
  font-family: "Roboto" !important;
  //color: var(--v-red-base);
  font-weight: 500 !important;
  line-height: 32px !important;
  letter-spacing: normal !important;
  @include respond-to(less-smallest-screens) {font-size: 16px;}
  @include respond-to(smallest-screens) {font-size: 16px;}
  @include respond-to(small-screens) {font-size: 20px;}
  @include respond-to(medium-screens) {font-size: 20px;}
  @include respond-to(large-screens) {font-size: 24px;}
  @include respond-to(largest-screens) {font-size: 28px;}
}

h6 {
  font-family: "Roboto" !important;
  color: var(--v-red-base);
  font-weight: 400 !important;
  font-size: 23px !important;
  line-height: 32px !important;
  letter-spacing: normal !important;}

.application {
  font-family: "Roboto"
}

p {
  font-family: "Roboto" !important;
  font-weight : 300 !important;
}

body {
  @include respond-to(less-smallest-screens) {font-size: 12px;}
  @include respond-to(smallest-screens) {font-size: 14px;}
  @include respond-to(small-screens) {font-size: 16px;}
  @include respond-to(medium-screens) {font-size: 18px;}
  @include respond-to(large-screens) {font-size: 20px;}
  @include respond-to(largest-screens) {font-size: 22px;}
  font-family: "Lato"} 

ul li {
  font-family: "Roboto" !important;
  font-weight : 300 !important;
}
a {
  font-family: "Roboto" !important;
  font-weight : 700 !important;
}
* { text-transform: none !important; }
ol li { font-family: "Roboto"}
* { text-transform: none !important; }
</style>
