import router from '../router'
const Phys10th =[
  {name:'Kinematics', lock:false, img:"/assets/motion.svg", data:[
           {
            title: "Motion in a Plane",
            description: ['Introduction to Vectors', 'Relative Motion in 2D', 'Projectile Motion', 'Circular Motion', '. . .'],
            isBlack: false,
            icon: 'fas fa-lock-open',
            action: () => router.push({path: '/physics/2dmotion'})
           },
           {
            title: "Motion in a Straight Line",
            description: ['Distance & Displacement','Speed & Velocity','Acceleration','Equations of Motion','. . .'],
            isBlack: true,
            icon: 'fas fa-lock-open',
            showhome:true,
            action: () => router.push({path:'/physics/sp'})
            },
            {
             title: "Circular Motion",
             description: ['Definition','Equation of Motion for a Simple Pendulum','Time Period of Oscillation','Small Angle Approximation','. . .'],
             isBlack: false,
             icon: 'fas fa-lock-open',
             showhome:true,
             action: () => router.push({path:'/physics/circularmotion'})
             } ]},
  {name:'Measurement', lock:true, img:"/assets/measurement.svg",  data:[
      {
        title: "Units & Mesurements",
        description: ['System of Units', 'Fundamental Units', '...'],
        icon: 'fas fa-lock',
        isBlack: false,
        action: () => router.push({path: ''})
      }]
    },
  {name:'Vectors', lock:true, img:"/assets/quadrant.svg", data:[
         {
          title: "Vectors",
          description: ['Vectors versus Scalars','Direction & Magnitude of a Vector','Components of a Vector','. . .'],
          isBlack: true,
          icon: 'fas fa-lock',
          showhome:true,
          action: () => router.push({path:'/physics/sp'})
          },
          {
          title: "Vector Addition & Subtraction",
          description: ['Graphical Method', 'Analytical Method','. . .'],
          isBlack: false,
          icon: 'fas fa-lock',
          showhome:true,
          action: () => router.push({path: '/physics/sm'})
          },
         ]},
      {name:'Gravitation', lock:true, img:"/assets/apple.svg", data:[
            {
             title: "Law of Gravitation",
             description: ['Gravitational Force between Two Bodies', 'Gravitational Constant', 'Acceleration due to Gravity', '. . .'],
             isBlack: true,
             icon: 'fas fa-lock',
              action: () => router.push({path: '/physics/gravity'})
            },
            {
             title: "Kepler\'s Law",
             description: ['Introduction to Vectors', 'Relative Motion in 2D', 'Projectile Motion', 'Circular Motion', '. . .'],
             isBlack: false,
             icon: 'fas fa-lock',
              action: () => router.push({path: ''})
            },
            {
             title: "Earth Satellite",
             description: ['Introduction to Vectors', 'Relative Motion in 2D', 'Projectile Motion', 'Circular Motion', '. . .'],
             isBlack: true,
             icon: 'fas fa-lock',
              action: () => router.push({path: ''})
            },
          ]},
{name:'Rotational Motion', lock:true, img:"/assets/rotation.svg", data:[
            {
              title: "Angular Motion",
              description: ['Angular Velocity','Angular Acceleration','Moment of Inertia','...'],
              icon: 'fas fa-lock',
              isBlack: false,
              action: () => router.push({path: ''})
            },
            {
              title: "Rolling Motion",
              description: ['Angular Velocity','Angular Acceleration','Moment of Inertia','...'],
              icon: 'fas fa-lock',
              isBlack: true,
              action: () => router.push({path: ''})
            },
            {
              title: "Angular Momentum",
              description: ['Angular Momentum','Moment of Inertia', 'Conservation of Angular Momentum','...'],
              icon: 'fas fa-lock',
              isBlack:false,
              action: () => router.push({path: '/physics/angularmotion'})
            }
          ]},
  {name:'Fluids', lock:true, img:"/assets/turbine.svg", data:[
    {
      title: "Density & Pressure of Fluids",
      description: ['Density & Pressure','Viscosity','Surface Tension','Buoyant force','...'],
      icon: 'fas fa-lock',
      isBlack: true,
      action: () => router.push({path: ''})
    },
    {
      title: "Pascal\'s Principle",
      description: ['Static Pressure', 'Pascal\'s Law','Application in Hydraulics','...'],
      icon: 'fas fa-lock',
      isBlack: false,
      action: () => router.push({path: ''})
    },
    {
      title: "Buoyancy",
      description: ['Buoyant Force','Archimedes\'s Principle','Application','...'],
      icon: 'fas fa-lock',
      isBlack: true,
      action: () => router.push({path: ''})
    },
    {
      title: "Bernoulli Principle",
      description: ['Bernoulli\'s Principle','Continuity Principle','Application','...'],
      icon: 'fas fa-lock',
      isBlack:false,
      action: () => router.push({path: '/physics/bernoulli'})
    },
   ]},
  {name:'Heat & Thermodynamics', lock:true, img:"/assets/thermometer.svg",  data:[
    {
      title: "Thermodynamics",
      description: ['Zeroth and First Law of Thermodynamics', 'Work from PV Graphs', 'Thermodynamic Processes', '. . .'],
      icon: 'fas fa-lock',
      isBlack: false,
      action: () => router.push({path: '/physics/thermodynamics'})
    },
    {
      title: "Thermal Properties of Matter",
      description: ['Temperature','Kinetic Theory of Gases','Ideal Gas Law','Thermal Expansion','...'],
      icon: 'fas fa-lock',
      isBlack: true,
      action: () => router.push({path: ''})
    },
    {
      title: "Thermal Expansion of Solids",
      description: ['Why objects expand?','Linear Expansion','Superficial Expansion','...'],
      icon: 'fas fa-lock',
      isBlack: false,
      action: () => router.push({path: '/physics/expansion'})
    },
  ]},
  {name:'Electricity', lock:true, img:"/assets/circuit.svg",  data:[
    {
      title: "Current & Resistance",
      description: ['Electric Current','Resistance & Resistors','Ohm\'s Law','...'],
      icon: 'fas fa-lock',
      isBlack: true,
      action: () => router.push({path: '/physics/electricity'})
    },
    {
      title: "Capacitance",
      description: ['Capacitance','Capacitors in Series & Parallel','Energy Stores in a Capacitor','...'],
      icon: 'fas fa-lock',
      isBlack: false,
      action: () => router.push({path: '/physics/electricity'})
    },
   ]},
  {name:'Magnetism', lock:true, img:"/assets/magnetism.svg",  data:[
    {
      title: "Magnetism",
      description: ['What is Magnetism?','Magnetic Force & Magnetic Field','Magnetic Monopoles and Dipoles', '...'],
      icon: 'fas fa-lock',
      isBlack: false,
      action: () => router.push({path: ''})
    },
    {
      title: "Electromagnetism",
      description: ['What is Electromagnetism?', 'Faraday\'s Law of Induction','Maxwell\'s Theory of Electromagnetism', '...'],
      icon: 'fas fa-lock',
      isBlack: true,
      action: () => router.push({path: ''})
    }]},
  {name:'Optics', lock:true, img:"/assets/prism.svg",  data:[
    {
      title: "Refraction of Light",
      description: ['What is Refraction?', 'Law of Refraction', 'Total Internal Reflection', '...'],
      icon: 'fas fa-lock',
      isBlack: true,
      action: () => router.push({path: '/physics/snell'})
    },
    {
      title: "Theory of Lens",
      description: ['Convex Lenses', 'Concave Lenses', '...'],
      icon: 'fas fa-lock',
      isBlack: false,
      action: () => router.push({path: '/physics/lens'})
    },
    {
      title: "Concave Mirrors",
      description: ['What is a Concave Mirror?', 'Mirror Formula', 'Image Formation in Concave Mirrors', 'Ray Diagram', '...'],
      icon: 'fas fa-lock',
      isBlack: true,
      action: () => router.push({path:'/physics/concavemirrors'})
    },
    {
      title: "Convex Mirrors",
      description: ['What is a Convex Mirror?', 'Mirror Formula',  'Image Formation in Convex Mirrors',  'Ray Diagram', '...'],
      icon: 'fas fa-lock',
      isBlack: false,
      action: () => router.push({path:'/physics/convexmirrors'})
    }
   ]},
  {name:'Waves', lock:true, img:"/assets/waves.svg",  data:[
    {
      title: "Waves",
      description: ['What is a Wave?','Types of Waves','Longitudinal Waves', 'Transverse Waves','...'],
      icon: 'fas fa-lock',
      isBlack: true,
      action: () => router.push({path: '/physics/waveA'})
    },
    {
      title: "Wave Equation",
      description: ['Equation of wave','Frequency','Amplitude','...'],
      icon: 'fas fa-lock',
      isBlack: false,
      action: () => router.push({path: ''})
    },
  ]},
]
export default Phys10th;
////////////////////////////END OF FILE /////////////////////////////////////////
