////////////////////////////6TH GRADE MATH TOPICS////////////////////////////////
import router from '../../../router'
const Math7th =[
  {name:'Fractions', lock:false, img:"/assets/fraction.svg",  data:[
    {
    title: "Understanding Fractions",
    description: ['Collecting Data','Organization of Data','Plotting Data','Pictographs','Bar Graphs','. . .'],
    flex:6,
    icon: 'fas fa-lock-open',
    action: () => router.push({path: '/mathematics/fractions2'})
   },
   {
   title: "Calculating Fractions",
   description: ['Collecting Data','Organization of Data','Plotting Data','Pictographs','Bar Graphs','. . .'],
   flex:6,
   icon: 'fas fa-lock-open',
   action: () => router.push({path: '/mathematics/fractions'})
  },
  {
  title: "Sum of Fractions",
  description: ['Collecting Data','Organization of Data','Plotting Data','Pictographs','Bar Graphs','. . .'],
  flex:12,
  icon: 'fas fa-lock-open',
  action: () => router.push({path: '/mathematics/fractions'})
 },
  ]},
  {name:'Numbers', lock:true, img:"/assets/numbers.svg", data:[
    {
      title: "Integers",
      description: ['Integers','Integers on a Number Line','Adding & Subtracting Integers', 'Comparing Integers','. . .'],
      flex:12,
      icon: 'fas fa-lock',
      action: () => router.push({path: '/mathematics/integer'})
    },
    {
      title: "Decimals",
      description: ['Decimals: Tenths & Hundreths','Decimals on a Number Line','Adding Decimals','Subtracting Decimals','. . .'],
      flex:6,
      icon: 'fas fa-lock',
      action: () => router.push({path: '/mathematics/integer'})
    },
    {
      title: "Ratio & Percentage",
      description: ['Ratio of Numbers','Percentage','Discount in a Price','Simple Interest', 'Compound Interest','. . .'],
      flex:6,
      icon: 'fas fa-lock',
      action: () => router.push({path: '/comingsoon'})
    },
    {
      title: "Rational Numbers",
      description: ['Rational Number','Rational Number on a Number Line','Comparing Rational Numbers', 'Operation on Rational Numbers','. . .'],
      flex:12,
      icon: 'fas fa-lock',
      action: () => router.push({path: '/comingsoon'})
    },
   ]},
  {name:'Algebra',  lock:true, img:"/assets/alg.svg", data:[
    {
      title: "Simple Equations",
      description: ['Setting up an Equation','Solving an Equation','Geometric Interpretation','Applications of Equations','. . .'],
      flex:12,
      icon: 'fas fa-lock',
      action: () => router.push({path: '/mathematics/6th/le'})
    },
    {
        title: "Parts of an Expression",
        description: ['Terms of an Expression','Coefficients','Monomials, Binomials, Trinomials, and Polynomials','Addition and Subtraction of Expressions','Evaluating Expressions','. . .'],
        flex:6,
        icon: 'fas fa-lock',
        action: () => router.push({path: '/mathematics/expression'})
    },
    {
        title: "Building an Expression",
        description: ['Terms of an Expression','Coefficients','Monomials, Binomials, Trinomials, and Polynomials','Addition and Subtraction of Expressions','Evaluating Expressions','. . .'],
        icon: 'fas fa-lock',
        flex:6,
        action: () => router.push({path: '/mathematics/expression2'})
    },
    {
      title: "Exponents and Powers",
      description: ['Exponents','Laws of Exponents','Multiplying Exponents with the Same Base','Dividing Exponents with the Same Base','Taking Power of a Power','. . .'],
      flex:12,
      icon: 'fas fa-lock',
      action: () => router.push({path: '/mathematics/exponent'})
    },
  ]},
  {name:'Lines and Angles', lock:true, img:"/assets/shapes.svg", data:[
    {
      title: "Complementary Angles",
      description: ['Complementary Angles','Supplementary Angles','Adjacent Angles','Angles made by a Transversal','. . .'],
      flex:6,
      icon: 'fas fa-lock',
      action: () => router.push({path: '/mathematics/angles'})
    },
    {
      title: "Supplementary Angles",
      description: ['Complementary Angles','Supplementary Angles','Adjacent Angles','Angles made by a Transversal','. . .'],
      flex:6,
      icon: 'fas fa-lock',
      action: () => router.push({path: '/mathematics/angles'})
    },
    {
      title: "Parallel Lines",
      description: ['Introduction','Definition of Congruence','Congruence of Angles','Congruence of Triangles','. . .'],
      icon: 'fas fa-lock',
      flex:12,
      action: () => router.push({path:'/mathematics/parallel'})
    }
    ]},

    {name:'Practical Geometry',  lock:true, img:"/assets/divider.svg", data:[
    {
      title: "Line Construction",
      description: ['Construction of a Line through a Point','Construction of a Line parallel to another Line','Construction of Triangles','. . .'],
      flex:6,
      icon: 'fas fa-lock',
      action: () => router.push({path: '/mathematics/drawLine'})
    },
    {
      title: "Triangle Construction",
      description: ['Construction of Triangles from Angles', 'Construction of Triangles from Sides', '. . .'],
      flex:6,
      icon: 'fas fa-lock',
      action: () => router.push({path: '/mathematics/drawTriangle'})
    },
    {
      title: "Drawing a Circle",
      description: ['Construction of a Line through a Point','Construction of a Line parallel to another Line','Construction of Triangles','. . .'],
      flex:12,
      icon: 'fas fa-lock',
      action: () => router.push({path: '/mathematics/drawCircle'})
    },
   ]},
   {name:'Geometric Measurement I', lock:true, img:"/assets/mensuration.svg",  data:[
         {
           title: "Circumference of a Circle",
           subtitle:"(Concepts)",
           description: ['Squares', 'Rectangles', 'Parallelograms','Triangles','. . .'],
           icon: 'fas fa-lock',
           flex:12,
           action: () => router.push({path: '/mathematics6th/perimeter'})
         },
         {
           title: "Perimeter of a Rectangle",
           subtitle:"(Concepts)",
           description: ['Squares', 'Rectangles', 'Parallelograms','Triangles','. . .'],
           icon: 'fas fa-lock',
           flex:6,
           action: () => router.push({path: '/mathematics6th/perimeter2'})
         },
         {
           title: "Perimeter of a Rectangle",
           subtitle:"(Quiz)",
           description: ['Squares', 'Rectangles', 'Parallelograms','Triangles','. . .'],
           icon: 'fas fa-lock',
           flex:6,
           action: () => router.push({path: '/mathematics6th/quizPerimeter'})
         },
         {
             title: "Area of Geometric Shapes",
             subtitle:"(Concepts)",
             description: ['Squares', 'Rectangles', 'Parallelograms','Triangles','. . .'],
             icon: 'fas fa-lock',
             flex:12,
             action: () => router.push({path: '/mathematics/area'})
           },
       ]
       },
  {name:'Geometric Measurement II',  lock:true, img:"/assets/mensuration.svg",  data:[
    {
      title: "Area of a Triangle",
      subtitle:"(Concepts)",
      description: ['Squares and Rectangles','Perimeter and Area of a Triangle','Perimeter and Area of a Parallelogram','Circumference and Area of a Circle','. . .'],
      icon: 'fas fa-lock',
      flex:6,
      action: () => router.push({path: '/mathematics7th/area2'})
    },
    {
      title: "Area of a Triangle",
      subtitle:"(Practice)",
      description: ['Squares and Rectangles','Perimeter and Area of a Triangle','Perimeter and Area of a Parallelogram','Circumference and Area of a Circle','. . .'],
      icon: 'fas fa-lock',
      flex:6,
      action: () => router.push({path: '/mathematics7th/area3'})
    },
    {
      title: "Area of a Triangle",
      subtitle:"(Quiz)",
      description: ['What is Circumference?','Circumference Formula', 'Measuring Circumference', '. . .'],
      icon: 'fas fa-lock',
      flex:12,
      action: () => router.push({path: '/mathematics7th/area4'})
    },
  ]},
  {name:'Triangles',  lock:true, img:"/assets/triangle.svg", data:[
    {
      title: "Triangle Construction",
      description: ['Construction of Triangles from Angles', 'Construction of Triangles from Sides', '. . .'],
      flex:6,
      icon: 'fas fa-lock',
      action: () => router.push({path: '/mathematics/drawTriangle'})
    },
    {
      title: "Properties of a Triangle",
      description: ['Median of a Triangle','Altitude of a Triangle','Centroid','Types of Triangles based on Sides','Types of Triangles based on Angles','. . .'],
      icon: 'fas fa-lock',
      flex:6,
      action: () => router.push({path: '/mathematics/triangles'})
    },
    {
      title: "Right-Angled Triangle",
      description: ['Construction of Triangles from Angles', 'Construction of Triangles from Sides', '. . .'],
      flex:12,
      icon: 'fas fa-lock',
      action: () => router.push({path: '/mathematics/pythagorean'})
    },

 ]},
 {name:'Data & Statistics',  lock:true, img:"/assets/graph-paper.svg",  data:[
     {
     title: "Arithmetic Mean",
     subtitle:"(Concepts)",
     description: ['Collecting Data','Organization of Data','Plotting Data','Pictographs','Bar Graphs','. . .'],
     flex:12,
     icon: 'fas fa-lock',
     action: () => router.push({path: '/mathematics6th/quizMean'})
   },
   {
   title: "Arithmetic Mean",
   subtitle:"(Quiz)",
   description: ['Collecting Data','Organization of Data','Plotting Data','Pictographs','Bar Graphs','. . .'],
     flex:12,
   icon: 'fas fa-lock',
   action: () => router.push({path: '/mathematics6th/quizMean'})
 }
   ]},
  {name:'Visualizing Data', lock:true, img:"/assets/data-handling.svg",  data:[
      {
        title: "Types of Data Visualization",
        description: ['Collecting Data','Organization of Data','Plotting Data','Pictographs','Bar Graphs','. . .'],
        icon: 'fas fa-lock',
        flex:12,
        action: () => router.push({path: '/mathematics6th/data'})
      },
      {
        title: "Build a Bar Chart",
        description: ['Collecting Data','Organization of Data','Plotting Data','Pictographs','Bar Graphs','. . .'],
        flex:6,
        icon: 'fas fa-lock',
        action: () => router.push({path: '/mathematics7th/quizBar'})
      },
      {
      title: "Build a Pie Chart",
      description: ['Collecting Data','Organization of Data','Plotting Data','Pictographs','Bar Graphs','. . .'],
      flex:6,
      icon: 'fas fa-lock',
      action: () => router.push({path: '/mathematics/pie'})
    },
    {
      title: "Build a Pictogram",
      description: ['Collecting Data','Organization of Data','Plotting Data','Pictographs','Bar Graphs','. . .'],
      flex:12,
      icon: 'fas fa-lock',
      action: () => router.push({path: '/mathematics/pic'})
    }
    ]
  },
  {name:'3D Shapes',  lock:true, img:"/assets/shapes.svg",  data:[
  {
    title: "Views of 3D Shapes",
    description: ['Collecting Data','Organization of Data','Plotting Data','Pictographs','Bar Graphs','. . .'],
    flex:6,
    icon: 'fas fa-lock',
    action: () => router.push({path: '/mathematics7th/demo3dShapes'})
  },
  {
  title: "Practice",
  description: ['Collecting Data','Organization of Data','Plotting Data','Pictographs','Bar Graphs','. . .'],
  flex:6,
  icon: 'fas fa-lock',
  action: () => router.push({path: '/mathematics7th/demo3dShapes'})
  },
  {
    title: "Test Your Knowledge",
    description: ['Collecting Data','Organization of Data','Plotting Data','Pictographs','Bar Graphs','. . .'],
    flex:12,
    icon: 'fas fa-lock',
    action: () => router.push({path: '/mathematics7th/demo3dShapes'})
  },
  ]}
  ]
export default Math7th;
////////////////////////////END OF FILE /////////////////////////////////////////
