const Math = [
  {
    path: '/mathematics6th',
    name: '6th Grade Math',
    component: () => import('./views/Math/Mathematics6th.vue')
  },
  {
    path: '/mathematics7th',
    name: '7th Grade Math',
    component: () => import('./views/Math/Mathematics7th.vue')
  },
  {
    path: '/mathematics8th',
    name: '8th Grade Math',
    component: () => import('./views/Math/Mathematics8th.vue')
  },
  {
    path: '/mathematics9th',
    name: '9th Grade Math',
    component: () => import('./views/Math/Mathematics9th.vue')
  },
  {
    path: '/mathematics10th',
    name: '10th Grade Math',
    component: () => import('./views/Math/Mathematics10th.vue')
  },
  {
    path: '/mathematics11th',
    name: '11th Grade Math',
    component: () => import('./views/Math/Mathematics11th.vue')
  },
  {
    path: '/mathematics12th',
    name: '12th Grade Math',
    component: () => import('./views/Math/Mathematics12th.vue')
  },
  {
    path: '/mathematics/coord',
    name: 'Coordinate Geometry',
    component: () => import('./components/mathematics/Math9thGrade/coord/SubtopicMenu.vue')
  },
];

export default Math;
