////////////////////////////6TH GRADE MATH TOPICS////////////////////////////////
import router from '../../../router'
const Math11th =[
  {name:'Straight Lines', lock:false, img:"/assets/lines.svg", data:[
    {
      title: 'Straight Line',
      subtitle:"(Part 1)",
      description: ['Graphical Representation of Sets','Venn Diagram','Examples', '. . .'],
      flex:6,
      icon: 'fas fa-lock-open',
      action: () => router.push({path:'/mathematics11th/line1'})
    },
    {
      title: 'Straight Line',
      subtitle:"(Part 2)",
      description: ['Sets','Union of Sets','Intersection of Sets', '. . .'],
      flex:6,
      icon: 'fas fa-lock-open',
      action: () => router.push({path:'/mathematics11th/line2'})
    },
    {
      title: 'Straight Line',
      subtitle:"(Part 3)",
      description: ['Sets','Union of Sets','Intersection of Sets', '. . .'],
      flex:12,
      icon: 'fas fa-lock-open',
      action: () => router.push({path:'/mathematics11th/line4'})
    },
  ]},

  {name:'Sets', lock:false, img:"/assets/venn-diagram.svg", data:[
    {
      title: 'Venn Diagram',
      description: ['Graphical Representation of Sets','Venn Diagram','Examples', '. . .'],
      flex:6,
      icon: 'fas fa-lock-open',
      action: () => router.push({path:'/mathematics/sets1'})
    },
    {
      title: 'Union & Intersection of Sets',
      description: ['Sets','Union of Sets','Intersection of Sets', '. . .'],
      flex:6,
      icon: 'fas fa-lock-open',
      action: () => router.push({path:'/mathematics/sets2'})
    },
    {
      title: 'Test Your Knowledge',
      description: ['Sets','Union of Sets','Intersection of Sets', '. . .'],
      flex:12,
      icon: 'fas fa-lock-open',
      action: () => router.push({path:'/mathematics/sets3'})
    },
  ]},
  {name:'Calculus', lock:true, img:"/assets/calculus.svg",  data:[
    {
      title: "Tangents & Derivatives",
      description: ['Derivatives and Rate of Change', 'Tangents & Normals', 'Maxima & Minima', 'Applications to Practical Problems', '. . .'],
      icon: 'fas fa-lock',
      flex:12,
      action: () => router.push({path: '/mathematics11th/quizTangent'})
    },
    {
      title: "Rates as Derivatives",
      description: ['Indefinite Integrals', 'Integration by Substitution', 'Integration by Parts', 'Integration by Fraction', '. . .'],
      icon: 'fas fa-lock',
      flex:12,
      action: () => router.push({path: '/mathematics12th/quizDerivative'})
    }
  ]},
  {name:'Complex Numbers', lock:true, img:"/assets/complex.svg", data:[
    {
      title: "Complex Numbers",
      description: ['What are Complex Numbers?','Magnitude and Argument','Polar Form of a Complex Number', 'Addition of Complex Numbers', '. . .'],
      flex:6,
      icon: 'fas fa-lock',
      action: () => router.push({path:'/mathematics/complexAddition'})
    },
    {
      title: "Complex Plane",
      description: ['What are Complex Numbers?','Magnitude and Argument','Polar Form of a Complex Number', 'Addition of Complex Numbers', '. . .'],
      flex:6,
      icon: 'fas fa-lock',
      action: () => router.push({path:'/mathematics11th/quizComplex'})
    },
    {
      title: "Product of Complex Numbers",
      description: ['What are Complex Numbers?','Magnitude and Argument','Polar Form of a Complex Number', 'Addition of Complex Numbers', '. . .'],
      flex:12,
      icon: 'fas fa-lock',
      action: () => router.push({path:'/mathematics11th/quizMultiply'})
    },
  ]},
  {name:'Coordinate Geometry', lock:true, img:"/assets/Cartesians.svg", data:[
    {
      title: "Points",
      description: ['Coordinates of a Point','Distance Formula','Section Formula', 'Area of a Triangle','. . .'],
      flex:12,
      icon: 'fas fa-lock',
      action: () => router.push({path:'/mathematics11th/quizPoint'})
    },
    {
      title: "Distance between Points",
      description: ['Coordinates of a Point','Distance Formula','Section Formula', 'Area of a Triangle','. . .'],
      flex:6,
      icon: 'fas fa-lock',
      action: () => router.push({path:'/mathematics11th/distance'})
    },
    {
      title: "Section Formula",
      description: ['Coordinates of a Point','Distance Formula','Section Formula', 'Area of a Triangle','. . .'],
      flex:6,
      subtitle:"(Part 1)",
      icon: 'fas fa-lock',
      action: () => router.push({path:'/mathematics11th/quizMid'})
    },
    {
      title: "Section Formula",
      description: ['Coordinates of a Point','Distance Formula','Section Formula', 'Area of a Triangle','. . .'],
      flex:12,
      subtitle:"(Part 2)",
      icon: 'fas fa-lock',
      action: () => router.push({path:'/mathematics11th/quizSection'})
    },
  ]},
  {name:'Conic Sections', lock:true, img:"/assets/cone.svg", data:[
   {
     title: "Conic Sections",
     subtitle:"(Basics)",
     description: ['Introduction','Euclid\'s Lemma','Fundamental Theorem of Arithmatic','Irrational Numbers', 'Decimal Exapansions','. . .'],
     flex:12,
     icon: 'fas fa-lock',
     action: () => router.push({path: '/mathematics11th/conic'})
   },
   {
     title: "Conic Sections",
     subtitle:"(Quiz)",
     description: ['Introduction','Nth Term of an AP', 'Sum of first N terms of an AP','. . .'],
     flex:12,
     icon: 'fas fa-lock',
     action: () => router.push({path: '/mathematics11th/quizConic'})
   },
  ]},
  {name:'Parabola', lock:true, img:"/assets/parabola.svg", data:[
   {
     title: "Equation of a Parabola",
     description: ['Introduction','Euclid\'s Lemma','Fundamental Theorem of Arithmatic','Irrational Numbers', 'Decimal Exapansions','. . .'],
     flex:12,
     icon: 'fas fa-lock',
     action: () => router.push({path: '/mathematics11th/quizParabola'})
   },
   {
     title: "Focal Distance",
     description: ['Introduction','Euclid\'s Lemma','Fundamental Theorem of Arithmatic','Irrational Numbers', 'Decimal Exapansions','. . .'],
     flex:12,
     icon: 'fas fa-lock',
     action: () => router.push({path: '/mathematics11th/quizParabola'})
   },
  ]},
  {name:'Geometry', lock:true, img:"/assets/shapes.svg", data:[
    {
      title: "Triangles",
      description: ['Similar Triangles','Criteria for Similar Triangles','Areas of Similar Triangles', 'Pythagoras Theorem','. . .'],
      icon: 'fas fa-lock',
      flex:6,
      action: () => router.push({path: '/mathematics/triangles'})
    },
    {
      title: "Circles",
      description: ['Introduction','Tangent to a Circle','Number of Tangents from a Point','Length of a Tangent','. . .'],
      icon: 'fas fa-lock',
      flex:6,
      action: () => router.push({path:'/comingsoon'})
    },
    {
      title: "Constructions",
      description: ['Divison of a Line Segment','Tangents to a Circle','Construction of Triangles','. . .'],
      flex:12,
      icon: 'fas fa-lock',
      action: () => router.push({path: '/comingsoon'})
    },
   ]},
  {name:'Geometric Measurement', lock:true, img:"/assets/mensuration.svg",  data:[
    {
      title: "Area & Circumference of a Circle",
      description: ['Area of a Circle','Circumference', 'Length of a Sector', 'Area of a Sector','. . .'],
      icon: 'fas fa-lock',
      flex:12,
      action: () => router.push({path: '/comingsoon'})
    },
    {
      title: "Volumes and Surface Areas",
      description: ['Area of a Triangle: Heron\'s Formula','Area of a Quadrilateral','. . .'],
      icon: 'fas fa-lock',
      flex:12,
      action: () => router.push({path: '/comingsoon'})
    },
  ]},
  {name:'Trigonometry', lock:true, img:"/assets/right-triangle.svg",
   data:[
    {
      title: "Trigonometric Ratios ",
      description: ['Base, Height & Hypotenuse', 'Trigonometric Ratios','Heights & Distances','Trigonometric Identities','. . .'],
      icon: 'fas fa-lock',
      flex:12,
      action: () => router.push({path: '/comingsoon'})
    }]},
  {name:'Algebra', lock:true, img:"/assets/alg.svg", data:[
    {
      title: "Polynomials",
      description: ['Linear, Quadratic & Cubic Polynomial','Zeros of a Polynomials','Division of Polynomials','Remainder Theorem','. . .'],
      flex:6,
      icon: 'fas fa-lock',
      action: () => router.push({path:'/mathematics/polynomials'})
    },
    {
      title: "Linear Equations in Two Variables",
      description: ['Linear Equations in Two Variables','Plotting Equations','Solving Equations','Types of Solutions','. . .'],
      flex:6,
      icon: 'fas fa-lock',
      action: () => router.push({path: '/mathematics/sle'})
    },
    {
      title: "Quadratic Equations",
      description: ['Standard Form','Solving by Factorization','Quadratic Formula','Discriminant', 'Nature of Roots','. . .'],
      flex:12,
      icon: 'fas fa-lock',
      action: () => router.push({path:'/mathematics/qe'})
    },
  ]},
  /////////////////////////////////////////////////////////////////////////////////////
 {name:'Series & Progressions', lock:true, img:"/assets/series.svg", data:[
  {
    title: "Arithmetic Progressions (AP)",
    description: ['Introduction','Euclid\'s Lemma','Fundamental Theorem of Arithmatic','Irrational Numbers', 'Decimal Exapansions','. . .'],
    flex:12,
    icon: 'fas fa-lock',
    action: () => router.push({path: '/comingsoon'})
  },
  {
    title: "Nth Terms of an AP",
    description: ['Introduction','Nth Term of an AP', 'Sum of first N terms of an AP','. . .'],
    flex:6,
    icon: 'fas fa-lock',
    action: () => router.push({path: '/comingsoon'})
  },
  {
    title: "Sum of an Arithmetic Progression",
    description: ['Introduction','Nth Term of an AP', 'Sum of first N terms of an AP','. . .'],
    flex:6,
    icon: 'fas fa-lock',
    action: () => router.push({path: '/comingsoon'})
  },
  {
    title: "Geometric Progressions (GP)",
    description: ['Introduction','Euclid\'s Lemma','Fundamental Theorem of Arithmatic','Irrational Numbers', 'Decimal Exapansions','. . .'],
    flex:12,
    icon: 'fas fa-lock',
    action: () => router.push({path: '/comingsoon'})
  },
 ]},
/////////////////////////////////////////////////////////////////////////////////////////////
  {name:'Data & Statistics', lock:true, img:"/assets/graph-paper.svg",  data:[
    {
      title: "Mean of Grouped Data",
      description: ['Formula for Mean of a Grouped Data', 'Examples','. . .'],
      flex:6,
      icon: 'fas fa-lock',
      action: () => router.push({path: '/comingsoon'})
    },
    {
      title: "Median of Grouped Data",
      description: ['Formula for Median a Grouped Data', 'Examples','. . .'],
      flex:6,
      icon: 'fas fa-lock',
      action: () => router.push({path: '/comingsoon'})
    },
    {
      title: "Mode of Grouped Data",
      description: ['Formula for Mode of a Grouped Data', 'Examples','. . .'],
      flex:12,
      icon: 'fas fa-lock',
      action: () => router.push({path: '/comingsoon'})
    },
   ]},
////////////////////////////////////////////////////////////////////////////////////////////////
  {name:'Probability', lock:true, img:"/assets/probs.svg", data:[
  {
    title: "Probability I",
    description: ['Event & Outcomes','Probability of an Event','Sum of Probabilities', '. . .'],
    flex:12,
    icon: 'fas fa-lock',
    action: () => router.push({path: '/mathematics12th/prob'})
  },
  {
    title: "Probability II",
    description: ['Event & Outcomes','Probability of an Event','Sum of Probabilities', '. . .'],
    flex:6,
    icon: 'fas fa-lock',
    action: () => router.push({path: '/mathematics/probability'})
  },
  {
    title: "Probability III",
    description: ['Event & Outcomes','Probability of an Event','Sum of Probabilities', '. . .'],
    flex:6,
    icon: 'fas fa-lock',
    action: () => router.push({path: '/mathematics/prob'})
  }]},
//////////////////////////////////////////////////////////////////////////////////////////////////
  {name:'Vectors', lock:true, img:"/assets/vectors.svg", data:[
    {
      title: "Introduction to Vectors",
      description: ['Event & Outcomes','Probability of an Event','Sum of Probabilities','. . .'],
      flex:12,
      icon: 'fas fa-lock',
      action: () => router.push({path: '/mathematics/vectors'})
    },
    {
      title: "Components of a Vector",
      description: ['Event & Outcomes','Probability of an Event','Sum of Probabilities','. . .'],
      flex:6,
      icon: 'fas fa-lock',
      action: () => router.push({path: '/mathematics/vectors/cartesian'})
    },
  {
    title: "Sum of Vectors",
    description: ['Event & Outcomes','Probability of an Event','Sum of Probabilities','. . .'],
    flex:6,
    icon: 'fas fa-lock',
    action: () => router.push({path: '/mathematics/vectorSum'})
  },
  {
    title: "Dot Product of Two Vectors",
    description: ['Event & Outcomes','Probability of an Event','Sum of Probabilities','. . .'],
    flex:6,
    icon: 'fas fa-lock',
    action: () => router.push({path: '/mathematics/vectors/inner'})
  },
  {
    title: "Cross Product of Two Vectors",
    description: ['Event & Outcomes','Probability of an Event','Sum of Probabilities','. . .'],
    flex:6,
    icon: 'fas fa-lock',
    action: () => router.push({path: '/mathematics/vectors/outer'})
  },
]}
]
export default Math11th;
////////////////////////////END OF FILE /////////////////////////////////////////
