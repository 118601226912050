////////////////////////////ITEMS IN THE MENU TOOLBAR ////////////////////////////////
const MenuItems = [
  {
    path: '/try',
    name: 'try',
    component: () => import('./components/physics/try/Lesson.vue')
  },
  {
    path: '/',
    name: 'home',
    component: () => import('./views/Home/NewAbout.vue')
  },
  {
     path: '/Demo',
     name: 'Demo',
     component: () => import('./views/Demo/Dashboard.vue')
   },
   {
     path: '/contact',
     name: 'contact',
     component: () => import('./views/Contact/Contact.vue'),
   },
   {
       path: '/register',
       name: 'registration page',
       component: () => import('./views/Register/Contact.vue')
   },
    {
      path: '/magic',
      name: 'Landing Page',
      component: () => import('./views/Landing/Landing.vue')
    },
    {
      path: '*',
      name: 'Page Not Found',
      component: () => import('./views/PNP/PNP.vue')
     },
     {
       path: '/PrivacyPolicy',
       name: 'PrivacyPolicy',
       component: () => import('./views/PP/PP.vue')
     },
     {
       path: '/TermsofService',
       name: 'TermsofService',
       component: () => import('./views/PP/PP.vue')
     },
     {
       path: '/subjects',
       name: 'Select a Subject',
       component: () => import('./views/Subjects.vue')
     },
    ]
export default MenuItems;
////////////////////////////END OF FILE /////////////////////////////////////////
