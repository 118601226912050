import router from '../router'
const Subjects =[
    {
      title: "Foundations (Grades 6-8)",
      description: "Topics in Foundations Math",
      img: '/assets/masswhite.svg',
      action: () => router.push({path: '/gradesFoundations'})
   //   action: () => router.push({path: '/gradesFoundations?id=RGNYTWJhSmFsVkVOOTV0QXNOVlYxUT09'})
    },
    {
      title: "Intermediate (Grades 8-10)",
      description: "Topics in Intermediate Math",
      img: '/assets/proton.svg',
      action: () => router.push({path: '/gradesIntermediate'})
    //  action: () => router.push({path: '/gradesIntermediate?id=MHVvcThONUMwS1hNekhkOWlGdXR4dz09'})
    },
    {
      title: "Advanced (Grades 10-12)",
      description: "Topics in Advanced Math",
      img: '/assets/algebraII.svg',
      action: () => router.push({path: '/gradesAdvanced'})
      //action: () => router.push({path: '/gradesAdvanced?id=Z1lWTmJ0OXJBWkNZR1orT1MwMWlsUT09'})
    },
    {
      title: " Watch a Demo",
      description: "Topics in Scientific Computing & Programming",
      img: '/assets/demos.svg',
      action: () => router.push({path: '/demo?id=cEcyQXJaR294OTNFc2YxTXpNb0Y5Zz09'})
    },
    {
      title: "Help & Support",
      description: "Have a Question? Contact Us.",
      img: '/assets/customerSupport.svg',
      action: () => router.push({path: '/contact?id=UVl0TyszbG5iOUxIY21jSUJFM1hSZz09'})
    },
]
export default Subjects;
////////////////////////////END OF FILE /////////////////////////////////////////
