import router from '../router'
const techTopics =[
  [
    {
      title: "Forces in Mechanics",
      description: "Frictional Force | Elastic Force | Viscous & Drag Forces | ...",
      isBlack: true,
      icon: 'fas fa-lock-open',
      action: () => router.push({path: '/forces-in-mechanics'})
    },
    {
      title: "Fluid Mechanics",
      description: "Bernoulli's Principle | Airfoil Theory | Laminar vs. Turbulent Flows | Capillary Effect | ...",
      action: () => router.push({path: '/fluid-mechanics'}),
      icon: 'fas fa-lock-open',
    },
    {
      title: "Heat & Mass Transfer",
      description: "Fourier's Law of Conduction | Theory of Insulation | ...",
      isBlack: true,
      icon: 'fas fa-lock-open',
      action: () => router.push({path: '/heat-mass-transfer'})
    },
  ],
  [
    {
      title: "Rock mechanics",
      description: "Mohr-Columb Model of Failure | Drucker-Prager Model | Borehole Mechanics | ...",
      action: () => router.push({path: ''}),
      icon: 'fas fa-lock',
    },
    {
      title: "Calculus",
      description: "Differentiation | Integration | Theorems in Calculus | ...",
      isBlack: true,
      icon: 'fas fa-lock',
      action: () => router.push({path: ''})
    },
    {
      title: "Electromagnetism",
      description: "Introduction | Electric Fields | Magnetic Fields | Maxwell's Law | ...",
      icon: 'fas fa-lock',
      action: () => router.push({path: ''})
    },
  ],
  [
    {
      title: "Transformation Laws",
      description: "Vector Transformation | Tensor Transformation | Mohr Circle | ...",
      isBlack: true,
      icon: 'fas fa-lock-open',
      action: () => router.push({path: '/transformation-laws'})
    },
    {
      title: "Structural Mechanics",
      description: "Deformation | Stress | Strains | Constitutive Laws",
      icon: 'fas fa-lock-open',
      action: () => router.push({path: '/structural-mechanics'})
    },
     {
      title: "Composite Materials",
      description: "Study of the strength and mechanics of laminated structures",
      icon: 'fas fa-lock',
      isBlack: true,
      action: () => router.push({path: ''})
    }
  ]
]
export default techTopics;
////////////////////////////END OF FILE /////////////////////////////////////////
