////////////////////////////7TH GRADE MATH TOPICS////////////////////////////////
const Math7th = [
  {
    path: '/mathematics/arithmeticMean',
    name: 'Mean',
    //beforeEnter: conditionalNext,
    component: () => import('../arithmeticMean/Lesson.vue')
  },
  // "Angles" routed in 6thGrade.
  {
    path: '/mathematics/triangles',
    name: 'Triangle and Its Properties',
    //beforeEnter: conditionalNext,
    component: () => import('../triangles/SubtopicMenu.vue')
  },
  {
    path: '/mathematics/pythagorean',
    name: 'Pythagorean Theorem',
    component: () => import('../Math7thGrade/pyt/Lesson.vue')
  },
  {
    path: '/mathematics/perimeter1',
    name: 'Perimeter',
    component: () => import('../Math6thGrade/demoPerimeter2/Lesson.vue')
  },
  {
    path: '/mathematics/3dShapes',
    name: '3D Shapes',
    component: () => import('../Math7thGrade/shapes/Lesson.vue')
  },
  {
    path: '/mathematics/exponent',
    name: 'exponent',
    component: () => import('../Math9thGrade/exponent/Lesson.vue')
  },
  {
    path: '/mathematics7th/area',
    name: 'area of a rectangle',
    component: () => import('../Math7thGrade/area/Lesson.vue')
  },
  {
    path: '/mathematics7th/area2',
    name: 'area of a triangle - concept',
    component: () => import('../Math7thGrade/area2/Lesson.vue')
  },
  {
    path: '/mathematics7th/area3',
    name: 'area of a triangle - practice',
    component: () => import('../Math7thGrade/area3/Lesson.vue')
  },
  {
    path: '/mathematics7th/area4',
    name: 'area of a triangle - test',
    component: () => import('../Math7thGrade/area4/Lesson.vue')
  },
  {
    path: '/mathematics7th/demo3dShapes',
    name: 'Demo 3D Shapes',
    component: () => import('../Math7thGrade/demo3Dshapes/Lesson.vue')
  },
  {
    path: '/mathematics7th/quiz3dShapes',
    name: 'Quiz 3D Shapes',
    component: () => import('../Math7thGrade/quiz3Dshapes/Lesson.vue')
  },
  {
    path: '/mathematics7th/demoBar',
    name: 'Bars',
    component: () => import('../Math7thGrade/demoBar/Lesson.vue')
  },
  {
    path: '/mathematics7th/quizBar',
    name: 'Bars',
    component: () => import('../Math7thGrade/quizBar/Lesson.vue')
  },
    ]
    function conditionalNext(to, from, next) {
              if (localStorage.getItem('token'))
              {
                  next();
              }
              else
              {
                next("/")
              }
      }
export default Math7th;
////////////////////////////END OF FILE /////////////////////////////////////////
