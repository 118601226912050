////////////////////////////6TH GRADE MATH TOPICS////////////////////////////////
import router from '../../../router'
const Math8th =[
  {name:'Percentage',
    lock:false,
    img:"/assets/percentage.svg",
    data:[
      {
        title: "Percentage to Number",
        description: ['Rational & Irrational Numbers','Examples of Rational Numbers','. . .'],
        flex:6,
        icon: 'fas fa-lock-open',
        action: () => router.push({path: '/mathematics/percentage1'})
      },
      {
        title: "Number to Percentage",
        description: ['Rational & Irrational Numbers','Examples of Rational Numbers','. . .'],
        flex:6,
        icon: 'fas fa-lock-open',
        action: () => router.push({path: '/mathematics/percentage2'})
      },
      {
        title: "Percentage Calculator",
        description: ['Rational & Irrational Numbers','Examples of Rational Numbers','. . .'],
        flex:12,
        icon: 'fas fa-lock-open',
        action: () => router.push({path: '/mathematics/percentage2'})
      }
        ]
  },
  {name:'Rational Numbers',
    lock:true,
    img:"/assets/bars.svg",
    data:[
      {
        title: "Rational Number",
        description: ['Rational & Irrational Numbers','Examples of Rational Numbers','. . .'],
        flex:12,
        icon: 'fas fa-lock',
        action: () => router.push({path: '/comingsoon'})
      },
      {
        title: "Properties of Rational Numbers",
        description: ['Additivity','Associativity','Commutivity','. . .'],
        flex:12,
        icon: 'fas fa-lock',
        action: () => router.push({path: '/comingsoon'})
      },
        ]
  },
  {name:'Squares & Square Roots',
    lock:true,
    img:"/assets/square-root.svg",
    data:[
      {
        title: "Perfect Squares",
        description: ['Rational & Irrational Numbers','Examples of Rational Numbers','. . .'],
        flex:12,
        icon: 'fas fa-lock',
        action: () => router.push({path: '/mathematics/squareRoot'})
      },
      {
        title: "Square Roots",
        description: ['Additivity','Associativity','Commutivity','. . .'],
        flex:12,
        icon: 'fas fa-lock',
        action: () => router.push({path: '/mathematics/squareRoot'})
      },
        ]
  },
  {name:'Parallel Lines',
    lock:false,
    img:"/assets/lines.svg",
    data:[
      {
        title: "Parallel Lines",
        description: ['Rational & Irrational Numbers','Examples of Rational Numbers','. . .'],
        flex:6,
        icon: 'fas fa-lock-open',
        action: () => router.push({path: '/mathematics8th/demoParallel'})
      },
      {
        title: "Pair of Angles",
        description: ['Additivity','Associativity','Commutivity','. . .'],
        flex:6,
        icon: 'fas fa-lock-open',
        action: () => router.push({path: '/mathematics8th/demoAngles'})
      },
      {
        title: "Parallel Lines",
        subtitle:"(Quiz)",
        description: ['Rational & Irrational Numbers','Examples of Rational Numbers','. . .'],
        flex:12,
        icon: 'fas fa-lock-open',
        action: () => router.push({path: '/mathematics8th/quizParallel'})
      },
        ]
  },
  {name:'System of Linear Equations',
    lock:true,
    img:"/assets/intersect.svg",
    data:[
      {
        title: "Number of Solutions",
        description: ['Algebraic Expressions','Types of Equations','Examples of Equations','. . .'],
        flex:6,
        icon: 'fas fa-lock',
        action: () => router.push({path:'/mathematics8th/quizSLE'})
      },
      {
        title: "Solving Linear Equations",
        description: ['Solution of an Equation','Equation with Variable on One Side','Equation with Variable on Both Sides','. . .'],
        flex:6,
        icon: 'fas fa-lock',
        action: () => router.push({path:'/mathematics8th/demoSLE'})
      },
      {
        title: "Applications of Linear Equations",
        description: ['Linear Equations in Daily Life','Word Problems','. . .'],
        flex:12,
        icon: 'fas fa-lock',
        action: () => router.push({path:'/mathematics8th/demoSLE'})
      },
        ]
  },
  {name:'Visualizing Data', lock:true, img:"/assets/data-handling.svg",  data:[
      {
        title: "Types of Data Visualization",
        description: ['Collecting Data','Organization of Data','Plotting Data','Pictographs','Bar Graphs','. . .'],
        icon: 'fas fa-lock',
        flex:12,
        action: () => router.push({path: '/mathematics6th/data'})
      },
      {
        title: "Build a Bar Chart",
        description: ['Collecting Data','Organization of Data','Plotting Data','Pictographs','Bar Graphs','. . .'],
        flex:6,
        icon: 'fas fa-lock',
        action: () => router.push({path: '/mathematics7th/quizBar'})
      },
      {
      title: "Build a Pie Chart",
      description: ['Collecting Data','Organization of Data','Plotting Data','Pictographs','Bar Graphs','. . .'],
      flex:6,
      icon: 'fas fa-lock',
      action: () => router.push({path: '/mathematics/pie'})
    },
    {
      title: "Build a Pictogram",
      description: ['Collecting Data','Organization of Data','Plotting Data','Pictographs','Bar Graphs','. . .'],
      flex:12,
      icon: 'fas fa-lock',
      action: () => router.push({path: '/mathematics/pic'})
    }
    ]
  },
  {name:'Quadrilaterals I',
  lock:true,
    img:"/assets/quad.svg",
    data:[
      {
        title: "Polygons",
        description: ['What is a Polygon?','Types of Polygons', 'Convex and Concave Polygons', '. . .'],
        flex:12,
        icon: 'fas fa-lock',
        action: () => router.push({path: '/comingsoon'})
      },
      {
        title: "Features of Polygons",
        description: ['Diagonal','Interior and Exterior Angles','Angle Sum Property','. . .'],
        flex:6,
        icon: 'fas fa-lock',
        action: () => router.push({path: '/comingsoon'})
      },
      {
        title: "Sum of Interior Angles of a Polygon",
        description: ['Diagonal','Interior and Exterior Angles','Angle Sum Property','. . .'],
        flex:6,
        icon: 'fas fa-lock',
        action: () => router.push({path: '/comingsoon'})
      },
      {
        title: "Kinds of Quadrilaterals",
        description: ['Trapezium', 'Rhombus', 'Rectangle', 'Square', '. . .'],
        flex:12,
        icon: 'fas fa-lock',
        action: () => router.push({path: '/comingsoon'})
      },
        ]
  },
  {name:'Quadrilaterals II',
  lock:true,
    img:"/assets/quad.svg",
    data:[
      {
        title: "Sum of Interior Angles of a Quadrilateral",
        subtitle:"(Concepts)",
        flex:12,
        icon: 'fas fa-lock',
        action: () => router.push({path: '/comingsoon'})
      },
      {
        title: "Sum of Interior Angles of a Quadrilateral",
        subtitle:"(Quiz)",
        flex:6,
        icon: 'fas fa-lock',
        action: () => router.push({path: '/comingsoon'})
      },
      {
        title: "Sum of Exterior Angles of a Quadrilateral",
        subtitle:"(Concepts)",
        flex:6,
        icon: 'fas fa-lock',
        action: () => router.push({path: '/comingsoon'})
      },
      {
        title: "Sum of Exterior Angles of a Quadrilateral",
        subtitle:"(Quiz)",
        flex:12,
        icon: 'fas fa-lock',
        action: () => router.push({path: '/comingsoon'})
      },
        ]
  },
  {name:'Mensuration',
  lock:true,
    img:"/assets/mensuration.svg",
    data:[
      {
        title: "Area of Some Common Shapes",
        description: ['What is a Polygon?','Types of Polygons', 'Convex and Concave Polygons', '. . .'],
        flex:6,
        icon: 'fas fa-lock',
        action: () => router.push({path: '/comingsoon'})
      },
      {
        title: "Area of a General Quadrilateral",
        description: ['Diagonal','Interior and Exterior Angles','Angle Sum Property','. . .'],
        flex:6,
        icon: 'fas fa-lock',
        action: () => router.push({path: '/comingsoon'})
      },
      {
        title: "Area of a Polygon",
        description: ['Trapezium', 'Rhombus', 'Rectangle', 'Square', '. . .'],
        flex:12,
        icon: 'fas fa-lock',
        action: () => router.push({path: '/comingsoon'})
      },
        ]
  },
  {name:'3D Geometry',
    lock:true,
    img:"/assets/shapes.svg",
    data:[
      {
        title: "Views of 3D Shapes",
        description: ['Rational & Irrational Numbers','Examples of Rational Numbers','. . .'],
        flex:12,
        icon: 'fas fa-lock',
        action: () => router.push({path: '/mathematics/3dshapes'})
      },
      {
        title: "Facets of a Cube",
        description: ['Rational & Irrational Numbers','Examples of Rational Numbers','. . .'],
        flex:12,
        icon: 'fas fa-lock',
        action: () => router.push({path: '/mathematics9th/demoSurface'})
      }
    ]
    }
  ]
export default Math8th;
////////////////////////////END OF FILE /////////////////////////////////////////
