////////////////////////////6TH GRADE MATH TOPICS////////////////////////////////
import router from '../../../router'
const Math9th =[
  {name:'Coordinate Geometry', lock:false,  img:"/assets/Cartesians.svg", data:[
    {
      title: "Coordinate System",
      description: ['Cartesian Plane','Coordinate of a Point','Quadrants','Plotting a Point on Cartesian Plane','. . .'],
      flex:12,
      icon: 'fas fa-lock-open',
      action: () => router.push({path:'/mathematics9th/coord'})
    },
    {
      title: "Plot Points",
      subtitle:"(Concepts)",
      description: ['Cartesian Plane','Coordinate of a Point','Quadrants','Plotting a Point on Cartesian Plane','. . .'],
      flex:6,
      icon: 'fas fa-lock-open',
      action: () => router.push({path:'/mathematics9th/coord/lesson-3'})
    },
    {
      title: "Plot Points",
      subtitle:"(Quiz)",
      description: ['Cartesian Plane','Coordinate of a Point','Quadrants','Plotting a Point on Cartesian Plane','. . .'],
      flex:6,
      icon: 'fas fa-lock-open',
      action: () => router.push({path:'/mathematics9th/point'})
    },
    {
      title: "Find Coordinates",
      description: ['Cartesian Plane','Coordinate of a Point','Quadrants','Plotting a Point on Cartesian Plane','. . .'],
      flex:12,
      icon: 'fas fa-lock-open',
      action: () => router.push({path:'/mathematics9th/coord/lesson-2'})
    }
  ]},
  {name:'Polynomials', lock:true, img:"/assets/alg.svg", data:[
    {
      title: "Polynomials",
      description: ['Order of a Polynomial','Coefficients of a Polynomial','Zeros of a Polynomials','Remainder Theorem','. . .'],
      flex:12,
      icon: 'fas fa-lock',
      action: () => router.push({path:'/mathematics/polynomials'})
    },
    {
      title: "Coefficients of a Polynomial",
      description: ['Linear Equations in Two Variables','Plotting Equations','Solving Equations','Types of Solutions','. . .'],
      flex:6,
      icon: 'fas fa-lock',
      action: () => router.push({path: '/mathematics/polynomials/coefficients'})
    },
    {
      title: "Zeros of a Polynomial",
      description: ['Linear Equations in Two Variables','Plotting Equations','Solving Equations','Types of Solutions','. . .'],
      flex:6,
      icon: 'fas fa-lock',
      action: () => router.push({path: '/mathematics/polynomials/coefficients'})
    },
    {
      title: "Order of a Polynomial",
      description: ['Order of a Polynomial','Coefficients of a Polynomial','Zeros of a Polynomials','Remainder Theorem','. . .'],
      flex:12,
      icon: 'fas fa-lock',
      action: () => router.push({path:'/mathematics/polynomials/order'})
    },
  ]},
  {name:'Geometric Measurement', lock:true, img:"/assets/mensuration.svg",  data:[
    {
      title: "Surface Area and Volume of Solids",
      description: ['Cube','Cuboid', 'Sphere', 'Cone','. . .' ],
      icon: 'fas fa-lock',
      flex:12,
      action: () => router.push({path: '/comingsoon'})
    },
    {
      title: "Area",
      description: ['Area of a Triangle: Heron\'s Formula','Area of a Quadrilateral','. . .'],
      icon: 'fas fa-lock',
      flex:6,
      action: () => router.push({path: '/comingsoon'})
    },
    {
      title: "Surface Area of a Cube",
      subtitle:"(Concepts)",
      icon: 'fas fa-lock',
      flex:6,
      action: () => router.push({path: '/mathematics9th/demoSurface'})
    },
    {
      title: "Surface Area of a Cuboid",
      subtitle:"(Concepts)",
      icon: 'fas fa-lock',
      flex:12,
      action: () => router.push({path: '/mathematics9th/demoSurface'})
    },
  ]},
  {name:'System of Linear Equations',
    lock:true,
    img:"/assets/intersect.svg",
    data:[
      {
        title: "Number of Solutions",
        description: ['Algebraic Expressions','Types of Equations','Examples of Equations','. . .'],
        flex:6,
        icon: 'fas fa-lock',
        action: () => router.push({path:'/mathematics8th/quizSLE'})
      },
      {
        title: "Solving Linear Equations",
        description: ['Solution of an Equation','Equation with Variable on One Side','Equation with Variable on Both Sides','. . .'],
        flex:6,
        icon: 'fas fa-lock',
        action: () => router.push({path:'/mathematics9th/demoSLE'})
      },
      {
        title: "Applications of Linear Equations",
        description: ['Linear Equations in Daily Life','Word Problems','. . .'],
        flex:12,
        icon: 'fas fa-lock',
        action: () => router.push({path:'/mathematics9th/demoSLE'})
      },
        ]
  },
  {name:'Parallel Lines I',
    lock:true,
    img:"/assets/lines.svg",
    data:[
      {
        title: "Parallel Lines",
        description: ['Rational & Irrational Numbers','Examples of Rational Numbers','. . .'],
        flex:6,
        icon: 'fas fa-lock',
        action: () => router.push({path: '/mathematics8th/demoParallel'})
      },
      {
        title: "Pair of Angles",
        description: ['Additivity','Associativity','Commutivity','. . .'],
        flex:6,
        icon: 'fas fa-lock',
        action: () => router.push({path: '/mathematics8th/demoAngles'})
      },
      {
        title: "Parallel Lines",
        subtitle:"(Quiz)",
        description: ['Rational & Irrational Numbers','Examples of Rational Numbers','. . .'],
        flex:12,
        icon: 'fas fa-lock',
        action: () => router.push({path: '/mathematics8th/quizParallel'})
      },
        ]
  },
  {name:'Parallel Lines II',
    lock:true,
    img:"/assets/lines.svg",
    data:[
      {
        title: "Angles made by a Transversal",
        subtitle:"(Concepts)",
        flex:12,
        icon: 'fas fa-lock',
        action: () => router.push({path: '/mathematics9th/demoTransversal'})
      },
      {
        title: "Angles made by a Transversal",
        subtitle:"(Quiz)",
        flex:12,
        icon: 'fas fa-lock',
        action: () => router.push({path: '/mathematics8th/quizTransversal'})
      }
        ]
  },

  {name:'Exponents', lock:true, img:"/assets/monomial.svg", data:[
   {
     title: "Law of Exponents",
     subtitle:"(Concepts)",
     description: ['Natural Numbers','Integers','Rational Numbers', 'Number Line','. . .'],
     flex:6,
     icon: 'fas fa-lock',
     action: () => router.push({path: '/mathematics/exponent'})
   },
   {
     title: "Exponents",
     subtitle:"(Calculator)",
     description: ['Fractions', 'Fractions on a Number Line','Adding & Subtracting Fractions','Proper & Improper Fractions','. . .'],
     icon: 'fas fa-lock',
     flex:6,
     action: () => router.push({path: '/comingsoon'})
   },
   {
     title: "Exponents",
     subtitle:"(Quiz)",
     description: ['Decimals: Tenths & Hundreths','Decimals on a Number Line','Adding Decimals','Subtracting Decimals','. . .'],
     flex:12,
     icon: 'fas fa-lock',
     action: () => router.push({path: '/comingsoon'})
   },
  ]},
 {name:'Statistics', lock:true, img:"/assets/statistics.svg", data:[
  {
    title: "Finding Average",
    subtitle:"(Concepts)",
    flex:12,
    icon: 'fas fa-lock',
    action: () => router.push({path: '/mathematics9th/demoMean'})
  },
  {
    title: "Finding Average",
    subtitle:"(Practice)",
    icon: 'fas fa-lock',
    flex:6,
    action: () => router.push({path: '/mathematics9th/practiceMean'})
  },
  {
    title: "Finding Average",
    subtitle:"(Quiz)",
    flex:6,
    icon: 'fas fa-lock',
    action: () => router.push({path: '/mathematics9th/quizMean'})
  },
  {
    title: "Finding Median",
    subtitle:"(Concepts)",
    flex:12,
    icon: 'fas fa-lock',
    action: () => router.push({path: '/mathematics9th/demoMedian'})
  },
 ]},
{name:'Area of a Triangle', lock:true, img:"/assets/areaTri.svg", data:[
  {
    title: "Area of a Triangle",
    subtitle:"(Concepts)",
    description: ['Median of a Triangle','Altitude of a Triangle','Centroid','Types of Triangles based on Sides','Types of Triangles based on Angles','. . .'],
    icon: 'fas fa-lock',
    flex:12,
    action: () => router.push({path: '/mathematics6th/practiceTriangle'})
  },
  {
    title: "Heron's Formula",
    subtitle:"(Concepts)",
    description: ['Complementary Angles','Supplementary Angles','Adjacent Angles','Angles made by a Transversal','. . .'],
    flex:6,
    icon: 'fas fa-lock',
    action: () => router.push({path: '/mathematics6th/quizTriangle'})
  },
  {
    title: "Heron's Formula",
    subtitle:"(Practice)",
    description: ['Introduction','Definition of Congruence','Congruence of Angles','Congruence of Triangles','. . .'],
    icon: 'fas fa-lock',
    flex:6,
    action: () => router.push({path:'/mathematics6th/quizTriangle'})
  },
  {
    title: "Heron's Formula",
    subtitle:"(Quiz)",
    description: ['Introduction','Definition of Congruence','Congruence of Angles','Congruence of Triangles','. . .'],
    icon: 'fas fa-lock',
    flex:12,
    action: () => router.push({path:'/mathematics6th/quizTriangle'})
  }
]},
{name:'Quadrilaterals', lock:true, img:"/assets/quad.svg", data:[
  {
    title: "Types of Quadrilaterals",
    subtitle:"(Concepts)",
    description: ['Median of a Triangle','Altitude of a Triangle','Centroid','Types of Triangles based on Sides','Types of Triangles based on Angles','. . .'],
    icon: 'fas fa-lock',
    flex:12,
    action: () => router.push({path: '/mathematics/9th/triangles'})
  },
  {
    title: "Angle Sum Property",
    subtitle:"(Concepts)",
    description: ['Complementary Angles','Supplementary Angles','Adjacent Angles','Angles made by a Transversal','. . .'],
    flex:6,
    icon: 'fas fa-lock',
    action: () => router.push({path: '/mathematics/angles'})
  },
  {
    title: "Parallelograms",
    subtitle:"(Practice)",
    description: ['Introduction','Definition of Congruence','Congruence of Angles','Congruence of Triangles','. . .'],
    icon: 'fas fa-lock',
    flex:6,
    action: () => router.push({path:'/comingsoon'})
  },
  {
    title: "Properties of Parallelograms",
    subtitle:"(Quiz)",
    description: ['Introduction','Definition of Congruence','Congruence of Angles','Congruence of Triangles','. . .'],
    icon: 'fas fa-lock',
    flex:12,
    action: () => router.push({path:'/comingsoon'})
  }
]},
{name:'Triangles', lock:true,
  img:"/assets/triangle.svg",
  data:[
  {
    title: "Congruence of Triangles",
    subtitle:"(Concepts)",
    description: ['Collecting Data','Organization of Data','Plotting Data','Pictographs','Bar Graphs','. . .'],
    icon: 'fas fa-lock',
    flex:12,
    action: () => router.push({path: '/mathematics9th/demoCongruence'})
  },
    {
      title: "SAS Rule of Congruence",
      subtitle:"(Practice)",
      description: ['What is a Pictograph?','Pictograh Examples', 'Drawing a Pictograph', '. . .'],
      flex:6,
      icon: 'fas fa-lock',
      action: () => router.push({path: '/mathematics9th/demoCongruence'})
    },
    {
      title: "ASA Rule of Congruence",
      subtitle:"(Practice)",
      description: ['What is a Bar Chart?','Bar Chart Examples','Drawing a Bar Chart','. . .'],
      flex:6,
      icon: 'fas fa-lock',
      action: () => router.push({path: '/mathematics9th/demoCongruence'})
    },
    {
      title: "SSS Rule of Congruence",
      subtitle:"(Practice)",
      description: ['What is a Pie Chart?', 'Example of Pie Charts', 'Drawing a Pie Chart','. . .'],
      flex:12,
      icon: 'fas fa-lock',
      action: () => router.push({path: '/mathematics9th/demoCongruence'})
    },
      ]
},
{name:'Data Handling', lock:true,
  img:"/assets/data-handling.svg",
  data:[
  {
    title: "Types of Data Visualization",
    subtitle:"(Concepts)",
    description: ['Collecting Data','Organization of Data','Plotting Data','Pictographs','Bar Graphs','. . .'],
    icon: 'fas fa-lock',
    flex:12,
    action: () => router.push({path: '/mathematics6th/data'})
  },
    {
      title: "Pictographs",
      subtitle:"(Practice)",
      description: ['What is a Pictograph?','Pictograh Examples', 'Drawing a Pictograph', '. . .'],
      flex:6,
      icon: 'fas fa-lock',
      action: () => router.push({path: '/mathematics/pic'})
    },
    {
      title: "Bar Charts",
      subtitle:"(Practice)",
      description: ['What is a Bar Chart?','Bar Chart Examples','Drawing a Bar Chart','. . .'],
      flex:6,
      icon: 'fas fa-lock',
      action: () => router.push({path: '/mathematics/bar'})
    },
    {
      title: "Pie Charts",
      subtitle:"(Practice)",
      description: ['What is a Pie Chart?', 'Example of Pie Charts', 'Drawing a Pie Chart','. . .'],
      flex:12,
      icon: 'fas fa-lock',
      action: () => router.push({path: '/mathematics/pie'})
    },
      ]
},
{name:'Lines & Angles', lock:true, img:"/assets/geometry.svg", data:[
{
  title: "Measuring Angle",
  subtitle:"(Practice)",
  description: ['Event & Outcomes','Probability of an Event','Sum of Probabilities','. . .'],
  flex:12,
  icon: 'fas fa-lock',
  action: () => router.push({path: '/mathematics9th/geometric'})
},
{
  title: "Complementary Angles",
  subtitle:"(Practice)",
  description: ['Event & Outcomes','Probability of an Event','Sum of Probabilities','. . .'],
  flex:6,
  icon: 'fas fa-lock',
  action: () => router.push({path: '/mathematics9th/angles'})
},
{
  title: "Supplementary Angles",
  subtitle:"(Practice)",
  description: ['Event & Outcomes','Probability of an Event','Sum of Probabilities','. . .'],
  flex:6,
  icon: 'fas fa-lock',
  action: () => router.push({path: '/mathematics9th/angles'})
},
{
  title: "Pairs of Angles",
  subtitle:"(Quiz)",
  description: ['Event & Outcomes','Probability of an Event','Sum of Probabilities','. . .'],
  flex:12,
  icon: 'fas fa-lock',
  action: () => router.push({path: '/mathematics9th/angles'})
},
]}
,
{name:'Probability', lock:true, img:"/assets/probs.svg", data:[
{
  title: "Probability of an Event",
  subtitle:"(Concepts)",
  flex:12,
  icon: 'fas fa-lock',
  action: () => router.push({path: '/mathematics12th/demoProb'})
},
{
  title: "Probability Theory",
  subtitle:"(Practice)",
  flex:6,
  icon: 'fas fa-lock',
  action: () => router.push({path: '/mathematics9th/prob'})
},
{
  title: "Finding Probability",
  subtitle:"(Practice)",
  flex:6,
  icon: 'fas fa-lock',
  action: () => router.push({path: '/mathematics/prob2'})
},
{
  title: "Finding Probability",
  subtitle:"(Quiz)",
  flex:12,
  icon: 'fas fa-lock',
  action: () => router.push({path: '/mathematics/probability'})
},

]},
  ]
export default Math9th;
////////////////////////////END OF FILE /////////////////////////////////////////
