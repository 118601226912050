////////////////////////////12TH GRADE MATH TOPICS////////////////////////////////
const Math12th = [
  {
    path: '/mathematics/pie',
        name: 'pei1',
        component: () => import('../data/PieChart/Lesson.vue')
  },
  {
    path: '/mathematics/pic',
        name: 'pei2',
        component: () => import('../data/Pictogram/Lesson.vue')
  },
  {
    path: '/mathematics/prob',
    name: 'Probability Calculator',
    component: () => import('./probt/Lesson.vue')
  },
  {
    path: '/mathematics/prob2',
    name: 'Find Probability',
    component: () => import('./probt2/Lesson.vue')
  },
  {
    path: '/mathematics12th/prob',
    name: 'Find Probability (12th)',
    component: () => import('./Prob/Lesson.vue')
  },
  {
    path: '/mathematics/probability',
    name: 'Introduction to Probability',
    component: () => import('../findProb/Lesson.vue')
  },
  {
    path: '/mathematics/12th/Derivatives',
    name: 'Maxima & Minima',
    component: () => import('../derivatives/maxima/Lesson.vue')
  },
  {
    path: '/mathematics/matrices',
    name: 'Matrices',
    component: () => import('../matrices/Lesson.vue')
  },
  {
    path: '/mathematics/matrices/components',
    name: 'Matrices Components',
    component: () => import('../matrices/components/Components.vue')
  },
  {
    path: '/mathematics/matrices/addition',
    name: 'Matrix Operations: Addition',
    component: () => import('../matrices/addition/Addition.vue')
  },
  {
    path: '/mathematics/matrices/multiplication',
    name: 'Matrix Operations: Multiplication',
    component: () => import('../matrices/multiplication/Multiplication.vue')
  },
  {
      path: '/mathematics/statistics',
      name: 'Statistics',
      component: () => import('../statistics/Lesson.vue')
  },
  {
    path: '/mathematics/12th/Integral',
    name: 'Definite Integrals',
    component: () => import('../integral/Lesson.vue')
  },
  {
      path: '/mathematics/AorB',
      name: 'A or B',
      component: () => import('./AorB/Lesson.vue')
  },
  ////////////////////////Functions/////////////////////////////
  {
      path: '/mathematics12th/functions1',
      name: 'functions',
      component: () => import('./Functions/Lesson.vue')
  },
  {
      path: '/mathematics12th/mappings',
      name: 'mappings',
      component: () => import('./Mappings/Lesson.vue')
  },
  {
      path: '/mathematics12th/tables',
      name: 'tables',
      component: () => import('./Tables/Lesson.vue')
  },
  {
      path: '/mathematics12th/quizDerivative',
      name: 'quizDerivative',
      component: () => import('./quizDerivative/Lesson.vue')
  },
  {
      path: '/mathematics12th/quizProb',
      name: 'quizDerivative',
      component: () => import('./quizProb/Lesson.vue')
  },
  {
      path: '/mathematics12th/quizMVT',
      name: 'quizMVT',
      component: () => import('./quizMVT/Lesson.vue')
  },
  {
      path: '/mathematics12th/AandB',
      name: 'AandB',
      component: () => import('./AandB/Lesson.vue')
  },
  {
      path: '/mathematics12th/conditionalProb',
      name: 'BuponA',
      component: () => import('./BuponA/Lesson.vue')
  },
  {
      path: '/mathematics12th/averageValue',
      name: 'average value of a function',
      component: () => import('./demoAvg/Lesson.vue')
  },
  {
    path: '/mathematics12th/mvt',
    name: 'Mean Value Theorem',
    component: () => import('./demoMVT/MeanValueTheorem.vue')
  }
    ]
export default Math12th;
////////////////////////////END OF FILE /////////////////////////////////////////
