import router from '../router';
///////////////////////////MATHEMATICS/////////////////////////////////////////////////////////
import Math6th from  '../components/mathematics/Math6thGrade/Math6thGrade.js'
import Math7th from  '../components/mathematics/Math7thGrade/Math7thGrade.js'
import Math8th from  '../components/mathematics/Math8thGrade/Math8thGrade.js'
import Math9th from  '../components/mathematics/Math9thGrade/Math9thGrade.js'
import Math10th from '../components/mathematics/Math10thGrade/Math10thGrade.js'
import Math11th from '../components/mathematics/Math11thGrade/Math11thGrade.js'
import Math12th from '../components/mathematics/Math12thGrade/Math12thGrade.js'
///////////////////////////////////////////////////////Physics/////////////////////////////////////
import Phys6th from './Phys6thGrade.js'
import Phys7th from './Phys7thGrade.js'
import Phys8th from './Phys8thGrade.js'
import Phys9th from './Phys9thGrade.js'
import Phys10th from './Phys10thGrade.js'
import Phys11th from './Phys11thGrade.js'
import Phys12th from './Phys12thGrade.js'
///////////////////////////////////////////////////////Advanced Topics ///////////////////////////////
import techTopics from './Tech.js'
///////////////////////////////////////////////////////Chemistry///////////////////////////////////////
import Chem6th from './Chem6thGrade.js'
import Chem7th from './Chem7thGrade.js'
import Chem8th from './Chem8thGrade.js'
import Chem9th from './Chem9thGrade.js'
import Chem10th from './Chem10thGrade.js'
import Chem11th from './Chem11thGrade.js'
import Chem12th from './Chem12thGrade.js'
/////////////////////////////////////////////////////////////////////////////////////////////////
import Subjects from './Topics.js'
///////////////////////////////////////////////////////PROGRAMMING ////////////////////////////////////
import Prog from './Programming.js'
////////////////////////////////////////////////////////////////////////////////////////////////////
/*const getCategories = async () => {
  const response = await axios.get(process.env.VUE_APP_API+'categories');
  // console.log("res123::",response);
  if(response.data.success){
    let cats = response.data.data.categories
    for(let i in cats){
      cats[i].isBlack = true;
      cats[i].description ="Topics in High School Physics";
      cats[i].action = () => router.push({path: cats[i].link+'?id='+cats[i].id})
      if(cats[i].title== "Physics"){
        cats[i].img = '/assets/masswhite.svg';
      }
      if(cats[i].title== "Chemistry"){
        cats[i].img = '/assets/proton.svg';
      }
      if(cats[i].title== "Mathematics"){
        cats[i].img = '/assets/algebraII.svg';
      }
      if(cats[i].title== "Watch a Demo"){
        cats[i].img = '/assets/demos.svg';
      }
      if(cats[i].title== "Help & Support"){
        cats[i].img = '/assets/customerSupport.svg';
      }
    }
    return cats;
  }
}*/
/////////////////////////////////////BELOW GIVES THE DEFAULT STATE OF THE SYSTEM ////////////////////////
const getDefaultState = ()=> {
  return {
    titles: {
      main: "Visual + Interactive",
      slogan: "Content that clicks. Concepts that stick.",
      menu: "Explore Edliy!",
    },
    showLeftArrow: false,
    showhome: true,
    showPhys: false,
    showChem: false,
    showMath: false,
    showEng: false,
    showTeam: false,
    showGraph:false,
    showSwing:false,
    showProton:false,
    showBrain:false,
    showSample:false,
//Only to be updated in purchaseHistory.vue
    showCart:false,
// Only to be updated in Team.vue
    showContact:false,
// Only to be updated in Contact.vue
    showFind:false,
// Only to be updated in Finder.vue
    showIP:false,
// Only to be updated in IP.vue
    showModule:false,
//Page Not Found////
    showPNP:false,
// Course info
    showEnrollmentAdvert:false,
// Shown in the menu,
    registerRole: false,
    userDashboard: false,
    price: null,
    newPrice:null,
    discount: null,
    courseStatus: null,
    titleStatus: true,
    subscriptionId: null,
    subscriptionEnd: null,
    loginModalValue: false,
// To be updated in Lesson.vue
    navItemsGroup: [],
    rightNavItems: [
   {
     title: "Learning Dashboard",
     img: "/assets/dash.svg",
     action: () => router.push({path: '/dashboard'})
   },
   {
     title: "Help & Support",
     img: "/assets/support.svg",
     action: () => router.push({path: '/contact'})
   },
   {
     title: "Purchase History",
     img: "/assets/purchase.svg",
     action: ()=> router.push({path: '/purchaseHistory'})
   },
   {
     title: "Settings",
     img: "/assets/settings.svg",
     action: ()=> router.push({path: '/settings'})
   },
 ],
 //////////////////////////////////////////////////////////MATH ITEMS //////////////////////////////////////////////////////////////////
    mathematics6th:[
      ...Math6th
    ],
    mathematics7th:[
      ...Math7th
    ],
    mathematics8th:[
      ...Math8th
    ],
    mathematics9th:[
      ...Math9th
    ],
    mathematics10th:[
      ...Math10th
    ],
    mathematics11th:[
      ...Math11th
    ],
    mathematics12th:[
      ...Math12th
    ],
/////////////////////////////////////////////Phy Topics /////////////////////////////////////////////
    physics6th:[
      ...Phys6th
    ],
    physics7th:[
      ...Phys7th
    ],
    physics8th:[
      ...Phys8th
    ],
    physics9th:[
      ...Phys9th
    ],
    physics10th:[
      ...Phys10th
    ],
    physics11th:[
      ...Phys11th
    ],
    physics12th:[
      ...Phys12th
    ],
//////////////////////////////////////NAV BAR ITEMS//////////////////////////////////////////////////////
   topics:[
      ...Subjects
    ],
  //  topics: await getCategories(),
    subjects: [
      ...techTopics
    ],
//////////////////////////////////////CHEMISTRY TOPICS ////////////////////////////////////////////////////
    chemistry6th:[
      ...Chem6th
    ],
    chemistry7th:[
      ...Chem7th
    ],
    chemistry8th:[
      ...Chem8th
    ],
    chemistry9th:[
        ...Chem9th
    ],
    chemistry10th:[
        ...Chem10th
    ],
    chemistry11th:[
        ...Chem11th
    ],
    chemistry12th:[
        ...Chem12th
    ],
//////////////////////////////////////////////////////////////////PROGRAMMING////////////////////////////////////////////////////////////////////////
    programming:[
      ...Prog
    ]
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  }
}
const state = getDefaultState();
export const navigation = {
  namespaced: true,
  state: state,
  getters: {
    navItemsGroup: state => state.navItemsGroup,
    navItems: state => state.navItems,
    titles: state => state.titles,
    title: state => state.titles.title,
    menu: state => state.titles.menu,
    subjects: state => state.subjects,
    topics: state => state.topics,
    engineering: state => state.subjects,
    programming: state => state.programming,
  //  physics: state => state.physics,
    physics6th: state => state.physics6th,
    physics7th: state => state.physics7th,
    physics8th: state => state.physics8th,
    physics9th: state => state.physics9th,
    physics10th: state => state.physics10th,
    physics11th: state => state.physics11th,
    physics12th: state => state.physics12th,
  //  chemistry: state => state.chemistry,
    chemistry6th: state => state.chemistry6th,
    chemistry7th: state => state.chemistry7th,
    chemistry8th: state => state.chemistry8th,
    chemistry9th: state => state.chemistry9th,
    chemistry10th: state => state.chemistry10th,
    chemistry11th: state => state.chemistry11th,
    chemistry12th: state => state.chemistry12th,
  //  mathematics: state => state.mathematics,
    mathematics6th: state => state.mathematics6th,
    mathematics7th: state => state.mathematics7th,
    mathematics8th: state => state.mathematics8th,
    mathematics9th: state => state.mathematics9th,
    mathematics10th: state => state.mathematics10th,
    mathematics11th: state => state.mathematics11th,
    mathematics12th: state => state.mathematics12th,
    //biology: state => state.biology,
    showLeftArrow: state => state.showLeftArrow,
    showhome: state => state.showhome,
    showPhys: state => state.showPhys,
    showMath: state => state.showMath,
    showChem: state => state.showChem,
    showEng: state => state.showEng,
    showTeam:state => state.showTeam,
    showContact:state => state.showContact,
    showFind:state => state.showFind,
    showIP:state => state.showIP,
    showModule:state =>state.showModule,
    showGraph:state =>state.showGraph,
    showSwing:state =>state.showSwing,
    showBrain:state =>state.showBrain,
    showProton:state =>state.showProton,
    showPNP:state =>state.showPNP,
    userDashboard: state => state.userDashboard,
    price: state => state.price,
    newPrice: state =>state.newPrice,
    discount: state => state.discount,
    courseStatus: state => state.courseStatus,
    subscriptionId: state => state.subscriptionId,
    subscriptionEnd: state => state.subscriptionEnd,
    titleStatus: state => state.titleStatus,
    loginModalValue: state => state.loginModalValue,
    //topics for Navigation Drawer
    navItems: state => state.topics,
    //navItems:state =>state.leftNavItems,
    rightNavItems: state => state.rightNavItems,
    showEnrollmentAdvert: state=>state.showEnrollmentAdvert,
    showCart: state=>state.showCart,
    registerRole: state => state.registerRole,
    showSample: state=>state.showSample
  },
  ////////MUTATIONS CANNOT BE ASYNC FUNCTIONS. THEY ALWAYS HAVE TO BE SYNCHRONOUS.
  mutations: {
     resetState (state) {
     Object.assign(state, getDefaultState());
     },
  /*  resetState (state, payload) {
      Object.assign(state, payload);
    },*/
    deleteItemGroup (state, title) {
      state.navItemsGroup.filter(item => item.title !== title);
    },
    replaceItemGroup (state, newItemsGroup) {
      state.navItemsGroup = newItemsGroup;
    },
    replaceTopics (state, newTopics) {
      state.topics = newTopics;
    },
    replaceProton (state, newProton) {
      state.showProton = newProton;
    },
    replaceGraph (state, newGraph) {
      state.showGraph = newGraph;
    },
    replaceSwing (state, newSwing) {
      state.showSwing = newSwing;
    },
    replaceBrain (state, newBrain) {
      state.showBrain = newBrain;
    },
    replacePhys (state, newPhys) {
      state.showPhys = newPhys;
    },
    replaceMath (state, newMath) {
      state.showMath = newMath;
    },
    replaceChem (state, newChem) {
      state.showChem = newChem;
    },
    replaceEng (state, newEng) {
      state.showEng = newEng;
    },
    replaceTeam (state, newTeam) {
      state.showTeam = newTeam;
    },
    replaceFind (state, newFind) {
      state.showFind = newFind;
    },
    replaceIP (state, newIP) {
      state.showIP = newIP;
    },
    replacePNP (state, newPNP) {
      state.showPNP = newPNP;
    },
    replaceContact (state, newContact) {
      state.showContact = newContact;
    },
    replaceModule (state, newModule) {
      state.showModule = newModule;
    },
    replaceLeftArrow (state, newLeftArrow) {
      state.showLeftArrow = newLeftArrow;
    },
    addItemGroup (state, item) {
      state.navItemsGroups.push(item);
    },
    changeTitle (state, newTitle) {
      state.titles.main = newTitle;
    },
    changeMenu (state, newMenu) {
      state.titles.menu = newMenu;
    },
    toggleshowhome (state, newshowhome) {
      state.showhome = newshowhome;
    },
    leftArrow (state, newBool) {
      state.showLeftArrow = newBool
    },
    registerRole (state, newReg) {
      state.registerRole = newReg;
      state.showhome = false;
    },
    userDashboard (state, newdash) {
      state.userDashboard = newdash;
      state.showhome = false;
    },
    changePrice (state, price) {
      state.price = price;
    },
    changenewPrice (state, newPrice) {
      state.newPrice = newPrice;
    },
    changeDiscount (state, discount) {
      state.discount = discount;
    },
    courseStatusInd (state, courseStatus) {
      state.courseStatus = courseStatus;
    },
    titleStatus (state, titleInd) {
      console.log("titleInd::", titleInd)
      state.titleStatus = titleInd;
    },
    appSubscriptionId (state, subscriptionId) {
      state.subscriptionId = subscriptionId;
    },
    appSubscriptionEnd (state, subscriptionEnd) {
      state.subscriptionEnd = subscriptionEnd;
    },
    loginModalValue (state, loginModal) {
      console.log("loginModal::", loginModal)
      state.loginModalValue = loginModal;
    },
    replaceEnroll (state, newEnroll) {
      state.showEnrollmentAdvert = newEnroll;
    },
    replaceCart (state, newCart) {
      state.showCart = newCart;
    },
    replaceSample (state, newCart) {
      state.showSample = newCart;
    },
  },
  // This call mutations but can be async
  actions: {
  /*async getData(context){
    const appData = await getDefaultState();
    context.commit("resetState", appData);
  } */
  }
};
