import router from '../router'
const Phys9th =[
  {name:'Mirrors', lock:false, img:"/assets/mirror.svg",  data:[
      {
        title: "Convex Mirrors",
        description: ['What is a Convex Mirror?', 'Image Formation by Convex Mirrors', 'Convex Mirror Formula', '...'],
        icon: 'fas fa-lock-open',
        isBlack: true,
        action: () => router.push({path: '/physics/convexmirrors'})
      },
      {
        title: "Concave Mirrors",
        description: ['What is a Concave Mirror?', 'Image Formation by Concave Mirrors', 'Concave Mirror Formula', '...'],
        icon: 'fas fa-lock-open',
        isBlack: false,
        action: () => router.push({path: '/physics/concavemirrors'})
      },
    ]
    },
/////////////////////////////////////////////////////////////////////////
{name:'Lenses', lock:true, img:"/assets/lens.svg",  data:[
    {
      title: "Convex Lens",
      description: ['What is a Lens?', 'Image Formation in a Lens', '...'],
      icon: 'fas fa-lock',
      isBlack: false,
      action: () => router.push({path: '/physics/lens'})
    },
    {
      title: "Concave Lens",
      description: ['What is a Lens?', 'Image Formation in a Lens', '...'],
      icon: 'fas fa-lock',
      isBlack: true,
      action: () => router.push({path: '/physics/lens'})
    }
  ]
  },
///////////////////////////////////////////////////////////////////////////////////////////////////
  {name:'Reflection of Light', lock:true, img:"/assets/reflect.svg",  data:[
      {
        title: "Units & Mesurements",
        description: ['System of Units', 'Fundamental Units', '...'],
        icon: 'fas fa-lock',
        isBlack: false,
        action: () => router.push({path: ''})
      }]
    },
    {name:'Refraction of Light', lock:true, img:"/assets/refraction.svg",  data:[
        {
          title: "Snell\'s Formula",
          description: ['System of Units', 'Fundamental Units', '...'],
          icon: 'fas fa-lock',
          isBlack: false,
          action: () => router.push({path: '/physics/snell'})
        },
        {
          title: "Light through a Glass Slab",
          description: ['System of Units', 'Fundamental Units', '...'],
          icon: 'fas fa-lock',
          isBlack: true,
          action: () => router.push({path: ''})
        }
      ]
      },
///////////////////////////////////////////////////////////////////////////////////////////////
          {name:'Human Eye & Colors', lock:true,  img:"/assets/eye-ball.svg",  data:[
              {
                title: "How Eye Works?",
                description: ['Anatomy of Human Eyes', 'How We See Things?', '...'],
                icon: 'fas fa-lock',
                isBlack: false,
                action: () => router.push({path: ''})
              }]
            }
]
export default Phys9th;
////////////////////////////END OF FILE /////////////////////////////////////////
