////////////////////////////11TH GRADE MATH TOPICS////////////////////////////////
const Math11th = [
  {
    path: '/mathematics/sets1',
        name: 'sets',
        component: () => import('../Math11thGrade/sets/Lesson.vue')
  },
  {
    path: '/mathematics/sets2',
        name: 'sets operation',
        component: () => import('../Math11thGrade/sets2/Lesson.vue')
  },
  {
    path: '/mathematics/sets3',
        name: 'sets union',
        component: () => import('../Math11thGrade/sets3/Lesson.vue')
  },
  {
    path: '/mathematics/ode',
        name: 'Linear ODE',
        component: () => import('../Math12thGrade/ode/Lesson.vue')
  },
  {
    path: '/mathematics/polarForm',
        name: 'Polar Form of a Complex Number',
        component: () => import('../complexNumber/Lesson.vue')
  },
  {
    path: '/mathematics/findProb',
        name: 'Calculating Probability',
        component: () => import('../findProb/Lesson.vue')
  },
  {
        path: '/mathematics/complexAddition',
        name: 'Addition of Complex Numbers',
        component: () => import('../complexAddition/Lesson.vue')
  },
  {
      path: '/mathematics/vectors',
      name: 'vectors2',
      component: () => import('../vectors/SubtopicMenu.vue')
    },
    {
        path: '/mathematics/vectorSum',
        name: 'vectorsSum',
        component: () => import('../Math11thGrade/vectorSum/Lesson.vue')
      },
    {
        path: '/mathematics/vectors/cartesian',
        name: 'Cartesian',
        component: () => import('../vectors/cartesian/Lesson.vue')
      },
    {
          path: '/mathematics/vectors/addition',
          name: 'Addition',
          component: () => import('../vectors/addition/Lesson.vue')
    },
    {
        path: '/mathematics/vectors/inner',
        name: 'Inner',
        component: () => import('../vectors/inner/Lesson.vue')
      },
    {
        path: '/mathematics/vectors/outer',
        name: 'Outer',
        component: () => import('../vectors/outer/Lesson.vue')
      },
      {
        path: '/mathematics/11th/arithmetic',
        name: 'arithmetic2',
        component: () => import('../arithmetic/Lesson.vue')
      },
      {
        path: '/mathematics11th/line1',
        name: 'Slope & Intercept of a Line',
        component: () => import('../Math11thGrade/line/Lesson.vue')
      },
      {
        path: '/mathematics11th/line2',
        name: 'Equation of a Line',
        component: () => import('../Math11thGrade/line2/Lesson.vue')
      },
      {
        path: '/mathematics11th/line3',
        name: 'Intersection of Lines',
        component: () => import('../Math11thGrade/line3/Lesson.vue')
      },
      {
        path: '/mathematics11th/line4',
        name: 'Intersection of Lines 2',
        component: () => import('../Math11thGrade/line4/Lesson.vue')
      },
      {
        path: '/mathematics11th/quizTangent',
        name: 'Quiz on Tangent',
        component: () => import('../Math11thGrade/quizTangent/Lesson.vue')
      },
      {
        path: '/mathematics11th/quizComplex',
        name: 'Quiz on Complex Number',
        component: () => import('../Math11thGrade/quizComplex/Lesson.vue')
      },
      {
        path: '/mathematics11th/quizMultiply',
        name: 'Quiz on Complex Number',
        component: () => import('../Math11thGrade/quizMultiply/Lesson.vue')
      },
      {
        path: '/mathematics11th/quizPoint',
        name: 'Quiz on Coordinates of a Point',
        component: () => import('../Math11thGrade/quizPoint/Lesson.vue')
      },
      {
        path: '/mathematics11th/quizMid',
        name: 'Midpoint of Two Points',
        component: () => import('../Math11thGrade/quizMid/Lesson.vue')
      },
      {
        path: '/mathematics11th/quizSection',
        name: 'Section Formula',
        component: () => import('../Math11thGrade/quizSection/Lesson.vue')
      },
      {
        path: '/mathematics11th/distance',
        name: 'Distance between Two Points',
        component: () => import('../Math10thGrade/distance/Lesson.vue')
      },
      {
        path: '/mathematics11th/quizConic',
        name: 'ConicSection',
        component: () => import('../Math11thGrade/quizConic/Lesson.vue')
      },
      {
          path: '/mathematics11th/conic',
          name: 'Conic',
          component: () => import('../cos/ConicSection.vue')
      },
      {
        path: '/mathematics11th/quizParabola',
        name: 'Parabola',
        component: () => import('../Math11thGrade/quizParabola/Lesson.vue')
      },
      {
        path: '/mathematics11th/quizFocal',
        name: 'Parabola',
        component: () => import('../Math11thGrade/quizFocal/Lesson.vue')
      },
      {
        path: '/mathematics11th/demoMaxima',
        name: 'Maxima',
        component: () => import('../Math11thGrade/demoMaxima/Lesson.vue')
      },
      {
        path: '/mathematics11th/demoParabola',
        name: 'Parabola',
        component: () => import('../Math11thGrade/demoParabola/Lesson.vue')
      },
      {
        path: '/mathematics11th/quizSets',
        name: 'Venn Diagram',
        component: () => import('../Math11thGrade/quizSets/Lesson.vue')
      },
      {
        path: '/mathematics11th/demoTrigWatch',
        name: 'Trigonometric Watch',
        component: () => import('../Math11thGrade/demoTrigWatch/Lesson.vue')
      },
    ]
export default Math11th;
////////////////////////////END OF FILE /////////////////////////////////////////
