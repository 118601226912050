////////////////////////////6TH GRADE MATH TOPICS////////////////////////////////
import router from '../../../router'
const Math10th =[
  {name:'Circles & Angles', lock:false, img:"/assets/circleAngle.svg",
   data:[
    {
      title: "Inscribed Angle ",
      description: ["What is Inscribed Angle?", "Inscribed Angle Theorem", "Corollary of Inscribed Angle Theorem", "..."],
      icon: 'fas fa-lock-open',
      flex:12,
      action: () => router.push({path:'/mathematics/iat'})
    }
    ,
    {
      title: "Thales Theorem",
      description: ["Interior Angles", "Triangle Sum Theorem", "Applications", "..."],
      icon: 'fas fa-lock-open',
      flex:6,
      action: () => router.push({path:'/mathematics/tt'})
    },
    {
      title: "Interior Angle Theorem",
      subtitle:"(Quiz)",
      description: ["Interior Angles", "Triangle Sum Theorem", "Applications", "..."],
      icon: 'fas fa-lock-open',
      flex:6,
      action: () => router.push({path:'/mathematics10th/quizThales'})
    },
    {
      title: "Thales Theorem",
      subtitle:"(Quiz)",
      description: ["Interior Angles", "Triangle Sum Theorem", "Applications", "..."],
      icon: 'fas fa-lock-open',
      flex:12,
      action: () => router.push({path:'/mathematics10th/quizTriangle'})
    },
  ]},
  {name:'Coordinate Geometry', lock:true, img:"/assets/Cartesians.svg", data:[
    {
      title: "Points & Distances",
      description: ['Coordinates of a Point','Distance Formula','Section Formula', 'Area of a Triangle','. . .'],
      flex:12,
      icon: 'fas fa-lock',
      action: () => router.push({path:'/mathematics10th/distance'})
    },
    {
      title: "Section Formula",
      description: ['Length of a Line Segment','Section Formula', 'Area of a Triangle','. . .'],
      flex:6,
      icon: 'fas fa-lock',
      action: () => router.push({path:'/mathematics/coord'})
    },
    {
      title: "Area of a Triangle",
      subtitle:"(Concepts)",
      flex:6,
      icon: 'fas fa-lock',
      action: () => router.push({path:'/mathematics/coord'})
    },
    {
      title: "Area of a Triangle",
      subtitle:"(Quiz)",
      flex:12,
      icon: 'fas fa-lock',
      action: () => router.push({path:'/mathematics/coord'})
    },
  ]},
  {name:'Similar Triangles',lock:true, img:"/assets/similarTriangle.svg", data:[
    {
      title: "Similarity of Triangles",
      description: ['Similar Triangles','Criteria for Similar Triangles','Areas of Similar Triangles', 'Pythagoras Theorem','. . .'],
      icon: 'fas fa-lock',
      flex:6,
      action: () => router.push({path: '/mathematics/triangles'})
    },
    {
      title: "Proportionality Theorem",
      subtitle:"(Basics)",
      description: ['Introduction','Tangent to a Circle','Number of Tangents from a Point','Length of a Tangent','. . .'],
      icon: 'fas fa-lock',
      flex:6,
      action: () => router.push({path:'/mathematics10th/demoThales'})
    },
    {
      title: "Proportionality Theorem",
      subtitle:"(Quiz)",
      description: ['Divison of a Line Segment','Tangents to a Circle','Construction of Triangles','. . .'],
      flex:12,
      icon: 'fas fa-lock',
      action: () => router.push({path: '/mathematics10th/demoThales'})
    },
   ]},

  {name:'Geometry',lock:true, img:"/assets/shapes.svg", data:[
    {
      title: "Triangles",
      description: ['Similar Triangles','Criteria for Similar Triangles','Areas of Similar Triangles', 'Pythagoras Theorem','. . .'],
      icon: 'fas fa-lock',
      flex:6,
      action: () => router.push({path: '/mathematics/triangles'})
    },
    {
      title: "Circles",
      description: ['Introduction','Tangent to a Circle','Number of Tangents from a Point','Length of a Tangent','. . .'],
      icon: 'fas fa-lock',
      flex:6,
      action: () => router.push({path:'/comingsoon'})
    },
    {
      title: "Constructions",
      description: ['Divison of a Line Segment','Tangents to a Circle','Construction of Triangles','. . .'],
      flex:12,
      icon: 'fas fa-lock',
      action: () => router.push({path: '/comingsoon'})
    },
   ]},
   {name:'Circles', lock:true, img:"/assets/circleMath.svg", data:[
     {
       title: "Tangent to a Circle",
       description: ['Definition of a Tangent','Tangent vs. Secant', '. . .'],
       icon: 'fas fa-lock',
       flex:6,
       action: () => router.push({path: '/mathematics/tangent'})
     },
     {
       title: "Number of Tangents to a Circle",
       description: ['Tangents drawn from an Exterior Point','From a Point on the Circle','From an Interior Point','. . .'],
       icon: 'fas fa-lock',
       flex:6,
       action: () => router.push({path:'/mathematics/tangentlength'})
     },
     {
       title: "Length of a Tangent",
       description: ['Divison of a Line Segment','Tangents to a Circle','Construction of Triangles','. . .'],
       flex:12,
       icon: 'fas fa-lock',
       action: () => router.push({path: '/mathematics10th/demoTangent'})
     },
    ]},
  {name:'Geometric Measurement',lock:true, img:"/assets/mensuration.svg",  data:[
    {
      title: "Area & Circumference of a Circle",
      description: ['Area of a Circle','Circumference', 'Length of a Sector', 'Area of a Sector','. . .'],
      icon: 'fas fa-lock',
      flex:12,
      action: () => router.push({path: '/comingsoon'})
    },
    {
      title: "Volumes and Surface Areas",
      description: ['Area of a Triangle: Heron\'s Formula','Area of a Quadrilateral','. . .'],
      icon: 'fas fa-lock',
      flex:12,
      action: () => router.push({path: '/comingsoon'})
    },
  ]},
  {name:'Trigonometry', lock:true, img:"/assets/trigonometry.svg",
   data:[
    {
      title: "Trigonometric Ratios ",
      description: ['Base, Height & Hypotenuse', 'Trigonometric Ratios','Heights & Distances','Trigonometric Identities','. . .'],
      icon: 'fas fa-lock',
      flex:12,
      action: () => router.push({path: 'mathematics10th/demoTrig'})
    },
    {
      title: "Quiz",
      description: ['Base, Height & Hypotenuse', 'Trigonometric Ratios','Heights & Distances','Trigonometric Identities','. . .'],
      icon: 'fas fa-lock',
      flex:12,
      action: () => router.push({path: 'mathematics10th/quizTrig'})
    },
  ]},

  {name:'Algebra', lock:true, img:"/assets/monomial.svg", data:[
    {
      title: "Polynomials",
      description: ['Linear, Quadratic & Cubic Polynomial','Zeros of a Polynomials','Division of Polynomials','Remainder Theorem','. . .'],
      flex:6,
      icon: 'fas fa-lock',
      action: () => router.push({path:'/mathematics/polynomials'})
    },
    {
      title: "Linear Equations in Two Variables",
      description: ['Linear Equations in Two Variables','Plotting Equations','Solving Equations','Types of Solutions','. . .'],
      flex:6,
      icon: 'fas fa-lock',
      action: () => router.push({path: '/mathematics/sle'})
    },
    {
      title: "Quadratic Equations",
      description: ['Standard Form','Solving by Factorization','Quadratic Formula','Discriminant', 'Nature of Roots','. . .'],
      flex:12,
      icon: 'fas fa-lock',
      action: () => router.push({path:'/mathematics/qe'})
    },
  ]},
  {name:'Quadratic Equations', lock:true, img:"/assets/alg.svg", data:[
    {
      title: "Quadratic Equation",
      description: ['Event & Outcomes','Probability of an Event','Sum of Probabilities','. . .'],
      flex:6,
      icon: 'fas fa-lock',
      action: () => router.push({path: '/mathematics10th/demoQE'})
    },
  {
    title: "Discriminant & Number of Solutions",
    description: ['Event & Outcomes','Probability of an Event','Sum of Probabilities','. . .'],
    flex:6,
    icon: 'fas fa-lock',
    action: () => router.push({path: '/mathematics10th/demoSOL'})
  },
  {
    title: "Quadratic Equation",
    subtitle:"(Quiz)",
    description: ['Event & Outcomes','Probability of an Event','Sum of Probabilities','. . .'],
    flex:12,
    icon: 'fas fa-lock',
    action: () => router.push({path: '/mathematics10th/quizQE'})
  }
 ]},
 {name:'Numbers', lock:true, img:"/assets/numbers.svg", data:[
  {
    title: "Real Numbers",
    description: ['Introduction','Euclid\'s Lemma','Fundamental Theorem of Arithmatic','Irrational Numbers', 'Decimal Exapansions','. . .'],
    flex:12,
    icon: 'fas fa-lock',
    action: () => router.push({path: '/comingsoon'})
  },
  {
    title: "Arithmatic Progressions (AP)",
    description: ['Introduction','Nth Term of an AP', 'Sum of first N terms of an AP','. . .'],
    flex:12,
    icon: 'fas fa-lock',
    action: () => router.push({path: '/comingsoon'})
  },
 ]},
  {name:'Data & Statistics',lock:true, img:"/assets/data-handling.svg",  data:[
    {
      title: "Mean of Grouped Data",
      description: ['Formula for Mean of a Grouped Data', 'Examples','. . .'],
      flex:6,
      icon: 'fas fa-lock',
      action: () => router.push({path: '/comingsoon'})
    },
    {
      title: "Median of Grouped Data",
      description: ['Formula for Median a Grouped Data', 'Examples','. . .'],
      flex:6,
      icon: 'fas fa-lock',
      action: () => router.push({path: '/comingsoon'})
    },
    {
      title: "Mode of Grouped Data",
      description: ['Formula for Mode of a Grouped Data', 'Examples','. . .'],
      flex:12,
      icon: 'fas fa-lock',
      action: () => router.push({path: '/comingsoon'})
    },
   ]},
  {name:'Probability', lock:true, img:"/assets/probs.svg", data:[
  {
    title: "Probability Theory",
    subtitle:"(Basics)",
    description: ['Event & Outcomes','Probability of an Event','Sum of Probabilities','. . .'],
    flex:12,
    icon: 'fas fa-lock',
    action: () => router.push({path: '/mathematics12th/prob'})
  },
  {
    title: "Probability Theory",
    subtitle:"(Test)",
    description: ['Event & Outcomes','Probability of an Event','Sum of Probabilities','. . .'],
    flex:12,
    icon: 'fas fa-lock',
    action: () => router.push({path: '/mathematics/probability'})
  },
 ]},
]
export default Math10th;
////////////////////////////END OF FILE /////////////////////////////////////////
