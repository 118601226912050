import Vue from 'vue'
import './plugins/vuetify'
import App from './App.vue'
import router from './router'
import Vuex from 'vuex'
import JXG from 'jsxgraph'
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueMeta from 'vue-meta'
import VueResponsiveImage from 'vue-responsive-image'
import VueCarousel from 'vue-carousel'
import VueScrollTo from 'vue-scrollto'
import ProgressBar from 'vuejs-progress-bar'
import VueGtag from 'vue-gtag'
//import 'vue-progress-path/dist/vue-progress-path.css'
//import 'vue-loading-overlay/dist/vue-loading.css';
import VueProgress from 'vue-progress-path'
import VueCircle from 'vue2-circle-progress'
//import Loading from 'vue-loading-overlay';
//import VueVideoPlayer from 'vue-video-player'
//import {VueVideoPlayer} from 'vue-videojs7'
//import VuePlyr from 'vue-plyr'
//import 'video.js/dist/video-js.css'
import VModal from 'vue-js-modal'
import VueTabs from 'vue-nav-tabs'
import VueConfirmDialog from 'vue-confirm-dialog'
//import VueEllipseProgress from 'vue-ellipse-progress'
import Notifications from 'vue-notification'
import VueMoment from 'vue-moment'
import VCalendar from 'v-calendar'
import VueJsonLD from 'vue-jsonld'
//import VueHead from 'vue-head'
///////////////////////////////////////////
Vue.use(VueGtag, {
  config: { id: "UA-155002427-1" }
})
//
//Vue.use(VueHead,{
//  separator: '|',
//  complement: 'Learn Interactively'});
//...
Vue.use(VueProgress);
//
Vue.use(VueJsonLD);
;//Vue.use(Loading,{color:'red'});
//
Vue.use(VueCircle);
//
Vue.use(VCalendar, {
  componentPrefix: 'vc'}
);
//Vue.use(ProgressBar, {duration:1000, easing: 'ease-in'});
//
Vue.use(VueScrollTo, {duration:1000, easing:"ease-in"})
//Vue.use(VueVideoPlayer)
Vue.use(VueCarousel);
//
//Vue.use(VuePlyr);
//
Vue.use(VueResponsiveImage);
//
Vue.use(VueMeta, {
  keyName: 'metaInfo',
  tagIDKeyName: 'vmid',
  refreshOnceOnNavigation: true
});
//
Vue.use(VueAxios, axios);
//
Vue.use(VueConfirmDialog);
//
//Vue.use(VueEllipseProgress);
//
Vue.use(Notifications);
// Using Navigation Tabs
Vue.use(VueTabs);
//Using confirm dialogs
Vue.use(VueConfirmDialog)
//
Vue.component('vue-confirm-dialog', VueConfirmDialog.default)
//
Vue.use(VueMoment);
//
Vue.config.productionTip = false
//
axios.defaults.baseURL = process.env.API_ENDPOINT;
//
Vue.use(Vuex)
//
Vue.use(VModal, { dialog: true });
//
Vue.mixin({
  methods: {
    goto(refName) {
      var element = this.$refs[refName];
      var top = element.offsetTop;
      window.scrollTo(0, top);
    },
    resize (graphs, _focusEvent) {
      graphs.forEach(
        function (g) {
          g.resizeContainer(g.containerObj.clientWidth, g.containerObj.clientHeight, true);
      });
    },
    logout()
    {
      this.loginStatus = false;
      this.dialog = false
      localStorage.removeItem('token');
      localStorage.removeItem('userId');
      localStorage.removeItem('redirect');
      console.log("checkLogin::", this.checkLogin())
      this.$router.push('/')
    },
    checkLogin (){
      if (localStorage.getItem('token')) {
        this.userName = "User Name"
        return true
      }else{
        this.userName = ""
        return false
      }
    },
    showCard ()
    {
      if(!localStorage.getItem('token'))
      {
      this.logged = true;
      }
      else
      {
      }
    },
    loggedIn()
    {
      if(localStorage.getItem('token')){
        return true;
      } else{
        return false;
      }
    },
    //Conditional Next to be used as a Navigation Guard
    conditionalNext(to, from, next) {
              if (localStorage.getItem('token'))
              {
                  next();
              }
              else
              {
                next("/")
              }
    },
///////////////////////////////////ENROLLMENT FUNCTION ////////////////////////////////////////
    enrollFunc(){
      console.log("token12::", localStorage.getItem('token'))
      if (localStorage.getItem('token')) {
        this.$router.push('/checkout?id='+this.$route.query.grade)
        // this.$router.push('/checkout?id='+this.$route.query.grade).catch((e)=>{});
      }else{
       this.dialog = true;
      }
    },
//////////////////////////////////////////////////////////////////////////////
    unsubscribeFunc(){
      this.$confirm(
        {
          message: `Are you sure want to cancel subscription?`,
          button: {
            no: 'No',
            yes: 'Yes'
          },
          callback: async (confirm) => {
            if (confirm) {
              console.log("yessssss")
              let postData = {
                appSubscriptionId: this.subscriptionId,
                userId: localStorage.getItem('userId')
              }
              const response = await axios.post(process.env.VUE_APP_API+'user/subscription/cancel', postData, { headers: {Authorization: 'Bearer ' + localStorage.getItem('token')}});
              // console.log(response);
              if(response.data.success){
                // this.subscriptionEnd = response.data.data.subscription.ends_at;
                this.$store.commit('navigation/appSubscriptionEnd', response.data.data.subscription.ends_at);
                // console.log("subscriptionEnd::", this.subscriptionEnd)
                this.$notify({
                    group: 'notify',
                    type: 'success',
                    title: 'success',
                    text: "Subscription cancelled successfully!!"
          });
              } else {
                // console.log("error::", response);
                this.$notify({
                          group: 'notify',
                          type: 'error',
                          title: 'Error!!',
                          text: 'Something went wrong!!'
                });
              }
            }
          }
        }
      )
    },
///////////////////////////////////////////////////////////////////////////////////////
  }
})
///////////////////////////IMPORTING NAVIGATION ARRAY FROM NAVIGATION.JS///////////
import { navigation } from './stores/navigation.js'
const store = new Vuex.Store({
  modules: {
    navigation: navigation
  }})
//////////////////////////////////////////////////////////
new Vue({
  JXG,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
//window._vuePrerender=_vue
/////////////////////////async module/////////////
/*
import navigation from './stores/navigation.js'
(async function () {
const store = new Vuex.Store({
  modules: {
    navigation: await navigation()
  }
})
////////////////////////////////////////////////////
const state = new Vuex.Store({
  vars: {
    finder: { },
    results: { },
    isSending:false
}
})
new Vue({
  JXG,
//MathJax,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
})(); */
