import Vue from 'vue'
import Router from 'vue-router'
//import Home from './views/Home.vue'
//import VueAnalytics from 'vue-analytics'
//import Chemistry from './views/Chem/Chemistry.vue'
//import Programming from './views/Prog/Programming.vue'
//import Mathematics from './views/Math/Mathematics.vue'
//import Physics from './views/Phys/Physics.vue'
//import VueGtag from 'vue-gtag'
//import VueMeta from 'vue-meta'
import Math from './Math.js'
//////////////////////////////////////////below needs to be rendered
import Math6th from './components/mathematics/Math6thGrade/6thGrade.js'
import Math7th from './components/mathematics/Math7thGrade/7thGrade.js'
import Math8th from './components/mathematics/Math8thGrade/8thGrade.js'
import Math9th from './components/mathematics/Math9thGrade/9thGrade.js'
import Math10th from './components/mathematics/Math10thGrade/10thGrade.js'
import Math11th from './components/mathematics/Math11thGrade/11thGrade.js'
import Math12th from './components/mathematics/Math12thGrade/12thGrade.js'
//////////////////////////////////////////BELOW ARE ALREADY RENDERED//////////////////////////////////////////////
import MenuItems from './MenuItems.js'
//import Misc from './Misc.js'//
import Dump from './dump.js'//
import Footers from './footerItems.js'
import SearchItems from './SearchItems.js'
////////////////////////////////////////////////////////
Vue.use(Router)
//
//Vue.use(VueMeta);
///////////////////////////
/*
Vue.use(VueGtag, {
  config: { id: "UA-155002427-1" }
})
*/
let router = new Router({
  mode: 'history',
  routes:[...Math9th,
          ...Math6th,
          ...Math7th,
          ...Math8th,
          ...Math12th,
          ...Math11th,
          ...Math10th,
          ...MenuItems,
          ...Math,
        //  ...Misc,
          ...Dump,
	        ...Footers,
	        ...SearchItems,
///////////////////////////CHEMISTRY MODULE ///////////////////////////////
    {
        path: '/chemistry6th',
        name: '6th Grade Chemistry',
        component: () => import('./views/Chem/Chemistry6th.vue')
    },

    {
          path: '/chemistry7th',
          name: '7th Grade Chemistry',
          component: () => import('./views/Chem/Chemistry7th.vue')
    },
    {
          path: '/chemistry8th',
          name: '8th Grade Chemistry',
          component: () => import('./views/Chem/Chemistry8th.vue')
    },
    {
          path: '/chemistry9th',
          name: '9th Grade Chemistry',
          component: () => import('./views/Chem/Chemistry9th.vue')
    },
    {
          path: '/chemistry10th',
          name: '10th Grade Chemistry',
          component: () => import('./views/Chem/Chemistry10th.vue')
    },
    {
          path: '/chemistry11th',
          name: '11th Grade Chemistry',
          component: () => import('./views/Chem/Chemistry11th.vue')
    },
    {
          path: '/chemistry12th',
          name: '12th Grade Chemistry',
          component: () => import('./views/Chem/Chemistry12th.vue')
    },
//////////////////////////////////////////////////////////////////////////////////////////
  {
      path: '/programming',
      name: 'programming',
      component: () => import('./views/Prog/Programming.vue'),
  },
  {
      path: '/programming6th',
      name: '6th Grade Programming',
      component: () => import('./views/Prog/Programming.vue'),
  },
  {
      path: '/programming7th',
      name: '7th Grade Programming',
      component: () => import('./views/Prog/Programming.vue'),
  },
  {
      path: '/programming8th',
      name: '8th Grade Programming',
      component: () => import('./views/Prog/Programming.vue'),
  },
  {
      path: '/programming9th',
      name: '9th Grade Programming',
      component: () => import('./views/Prog/Programming.vue'),
  },
  {
      path: '/programming10th',
      name: '10th Grade Programming',
      component: () => import('./views/Prog/Programming.vue'),
  },
  {
      path: '/programming11th',
      name: '11th Grade Programming',
      component: () => import('./views/Prog/Programming.vue'),
  },
  {
      path: '/programming12th',
      name: '12th Grade Programming',
      component: () => import('./views/Prog/Programming.vue'),
  },
///////////////////////PHYSICS MODULES /////////////////////////////
  {
      path: '/physics6th',
      name: '6th Grade Physics',
      component: () => import('./views/Phys/Physics6th.vue')
  },
  {
      path: '/physics7th',
      name: '7th Grade Physics',
      component: () => import('./views/Phys/Physics7th.vue')
  },
  {
      path: '/physics8th',
      name: '8th Grade Physics',
      component: () => import('./views/Phys/Physics8th.vue')
  },
  {
      path: '/physics9th',
      name: '9th Grade Physics',
      component: () => import('./views/Phys/Physics9th.vue')
  },
  {
      path: '/physics10th',
      name: '10th Grade Physics',
      component: () => import('./views/Phys/Physics10th.vue')
  },
  {
      path: '/physics11th',
      name: '11th Grade Physics',
      component: () => import('./views/Phys/Physics11th.vue')
  },
  {
      path: '/physics12th',
      name: '12th Grade Physics',
      component: () => import('./views/Phys/Physics12th.vue')
  },
//duplicate
/*  {
    path: '/physics',
    name: 'physics',
    component: () => import('./views/SubtopicMenuPhys.vue')
  },
*/
//duplicate
/*  {
      path: '/mathematics',
      name: 'mathematics',
      component: () => import('./views/SubtopicMenuMath.vue')
  },
*/
//duplicate
/*  {
      path: '/chemistry',
      name: 'chemistry',
      component: () => import('./views/SubtopicMenuChem.vue')
  },
*/
{
    path: '/gradesPhy',
    name: 'Physics Grades',
    component: () => import('./views/SubtopicMenuPhys.vue')
},
{
    path: '/gradesChem',
    name: 'Chemistry Grades',
    component: () => import('./views/SubtopicMenuChem.vue')
},
{
    path: '/gradesMath',
    name: 'Math Grades',
    component: () => import('./views/SubtopicMenuMath.vue')
},
  {
      path: '/gradesFoundations',
      name: 'Foundations Math Grades',
      component: () => import('./views/Foundations/SubtopicMenuMath.vue')
  },
  {
      path: '/gradesIntermediate',
      name: 'Intermediate Math Grades',
      component: () => import('./views/Intermediate/SubtopicMenuMath.vue')
  },
  {
      path: '/gradesMath',
      name: 'Math Grades',
      component: () => import('./views/Advanced/SubtopicMenuMath.vue')
  },
  {
      path: '/gradesAdvanced',
      name: 'Advanced Math Grades',
      component: () => import('./views/Advanced/SubtopicMenuMath.vue')
  },
//////////////////////////////////DASHBOARD MODULES ////////////////////////////
    {
      path: '/dashboard',
      name: 'Dashboard',
      beforeEnter: conditionalNext,
      component: () => import('./views/Dash/Dashboard.vue')
    },
    {
        path: '/purchaseHistory',
        name: 'Purchase History',
        beforeEnter: conditionalNext,
        component: () => import('./views/PurchaseHistory.vue')
    },
    {
        path: '/settings',
        name: 'Settings',
        beforeEnter: conditionalNext,
        component: () => import('./views/setting.vue')
    },
    {
        path: '/checkout',
        name: 'Checkout',
        beforeEnter: conditionalNext,
        component: () => import('./views/checkout.vue')
    },
    {
      path: '/registerRole',
      name: 'register',
      beforeEnter: conditionalNext,
      component: () => import('./views/registerRole.vue')
    },
    {
      path: '/paymentsuccess',
      name: 'paymentsuccess',
      beforeEnter: conditionalNext,
      component: () => import('./views/paymentsuccess.vue'),
    },
  ////////////////////////////MISC/////////////////////////////////////
    {
        path: '/quizoftheweek',
        name: 'quizoftheweek',
        component: () => import('./components/mathematics/Math12thGrade/quizDerivative/Lesson.vue')
    }
  //////////////////////////////////////////////////////////////////////////
  ],
//////////////////////////////////////////////////END OF MODULES////////////////////////////////////
  scrollBehavior (to, from, savedPosition) {
  return { x: 0, y:0 };
}
});
//////////////////////////////ACTIVE THE BELOW LINES TO ACTIVE THE PROGRESS CIRCLE///////
router.afterEach((to, from)=>{document.getElementById("sine").style.display='none'})
router.beforeResolve((to, from, next) => {
  // If this isn't an initial page load.
  if (to.name) {
    // Start the route progress bar.
  document.getElementById("sine").style.display='inline-block'
  }
  next()})
///////////////////////////////////////NAVIGATION GUARD///////////////////////////////
function conditionalNext(to, from, next) {
          if (localStorage.getItem('token'))
          {
              next();
          }
          else
          {
            next("/")
          }
  }
/*
async function checkValidation(to, from, next) {
    console.log("query::::::::", to.query.chap)
    try{
      if(typeof to.query.chap !== 'undefined'){
        let postObj = { chapter_id : to.query.chap}
        if (localStorage.getItem('token')){
          postObj.user_id = localStorage.getItem('userId')
        }
        const response = await axios.post(process.env.VUE_APP_API+'chapter/auth', postObj);
        if(response.data.success){
          if(response.data.data.is_authorized) next();
          else next("/");
        } else {
          next("/");
        }

      } else {
          next("/")
      }
    } catch(error){
      next("/");
    }
}*/
export default router
