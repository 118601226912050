import router from '../router'
const Phys12th =[
  {name:'Forces & Motion', lock:false, img:"/assets/pulleyb&w.svg", data:[
    {
      title: "Motion in a Plane",
      description: ['Introduction to Vectors', 'Relative Motion in 2D', 'Projectile Motion', 'Circular Motion', '. . .'],
      isBlack: false,
      icon: 'fas fa-lock-open',
      action: () => router.push({path: '/physics/2dmotion'})
    },
    {
      title: "Free Body Diagram",
      description: ['Definition','Drawing Free Body Diagrams','. . .'],
      isBlack: true,
      icon: 'fas fa-lock-open',
      showhome:true,
      action: () => router.push({path:'/physics/12th/forces'})
    },
    {
     title: "Newton\s Laws",
     description: ['Laws of Motion','Newton\'s First Law','Newton\'s Second Law','Newton\'s Third Law','. . .'],
     isBlack: false,
     icon: 'fas fa-lock-open',
     showhome:true,
     action: () => router.push({path:'/physics/FBD'})
     } ]},
  {name:'Harmonic Motion', lock:true, img:"/assets/pendulumb&w.svg", data:[
         {
          title: "Oscillations",
          description: ['Definition','Equation of Motion for a Simple Pendulum','Time Period of Oscillation','Small Angle Approximation','. . .'],
          isBlack: true,
          icon: 'fas fa-lock',
          showhome:true,
          action: () => router.push({path:'/physics/sp'})
          },
          {
          title: "Springs",
          description: ['Springs', 'Hooke\'s Law','Springs in Series','Springs in Parallel', 'Oscillations of Spring-Mass System','. . .'],
          isBlack: false,
          icon: 'fas fa-lock',
          showhome:true,
          action: () => router.push({path: '/physics/sm'})
          },
         ]},

  {name:'Fluids',lock:true, img:"/assets/turbine.svg", data:[
    {
      title: "Density & Pressure of Fluids",
      description: ['Density & Pressure','Viscosity','Surface Tension','Buoyant force','...'],
      icon: 'fas fa-lock',
      isBlack: true,
      action: () => router.push({path: ''})
    },
    {
      title: "Pascal\'s Principle",
      description: ['Static Pressure', 'Pascal\'s Law','Application in Hydraulics','...'],
      icon: 'fas fa-lock',
      isBlack: false,
      action: () => router.push({path:'/physics/hydrostatic'})
    },
    {
      title: "Buoyancy",
      description: ['Buoyant Force','Archimedes\'s Principle','Application','...'],
      icon: 'fas fa-lock',
      isBlack: true,
      action: () => router.push({path: '/physics/buoyancy'})
    }
   ]},
  {name:'Heat & Thermodynamics', lock:true,img:"/assets/thermometer.svg",  data:[
    {
      title: "Thermodynamics",
      description: ['Zeroth and First Law of Thermodynamics', 'Work from PV Graphs', 'Thermodynamic Processes', '. . .'],
      icon: 'fas fa-lock',
      isBlack: false,
      action: () => router.push({path: '/physics/thermodynamics'})
    },
    {
      title: "Thermal Properties of Matter",
      description: ['Temperature','Kinetic Theory of Gases','Ideal Gas Law','Thermal Expansion','...'],
      icon: 'fas fa-lock',
      isBlack: true,
      action: () => router.push({path: ''})
    }]},
  {name:'Electricity',lock:true, img:"/assets/circuit.svg",  data:[
    {
      title: "Current & Resistance",
      description: ['Electric Current','Resistance & Resistors','Ohm\'s Law','...'],
      icon: 'fas fa-lock',
      isBlack: true,
      action: () => router.push({path: '/physics/electricity'})
    },
    {
      title: "Capacitance",
      description: ['Capacitance','Capacitors in Series & Parallel','Energy Stores in a Capacitor','...'],
      icon: 'fas fa-lock',
      isBlack: false,
      action: () => router.push({path: '/physics/electricity'})
    },
   ]},
  {name:'Magnetism',lock:true, img:"/assets/magnetism.svg",  data:[
    {
      title: "Magnetism",
      description: ['What is Magnetism?','Magnetic Force & Magnetic Field','Magnetic Monopoles and Dipoles', '...'],
      icon: 'fas fa-lock',
      isBlack: false,
      action: () => router.push({path: '/physics/magnet'})
    },
    {
      title: "Magnetic Moment & Dipole",
      description: ['What is Electromagnetism?', 'Faraday\'s Law of Induction','Maxwell\'s Theory of Electromagnetism', '...'],
      icon: 'fas fa-lock',
      isBlack: true,
      action: () => router.push({path: ''})
    }]},
  {name:'Optics', lock:true,img:"/assets/prism.svg",  data:[
    {
      title: "Refraction of Light",
      description: ['What is Refraction?', 'Law of Refraction', 'Total Internal Reflection', '...'],
      icon: 'fas fa-lock',
      isBlack: true,
      action: () => router.push({path: '/physics/refraction'})
    },
    {
      title: "Theory of Lens",
      description: ['Convex Lenses', 'Concave Lenses', '...'],
      icon: 'fas fa-lock',
      isBlack: false,
      action: () => router.push({path: ''})
    },
    {
      title: "Mirror",
      description: ['Convex Mirrors', 'Concave Mirrors', '...'],
      icon: 'fas fa-lock',
      isBlack: true,
      action: () => router.push({path: ''})
    },
   ]},
  {name:'Electromagnetism', lock:true,img:"/assets/electromagnet.svg",  data:[
    {
      title: "Electromagnetism",
      description: ['What is Electromagnetism?', 'Faraday\'s Law of Induction','Maxwell\'s Theory of Electromagnetism', '...'],
      icon: 'fas fa-lock',
      isBlack: false,
      action: () => router.push({path: ''})
    }, ]},
  {name:'Waves', lock:true, img:"/assets/waves.svg",  data:[
    {
      title: "Waves",
      description: ['What is a Wave?','Types of Waves','Longitudinal Waves', 'Transverse Waves','...'],
      icon: 'fas fa-lock',
      isBlack: true,
      action: () => router.push({path: '/physics/phase'})
    },
    {
      title: "Wave Equation",
      description: ['Equation of wave','Frequency','Amplitude','...'],
      icon: 'fas fa-lock',
      isBlack: false,
      action: () => router.push({path: ''})
    },
  ]}
]
export default Phys12th;
////////////////////////////END OF FILE /////////////////////////////////////////
